@mixin opacity($value) {
    -ms-filter: alpha(opacity=($value*100));  // new ie
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100);  // new ie
    filter: alpha(opacity=$value*100);  // old ie
    opacity: $value;  // modern gecko and webkit
}

@mixin colorepacchetto($shade) {
    h4 {
        background-color: $shade;
    }
    &.pacchetto-owned {
        border-right: 4px solid $shade;
        border-left: 4px solid $shade;
        border-bottom: 4px solid $shade;
    }
    .pacchetto-owned-alert,
    .pacchetto-in-evidenza-alert {
        color: $shade;
    }
    /*
    *   Se applicata a un'icona del font genya le forza il colore
    */
    &[class*='icon-genya-'] {
        color: $shade!important;
    }
}

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
            transform: $transforms;
}

@mixin scale($scale) {
	 @include transform(scale($scale));
}

@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
	     -o-transform-origin: $origin;
	    -ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
          transform-origin: $origin;
}

@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}

@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin box-shadow ($params) {
    -webkit-box-shadow: $params;
    -moz-box-shadow: $params;
    box-shadow: $params;
}
