/* Footer */
.wk-footer {
    padding-top: 3rem;
    margin-top: 0;
    line-height: 1.5;
     .wk-footer-nav {
         .wk-footer-nav-list {
             @media screen and (max-width: $min-width-tablet) {
                 margin-bottom: 2.5rem;
                 width: 100%;
             }
             &:last-child {
                 float: right;
                 margin-right: 0;
                 width: auto;
                 @media screen and (max-width: $min-width-tablet) {
                     float: left;
                 }
                 ul {
                     text-align: right;
                     @media screen and (max-width: $min-width-tablet) {
                        text-align: left;
                     }
                 }
             }
             .wk-footer-heading {
                 font-size: 1.2rem;
                 margin-left: 27px;
                 margin-bottom: 1rem;
                 @media screen and (max-width: $min-width-tablet) {
                     margin-left: 0;
                 }
             }
             ul.wk-social-icons-container {
                 li {
                     a {
                         display: block;
                         width: 45px;
                     }
                 }
             }
         }
     }
     .wk-footer-meta-info {
         li {
             color: $textColor;
             line-height: 1.5;
         }
         a {
             text-decoration: none;
             &:hover {
                 text-decoration: none;
             }
         }
     }
     .footer-phone,
     .footer-video {
         > a {
             font-size: 1.3rem;
             display: inline-block;
             [class*='icon-genya-'] {
             	vertical-align: text-top;
             }
         }
     }
}

/* Cookie Policy Bar */
.cookie-policy-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(71, 71, 71, 0.9);
    padding: 1.2rem;
    z-index: 9999;
    color: $white;
    font-size: 1rem;
    line-height: 1.5;
    @media screen and (max-width: $max-width-mobile) {
        font-size: 0.8rem;
        padding: 0.8rem;
        width: 100%;
    }
    div {
        width: 87%;
        display: inline-block;
        padding-right: 1rem;
        @media screen and (max-width: $max-width-tablet) {
            width: 75%;
        }
        @media screen and (max-width: $max-width-mobile) {
            padding-right: 0;
            width: 100%;
        }
    }
    a {
        color: $white;
        text-decoration: underline;
        &:hover {
            color: $white;
        }
        &:active {
            color: $white;
        }
        &:focus {
            color: $white;
        }
    }
    a[wk-button] {
        width: 13%;
    	float: right;
    	padding: 6px;
        margin-top: 4px;
    	font-weight: 500;
        background-color: #e5202e;
        text-decoration: none;
        &:hover {
            background-color: lighten(#e5202e, 5%)!important;
        }
        @media screen and (max-width: $max-width-tablet) {
            margin-top: 1rem;
            width: 25%;
        }
        @media screen and (max-width: $max-width-mobile) {
            font-size: 0.8rem;
            width: 100%;
        }
    }
}

/* Cookie policy banner 2 */
#banner_consenso_cookie {
    position: fixed;
	font-size: 100%;
	left: 0pt;
	width: 100%;
	background-color: #0082c8;
	background-color: rgba(0, 130, 200, 0.95);
	display: none;
	z-index: 10000;
	clear: both;
	float: none;
	top: 0pt;
	padding: 8pt;
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 10.75pt;
	line-height: 1.3;
	font-weight: 400;
	background-image: none;
	letter-spacing: normal;
	text-align: left;
	text-indent: 0;
	vertical-align: top;
	word-spacing: normal;
	white-space: normal;
	margin: 0pt;
	border-top-style: none;
	border-right-style: none;
	border-bottom-style: none;
	border-left-style: none;
	height: auto;
	right: auto;
	box-sizing: border-box;
	border-collapse: collapse;
	border-spacing: 0px;
	table-layout: fixed;
    background-color: rgba(66,66,66,0.95) !important;
    float: inherit !important;
    top: inherit !important;
    bottom: 0 !important;
    padding-top: 15px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 10px !important;
}

#testo_banner {
    font-size: 14px;
    line-height: 130%;
    font-weight: normal;
    font-family: "Source Sans Pro",Helvetica Neue,Helvetica,Arial,sans-serif !important;
    color: #fff;
    font-style: normal;
    padding: 0 !important;
    margin: 0 !important;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: none !important;
    bottom: inherit !important;
    float: left !important;
    margin-bottom: 10px !important;
    width: 82% !important;
    margin-right: 2% !important;
}

#testo_banner a {
    color: #fff !important;
    text-decoration: underline!important;
}

#chiusura_banner {
    display: block;
    cursor: pointer;
    background-color: transparent !important;
    font-size: 14px;
    line-height: 100%;
    font-weight: bold;
    font-family: "Source Sans Pro",Helvetica Neue,Helvetica,Arial,sans-serif !important;
    margin: 0 !important;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    width: 14% !important;
    text-align: center !important;
    text-indent: -99999px !important;
    position: relative !important;
    height: 35px !important;
    float: left !important;
    top: 0!important;
    right: 0!important;
}

#chiusura_banner::after {
    content: "Accetto";
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: auto !important;
    bottom: auto !important;
    border-style: none;
    cursor: pointer;
    vertical-align: middle;
    -moz-box-shadow: rgba(12,12,15,0.2) 0 2px 1px;
    -webkit-box-shadow: rgba(12,12,15,0.2) 0 2px 1px;
    box-shadow: rgba(12,12,15,0.2) 0 2px 1px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background-color: #e5202e !important;
    color: #fff !important;
    border-style: none;
    width: 100% !important;
    text-align: center !important;
    height: 35px !important;
    font-size: 14px;
    line-height: 230%;
    font-weight: bold;
    float: none !important;
    padding: 0 !important;
    margin: 0 !important;
    visibility: visible;
    overflow: visible;
    clear: both;
    text-decoration: none;
    text-indent: 0;
    word-spacing: normal;
    z-index: 999999;
    -moz-transition: all 150ms ease-out;
    -o-transition: all 150ms ease-out;
    -webkit-transition: all 150ms ease-out;
    transition: all 150ms ease-out;
}

#chiusura_banner:hover::after {
    background-color: lighten(#e5202e, 5%)!important;
}

@media screen and (max-width: 600px) {
    #testo_banner {
        width: 100%!important;
        font-size: 12px!important;
    }
    #chiusura_banner {
    	width: 100%!important;
    }
}
