html {
    /* LT IE9 */
    &.ie {
        .row-pacchetti-obscured {
            > div {
                cursor: not-allowed;
                @include opacity(0.5);
            }
        }
    }
}

body {
    /* Common rules for IE and Edege */
    &.msie,
    &.msedge {
        .opener-menu-profilo,
        .opener-menu-carrello {
            .nav-profile-menu,
            .nav-carrello-menu {
                top: 43px;
            }
        }
        .dropdown-cart {
            top: 43px;
        }
    }
    /* IE */
    &.msie {

    }
    /* Edge */
    &.msedge {

    }
}
