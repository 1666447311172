@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import url( 'https://fonts.googleapis.com/css?family=Merriweather:300i,400');
@import 'fonts';
@import 'variables';
@import 'mixins';
@import 'header';
@import 'forms';
@import 'modals';
@import 'footer';
@import 'ie';

body {
    font-family: $font-stack-sans;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $wkGray5;
    color: $textColor;
    margin-top:0!important;
    > form {
        background-color: $white;
        &.transparent {
            background-color: inherit;
        }
        > .wk-page-content {
            margin-top: 1rem;
        }
    }
    &.contact-page {
        form {
            background-color: $wkGray5;
        }
        .page-content-centered {
            max-width: 50rem;
        	margin: 3rem auto;
            background-color: $white;
            padding: 2rem;
            @media screen and (max-width: $min-width-tablet) {
                margin: 1rem auto;
            }
            @media screen and (max-width: $max-width-mobile) {
                padding: 1rem;
            }
            > .wk-h2,
            > .wk-h3 {
                font-weight: 400;
                line-height: 1.5;
            }
            p.form-nota {
                font-size: 1rem;
                margin: 1.5rem 0;
                line-height: 1.5;
                > span {
                    color: $navBlue;
                }
            }
        }
    }
}

.fixed {
    position: fixed;
    overflow: hidden;
    width: 100%;
}

.wk-h1,
.wk-h2,
.wk-h3 {
    font-weight: 500;

}
.heading-blue {
    color: $wkBlue;
    font-family: $font-stack-serif;
    font-style: italic;
}

.redText {
    color: $wkRed;
}

.grayText {
    color: $infoTextColor;
}

.greenText {
    color: $wkGreen;
}

.wk-green-icons {
    [class*='icon-genya-'] {
        color: $wkGreen;
    }
}

.green-icon,
.green-icon:before,
.green-icon:after {
    color: $wkGreen;
}

.gray-icon,
.gray-icon:before,
.gray-icon:after {
    color: $wkGray1;
}

.icon-action {
    [class*="icon-genya-"] {
        display: inline-block;
        margin-right: 0;
        color: $wkBlue;
    }
}

a {
    &:hover {
        text-decoration: none;
    }
    &:active {
        text-decoration: none;
    }
    &:focus {
        text-decoration: none;
    }
}

a[wk-button],
button[wk-button] {
    font-size: 1rem;
    line-height: 1.5;
    &:hover {
        text-decoration: none;
    }
    &.wk-icon-arrow-right {
    	padding-left: 1.3rem;
        &:before {
            width: 3.6rem;
        	right: 0;
        	padding: 1.3rem;
        	background: none;
        }
    }
}

a.phone-number {
    color: $textColor;
    &:hover {
        color: $navBlue;
    }
    &:active {
        color: $navBlue;
    }
    &:visited {
        color: $textColor;
        text-decoration: none;
    }
}

/*
* Link e icone disabilitate dopo il click
*/
.disabled-link {
    color: $wkGray3!important;
    cursor: not-allowed;
    &:before {
        color: $wkGray3!important;
        cursor: not-allowed;
    }
    &:visited {
        color: $wkGray3!important;
        cursor: not-allowed;
    }
    &:hover {
        color: $wkGray3!important;
        cursor: not-allowed;
    }
    &:active {
        color: $wkGray3!important;
        cursor: not-allowed;
    }
}

.wk-page-content {
    margin: 3rem auto;
    @media screen and (max-width: $max-width-mobile) {
        margin: 1rem auto;
    }
}

.genya-top-header {
    position: relative;
    height: 320px;
    width: 100%;
    margin: 0 auto;
    background-image: url('../images/header_1.jpg');
    background-size: cover;
    background-color: $white;
	background-repeat: no-repeat;
    @media screen and (min-width: $max-width-tablet) {
        background-position: 20% 30%;
    }
    @media screen and (max-width: 480px) {
    	background-size: contain;
    }
    .wk-h2,
    .wk-h3 {
        color: $white;
    }
    .wk-h2 {
        margin-top: 0;
        font-family: $font-stack-serif;
        font-style: italic;
    }
    .wk-h3 {
        font-size: 1rem;
    }
    .genya-top-header-wrapper {
        width: 100%;
        max-width: 75rem;
        margin: auto;
    }
    .genya-top-header-logged,
    .genya-top-header-guest {
        //max-width: 35rem;
        width: 40%;
        position: absolute;
        bottom: 0;
        //padding-left: 3rem;
        @media screen and (max-width: $min-width-tablet) {
            max-width: 100%;
            width: 100%;
            padding-left: 0;
            position: absolute;
        	bottom: -3.6rem;
        }
        @media screen and (max-width: 599px) {
            bottom: -1rem;
        }
    }
    .genya-top-header-logged {
        @media screen and (max-width: $min-width-tablet) {
            bottom: 0;
        }
    }
    .genya-top-header-guest-button {
        width: 100%;
        a[wk-button] {
            float: right;
            @media screen and (max-width: $max-width-mobile) {
                font-size: 0.9rem;
            }
        }
    }
    .genya-top-header-section {
        display: inline-block;
        width: 100%;
        padding: 2rem 3rem;
    	margin-bottom: -0.1px;
        .wk-h2 {
            line-height: 1.5;
            @media screen and (max-width: $max-width-mobile) {
                font-size: 1.4rem;
            }
        }
        .wk-h3 {
            line-height: 1.5;
        }
        @media screen and (max-width: $min-width-tablet) {
        	padding: 1rem 1.2rem;
            background-color: rgba(36, 24, 102, 1);
            background: rgba(36, 24, 102, 1);
            color: rgba(36, 24, 102, 1);
        }
        @media screen and (max-width: $max-width-mobile) {
            background-color: rgba(36, 24, 102, 1);
        }
    }
    &.genya-top-header-landing {
        background-image: url('../images/landing-header.jpg');
        @media screen and (max-width: $max-width-mobile) {
            background-size: cover;
	        height: 330px;
        }
        @media screen and (max-width: 360px) {
            background-size: contain;
	        height: 300px;
        }

        .genya-top-header-guest {
            bottom: 2rem;
            @media screen and (max-width: $max-width-tablet) {
                bottom: 0;
            }
        }
        .genya-top-header-section {
            background-color: rgba(0, 122, 195, 0.8);
            @media screen and (max-width: $max-width-mobile) {
                background-color: rgba(0, 122, 195, 1);
            }
        }
    }
}

.genya-top-header-professional {
    background-image: url('../images/genya_header_professional.jpg');
    .genya-top-header-section {
        background-color: rgba(36, 24, 102, 0.8);
        @media screen and (max-width: $max-width-mobile) {
            background-color: rgba(36, 24, 102, 1);
        }
    }
}

.genya-top-header-aziende {
    background-image: url('../images/genya_header_corporate.jpg');
    background-position: 20% 80%;
    @media screen and (max-width: $max-width-mobile) {
        background-position: 0 0;
    }
    .genya-top-header-section {
        background-color: rgba(148, 12, 114, 0.8);
        @media screen and (max-width: $max-width-mobile) {
            background-color: rgba(148, 12, 114, 1);
        }
    }
}

.genya-top-header-fatturasmart {
    background-image: url('../images/genya_header_fatturasmart.jpg');
    background-position: 50% 45%;
    @media screen and (max-width: $max-width-mobile) {
        background-position: 0 0;
    }
    .genya-top-header-section {
        background-color: rgba(0, 152, 129, 0.8 );
        @media screen and (max-width: $max-width-mobile) {
            background-color: rgba(0, 152, 129, 1);
        }
    }
}

.genya-tab-container {
    .wk-nav-tab-bar {
        padding-top: 2rem;
        margin-bottom: 0;
        background-color: $white;
        @media screen and (min-width: 600px) and (max-width: $min-width-tablet){
            padding-top: 3.2rem;
        }
    }
    ul.wk-nav-tabs {
        li {
            a {
                font-size: 1rem;
                color: #007ac2;
                [class*="icon-genya-"] {
                    color: $wkGray1;
                    vertical-align: sub;
                    font-size: 1.4rem;
                    margin-left: 0.25rem;
                    line-height: 0.9;
                }
                .icon-genya-lock {
                    font-size: 1.1rem;
                    vertical-align: middle;
                }
            }
            &.active {
                a {
                    background-color: $wkGray5;
                }
            }
        }
    }
    .tab-content {
        padding: 1rem 0;
        background-color: $wkGray5;
        .wk-h3 {
            margin-bottom: 2rem;
            @media screen and (max-width: 420px) {
            	max-width: 85%;
            }

            font-family: $font-stack-sans;
            font-weight: 300;
            font-style: normal;
            font-size: 1.875rem;
            -webkit-font-smoothing: subpixel-antialiased;
        	-moz-osx-font-smoothing: auto;
            strong {
                font-weight: 500;
            }
        }
        .wk-page-content {
            margin: 2rem auto;
        }
        p {
            font-size: 1.25rem;
            line-height: 1.5;
        }
    }
    .tab-pane {
        position: relative;
        .tab-presto-online {
            margin-bottom: 2rem;
            .wk-h3 {
                max-width: 80%;
            }
            &:before {
                content: ' ';
                background-image: url('../images/presto_online.png');
                background-size: 100%;
                background-repeat: no-repeat;
                width: 10rem;
                height: 10rem;
                display: block;
                position: absolute;
                top: -4rem;
                right: 0;
                @media screen and (max-width: $min-width-tablet) {
                    width: 6rem;
                    height: 6rem;
                }
                @media screen and (max-width: $max-width-mobile) {
                    top: -2.1rem;
                }
            }
        }
    }
    /* iOS scrolling bars*/
    ::-webkit-scrollbar {
        height: 8px;
        border-bottom: 1px solid #eee;
        border-top: 1px solid #eee;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #C3C3C3;
        border: 1px solid #eee;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    }
}

.genya-mobile-full-container {
    padding-left: 0;
    padding-right: 0;
    p,
    .wk-h3 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

/* Landing page */
.landing-content {
    margin-top: 0;
    @media screen and (max-width: $max-width-mobile) {
        margin: 1rem auto;
    }
    .landing-content-text {
        .wk-h3 {
            font-family: $font-stack-sans;
            //font-weight: 400;
            margin-bottom: 2rem;
            line-height: 1.5;
            font-weight: 300;
            font-size: 1.875rem;
            font-style: normal;
            -webkit-font-smoothing: subpixel-antialiased;
        	-moz-osx-font-smoothing: auto;
            strong {
                font-weight: 500;
            }
        }
        p {
            font-size: 1.2rem;
            line-height: 1.5;
            margin-bottom: 2rem;
        }
    }
    .landing-box {
        background-color: $white;
        padding: 1rem 2rem;
        font-size: 1.2rem;
        @media screen and (max-width: $max-width-tablet) {
            margin-bottom: 2rem;
        }
        .wk-h2 {
            font-family: $font-stack-serif;
            font-style: italic;
        }
        .landing-subtitle {
            color: $wkGray1;
            @media screen and (max-width: $max-width-mobile) {
                line-height: 1.2rem;
            }
        }
        .landing-soluzione {
            position: relative;
            width: 100%;
            margin: 1rem 0;
            padding: 1.4rem 0;
            border-top: 1px solid #d3d3d3;
            > span {
                display: inline-block;
                padding: 0.8rem;
                font-size: 2.2rem;
                line-height: 2.2rem;
            }
            .landing-soluzione-descrizione {
                display: inline-block;
                vertical-align: top;
                max-width: 50%;
                color: $wkGray1;
                margin-left: 0.5rem;
                line-height: 1.5;
                @media screen and (min-width: $min-width-tablet) and (max-width: $max-width-sm) {
                    max-width: 65%;
                }
                @media screen and (max-width: $max-width-mobile) {
                    max-width: 180px;
                    font-size: 1rem;
                    line-height: 1rem;
                }
                @media screen and (max-width: 360px) {
                    max-width: 140px;
                }
                strong:first-of-type {
                    width: 100%;
                    display: block;
                    line-height: 1.8rem;
                    @media screen and (max-width: $max-width-mobile) {
                        line-height: normal;
                    }
                }
                & + a {
                    position: absolute;
                    top: 2.3rem;
                    right: 0;
                    padding: 1rem 2rem;
                    font-size: 1.2rem;
                    @media screen and (max-width: $min-width-tablet) {
                        padding: 1rem 2rem;
                        font-size: 1.2rem;
                    }
                    @media screen and (max-width: $max-width-mobile) {
                        position: relative;
                        width: 100%;
                        top: 1rem;
                    }
                }
            }
        }
    }
    .landing-box-professionisti {
        .wk-h2 {
            color: #241866;
        }
        .landing-soluzione > span {
            color: lighten(#3a3076, 20%);
            background-color: #3a3076;
        }
    }
    .landing-box-aziende {
        .wk-h2 {
            color: $wkPurple;
        }
        .landing-soluzione > span {
            color: lighten($wkPurple, 10%);
            background-color: $wkPurple;
        }
    }
}

/* Pacchetti */
.wk-row.row-pacchetti {
    margin-top: 2rem;
}

.pacchetto {
    background-color: $white;
    margin-top: 2rem;
    .wk-h4 {
        padding: 1rem;
        margin: 0 0 1rem 0;
        font-family: $font-stack-serif;
        font-style: italic;
        color: $white;
        font-size: 1.4375rem;
        font-weight: 400;
    }
    .pacchetto-info {
        padding: 0 1rem 1rem 1rem;
    }
    .pacchetto-anagrafiche {
        position: relative;
        font-size: 1.1875rem;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        word-wrap: break-word;
        white-space: nowrap;
        border-bottom: 1px solid #d3d3d3;
        overflow: hidden;
        text-overflow: ellipsis;
        [class*="icon-"] {
            font-size: 1rem;
        }
        &.descrizione-suite {
            white-space: unset;
        }
        > span {
            font-family: $font-stack-serif;
            font-size: 2rem;
            font-style: italic;
        }
        > a {
            float: none;
            margin-top: 1rem;
            color: $wkGray2;
            &:visited {
                color: $wkGray2;
            }
            &:hover,
            &:active {
                color: $lightblue;
            }
        }
        .pacchetto-info-popup {
            display: none;
            position: absolute;
            font-size: 0.9rem;
            background-color: $white;
            top: -7rem;
            right: -17rem;
            width: 300px;
            padding: 1rem;
            border: 1px solid #d3d3d3;
            z-index: 999;
        }
        .pacchetto-info-accordion {
            display: none;
            position: relative;
            font-size: 0.9rem;
            padding-top: 1rem;
            color: $infoTextColor;
            background-color: $white;
            z-index: 999;
            white-space: normal;
        }
    }
    .pacchetto-incluso {
        font-size: 1.1875rem;
        font-style: italic;
        font-family: $font-stack-serif;
        ul {
            margin-top: 0.5rem;
            list-style-position: outside;
            list-style-type: none;
            padding-left: 0.5rem;
            li {
                font-family: $font-stack-sans;
                font-size: 0.9rem;
                font-style: normal;
                &:before {
                    content: '\0045';
                    font-family: "genya icons";
                    font-size: 0.9rem;
                    color: $wkGreen;
                    font-weight: bold;
                    margin-right: 4px;
                    padding-right: 5px;
                }
            }
        }
    }
    .pacchetto-prezzo {
        margin: 2rem auto;
        font-size: 1.6rem;
    }
    .pacchetto-dettagli {
        margin: 2rem auto;
    }
    .pacchetto-button {
        text-align: center;
        padding: 1rem;
        > a {
            width: 100%;
            font-size: 1rem;
            line-height: 1.5;
            display: block;
            white-space: normal;
            @media screen and (min-width: $min-width-md) and (max-width: 1100px) {
                font-size: 0.8rem;
            }
            @media screen and (max-width: $max-width-mobile) {
                font-size: 0.9rem;
            }
        }
        > span {
            display: inline-block;
            min-height: 53px;
        }
    }
    &.pacchetto-obscured {
        .pacchetto-anagrafiche {
            > a {
                cursor: default;
                float: right;
                margin-top: 1rem;
                color: $wkGray2;
                &:visited {
                    color: $wkGray2;
                }
                &:hover,
                &:active {
                    color: $wkGray2;
                }
                @media screen and (max-width: $max-width-tablet) {
                    float: none;
                }
            }
        }
    }
    &.pacchetto-col-6 {
        .pacchetto-info {
            display: inline-block;
            width: 100%;
            .pacchetto-incluso,
            .pacchetto-prezzo,
            .pacchetto-dettagli {
                width: 50%;
                float: left;
                @media screen and (max-width: $max-width-mobile) {
                    width: 100%;
                }
            }
            .pacchetto-incluso {
                border-right: 1px solid #d3d3d3;
                @media screen and (max-width: $max-width-mobile) {
                    border: 0;
                }
            }
            .pacchetto-prezzo {
                padding: 0 1rem;
                text-align: center;
                @media screen and (max-width: $max-width-mobile) {
                    text-align: left;
                }
            }
            .pacchetto-dettagli {
                padding: 0 2rem;
                text-align: left;
                @media screen and (max-width: $max-width-mobile) {
                    padding: 0 1rem;
                }
            }
        }
    }
    &.pacchetto-disabled {
        @include opacity(0.4);
        cursor: not-allowed;
        .pacchetto-anagrafiche {
            > a {
                float: none;
                margin-top: 1rem;
                color: $wkGray2;
                cursor: not-allowed;
                &:visited {
                    color: $wkGray2;
                }
                &:hover,
                &:active {
                    color: $wkGray2;
                }
            }
        }
        button {
            cursor: not-allowed;
        }
    }

}

.pacchetto-owned,
.pacchetto-in-evidenza {
    @media screen and (max-width: $max-width-mobile) {
        margin-top: 3rem;
    }
}

.pacchetto-in-evidenza {
    @include box-shadow(0px 0px 15px 7px rgba(0,0,0,0.12));
    .pacchetto-in-evidenza-alert {
        font-size: 1rem;
        font-weight: 500;
        img {
            width: 1.375rem;
            margin-right: 0.5rem;
            vertical-align: top;
        }
    }
}

.pacchetto-owned-alert,
.pacchetto-in-evidenza-alert {
    position: absolute;
    margin-top: -2rem;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.6rem;
    > [class*='icon-genya-'] {
        margin-right: 0.25rem;
        vertical-align: text-top;
        font-size: 1.2rem;
    }
}

.colore-pacchetto-professional-01 {
    @include colorepacchetto($colore-pacchetto-professional-01);
}
.colore-pacchetto-professional-02 {
    @include colorepacchetto($colore-pacchetto-professional-02);
}
.colore-pacchetto-professional-03 {
    @include colorepacchetto($colore-pacchetto-professional-03);
}
.colore-pacchetto-professional-04 {
    @include colorepacchetto($colore-pacchetto-professional-04);
}
.colore-pacchetto-professional-05 {
    @include colorepacchetto($colore-pacchetto-professional-05);
}
.colore-pacchetto-professional-06 {
    @include colorepacchetto($colore-pacchetto-professional-06);
}
.colore-pacchetto-professional-07 {
    @include colorepacchetto($colore-pacchetto-professional-07);
}
.colore-pacchetto-professional-08 {
    @include colorepacchetto($colore-pacchetto-professional-08);
}


.colore-pacchetto-aziende-01 {
    @include colorepacchetto($colore-pacchetto-aziende-01);
}
.colore-pacchetto-aziende-02 {
    @include colorepacchetto($colore-pacchetto-aziende-02);
}
.colore-pacchetto-aziende-03 {
    @include colorepacchetto($colore-pacchetto-aziende-03);
}
.colore-pacchetto-aziende-04 {
    @include colorepacchetto($colore-pacchetto-aziende-04);
}
.colore-pacchetto-aziende-05 {
    @include colorepacchetto($colore-pacchetto-aziende-05);
}
.colore-pacchetto-aziende-06 {
    @include colorepacchetto($colore-pacchetto-aziende-06);
}
.colore-pacchetto-aziende-07 {
    @include colorepacchetto($colore-pacchetto-aziende-07);
}
.colore-pacchetto-aziende-08 {
    @include colorepacchetto($colore-pacchetto-aziende-08);
}

.colore-pacchetto-fatturasmart-aziende-06 {
    @include colorepacchetto($colore-pacchetto-fatturasmart-aziende-06);
}
.colore-pacchetto-fatturasmart-aziende-07 {
    @include colorepacchetto($colore-pacchetto-fatturasmart-aziende-07);
}
.colore-pacchetto-fatturasmart-aziende-08 {
    @include colorepacchetto($colore-pacchetto-fatturasmart-aziende-08);
}

.genya-accordion {
    background-color: #fbfbfb;
    margin-top: 3rem;
    .accordion {
        margin: auto;
        position: relative;
        padding: 1rem 0 0 0;
        color: $white;
        list-style: none;
        &.accordion-professional {
            background-color: $wkBlueDarker;
        }
        &.accordion-aziende {
            background-color: $wkPurple;
        }
        .accordion-title {
            color: rgba(255,255,255,.75);
            padding: 1rem 0 2rem 3rem;
            @media screen and (max-width: $max-width-tablet) {
                padding: 2rem 0 2rem 1rem;
            }
            .accordionTitleText {
                font-size: 1.4em;
            }
        }
        .accordion-item {
            padding-left: 4rem;
            @media screen and (max-width: $max-width-tablet) {
                //padding-left: 1rem;
            	word-wrap: break-word;
            	max-width: 60%;
            }
            @media screen and (max-width: $min-width-tablet) {
                margin-left: 2rem;
                padding-left: 0;
                max-width: 100%;
            }
            .accordion-itemTitle {
                display: block;
                width: 60%;
                color: #fff;
                font-size: 1.175rem;
                line-height: 2.3;
                padding: 0.9rem;
                cursor: pointer;
                @media screen and (max-width: $min-width-tablet) {
                    width: auto;
                }
                &:hover {
                    background-color: $white;
                    a {
                        color: $wkBlueDarker;
                        &:visited {
                            color: $wkBlueDarker;
                        }
                        &:hover,
                        &:active {
                            color: $wkBlueDarker;
                            text-decoration: none;
                        }
                    }
                }
                a {
                    color: $white;
                    &:visited {
                        color: $white;
                    }
                    &:hover,
                    &:active {
                        color: $wkBlueDarker;
                        text-decoration: none;
                    }
                }
            }
            .accordion-content {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                display: none;
                padding: 1rem;
                width: 60%;
                background-color: $white;
                overflow: hidden;
                @media screen and (max-width: $min-width-tablet) {
                    position: relative;
                    width: 100%;
                    padding: 1rem 0.5rem;
                	background-color: $wkGray5;
                }
                p {
                    font-size: 1.09rem;
                    line-height: 1.5;
                    color: $textColor;
                    font-weight: 300;
                    @media screen and (max-width: $min-width-tablet) {
                        font-size: 1.09rem;
	                    line-height: 1.5;
                    }
                    a {
                        text-decoration: underline;
                    }
                }
            }
            &.active {
                background-color: $white;
                .accordion-content {
                    display: block;
                }
                a {
                    color: $wkBlueDarker;
                    &:visited {
                        color: $wkBlueDarker;
                    }
                    &:hover,
                    &:active {
                        color: $wkBlueDarker;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.genya-scopri {
    position: relative;
    text-align: right;
    max-width: 55%;
    margin: 3rem auto;
    @media screen and (max-width: $max-width-mobile) {
        max-width: 100%;
        margin: 1rem auto;
    }
    @media screen and (min-width: 600px) and (max-width: $max-width-tablet) {
        max-width: 80%;
        margin: 1rem auto;
    }
    .soluzione-azienda,
    .soluzione-professional {
        font-family: $font-stack-serif;
        padding: 2rem;
        p {
            text-align: center;
            font-size: 1.6rem;
            color: white;
            font-style: italic;
            @media screen and (max-width: $max-width-mobile) {
                text-align: left;
            }
        }
    }
    .soluzione-azienda {
        background-color: $wkPurple;
    }
    .soluzione-professional {
        background-color: #241866;
    }
    [wk-button] {
        padding-left: 1.3rem;
        padding-right: 1.3rem;
    }
}

.row-pacchetti-obscured {
    position: relative;
    > div:after {
        content: ' ';
        width: 100%;
        height: 140px;
        background-image: linear-gradient(rgba(237, 237, 237, 0), rgba(237, 237, 237, 0.5), rgba(237, 237, 237, 0.8), rgba(237, 237, 237, 0.97), rgba(237, 237, 237, 1));
        position: absolute;
        bottom: 0;
        margin: auto;
    }
}

.nome-pacchetto {
    padding: 2rem 0;
    font-size: 2rem;
    border-bottom: 1px solid #d3d3d3;
    > span {
        display: block;
        width: 100%;
        font-style: italic;
        font-size: 1.1rem;
    }
}

/* Checkout con form */
.checkout {
    position: relative;
    .checkout-fasi {
        display: inline-block;
        width: 100%;
        margin-top: 1rem;
        > div {
            border-top: 1px solid #d3d3d3;
            border-bottom: 1px solid #d3d3d3;
            &:first-child {
                border-left: 1px solid #d3d3d3;
            }
            &:last-child {
                border-right: 1px solid #d3d3d3;
            }
        }
        .checkout-fase {
            position: relative;
            display: inline-block;
            float: left;
            text-align: center;
            width: 33.333%;
            margin: 0;
            padding: 0.5rem;
            background-color: $white;
            color: $textColor;
            //border-right: 1px solid $white;
            @media screen and (max-width: 600px) {
                border-right: 1px solid #d3d3d3;
            }
            @media screen and (max-width: $max-width-mobile) {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            @media screen and (min-width: 600px) and (max-width: $max-width-tablet) {
                overflow: visible;
            }
            > span {
                &:before {
                    content: ' ';
                    width: 23px;
                    height: 9px;
                    position: absolute;
                    border-top: 1px solid #d3d3d3;
                    -webkit-transform: rotate(52deg);
                    transform: rotate(52deg);
                    top: 7px;
                    right: 0;
                    @media screen and (max-width: 600px) {
                        display: none;
                    }
                }
                &:after {
                    content: ' ';
                    width: 23px;
                    height: 9px;
                    position: absolute;
                    border-top: 1px solid #d3d3d3;
                    -webkit-transform: rotate(128deg);
                    transform: rotate(128deg);
                    bottom: 7px;
                    right: 0;
                    @media screen and (max-width: 600px) {
                        display: none;
                    }
                }
            }
            &.active {
                > span {
                    display: none;
                }
                background-color: $wkBlue;
                color: $white;
                &:after {
                    content: '';
                    width: 0;
                    height: 0;
                    border-top: 18px solid transparent;
                    border-bottom: 18px solid transparent;
                    border-left: 14px solid #007ac3;
                    position: absolute;
                    right: -13px;
                    top: 0;
                    z-index: 9;
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
                &:not(:first-child) {
                    @media screen and (min-width: 600px) {
                        &:before {
                            content: '';
                            width: 0;
                            height: 0;
                            border-top: 20px solid transparent;
                            border-bottom: 20px solid transparent;
                            border-left: 14px solid $wkGray5;
                            position: absolute;
                            left: -1px;
                            top: -2px;
                            z-index: 9;
                        }
                    }
                }
            }
            &.done {
                > span {
                    display: none;
                }
                background-color: $wkGray5;
                border-left: 1px solid #d3d3d3;
                @media screen and (max-width: $max-width-mobile) {
                    padding-left: 28px;
                    text-align: left;
                }
                &:before {
                    content: '\0045';
                    font-family: "genya icons";
                    position: absolute;
                    left: 8px;
                    color: green;
                    font-size: 1rem;
                    top: 6px;
                    padding-right: 5px;
                }
            }
            &:not(.active):last-child {
                width: 32%;
                &:after {
                    content: '';
                    width: 0;
                    height: 0;
                    border-top: 18px solid transparent;
                    border-bottom: 19px solid transparent;
                    border-left: 14px solid $white;
                    position: absolute;
                    right: -14px;
                    top: -1px;
                    z-index: 9;
                }
                > span {
                    &:before {
                        content: ' ';
                        width: 22px;
                        height: 6px;
                        position: absolute;
                        border-top: 1px solid #d3d3d3;
                        @include rotate(52);
                    	top: 7px;
                    	right: -16px;
                        z-index: 99;
                        @media screen and (max-width: 600px) {
                            display: none;
                        }
                    }
                    &:after {
                        content: ' ';
                        width: 23px;
                        height: 9px;
                        position: absolute;
                        border-top: 1px solid #d3d3d3;
                        @include rotate(126);
                    	bottom: 7px;
                    	right: -15px;
                        z-index: 99;
                        @media screen and (max-width: 600px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .checkout-left-column {
        position: relative;
        width: 68%;
        margin-right: 2%;
        float: left;
        background-color: $white;
        @media screen and (max-width: $max-width-mobile) {
            width: 100%;
            margin-right: 0;
        }
    }
    .checkout-dati-personali {
        margin-top: 4rem;
    	//margin-bottom: 1rem;
        @media screen and (max-width: $max-width-mobile) {
            margin-top: 1rem;
        }
    }
    .checkout-dati-fatturazione {
        margin-top: 1rem;
    }
    .checkout-button-container {
        > button {
            float: right;
        }
    }
    .checkout-condizioni {
        background-color: $wkGray5;
        padding: 2rem;
        .wk-field-item {
            margin-bottom: 0;
        }
        .wk-checkbox-field {
            label {
                font-weight: 400;
            }
        }
        button + em {
            display: block;
            margin-top: 1rem;
        }
    }
    .checkout-form-container {
        width: 50%;
        padding-top: 2rem;
        @media screen and (max-width: $max-width-mobile) {
            width: 100%;
        }
        &.full {
            width: 100%;
        }
    }
    .wk-field-item {
        margin-bottom: 2rem;
        &.select-iban {
            float: left;
            width: 33%;
            margin-top: 1rem;
            margin-right: 2%;
            margin-bottom: 0;
            @media screen and (max-width: $max-width-mobile) {
                width: 100%;
                margin-right: 0;
                margin-top: 0;
            }
        }
        &.codice-iban {
            float: left;
            width: 65%;
            margin-top: 1rem;
            margin-bottom: 0;
            @media screen and (max-width: $max-width-mobile) {
                width: 100%;
                margin-top: 0;
            }
        }
        &.metodo-carta-codice-fiscale {
            width: 50%;
            @media screen and (max-width: $max-width-mobile) {
                width: 100%;
            }
        }
    }
    .radiobutton-group {
        &:not(.radiobutton-group-images) {
            .wk-radio-field {
                float: left;
                margin-right: 1rem;
                span {
                    font-weight: normal;
                }
            }
        }
        &.radiobutton-group-images {
            label {
                img {
                    width: 80px;
                    margin-right: 1rem;
                }
            }
        }
    }
    .checkout-conferma {
        max-width: 40rem;
        margin: auto;
        line-height: 2.4rem;
        .checkout-conferma-sommario {
            > strong {
                display: block;
                margin: 1rem auto;
            	font-size: 1.2rem;
            }
            .checkout-conferma-sommario-riga {
                display: inline-block;
                font-size: 1.3rem;
                padding-bottom: 1rem;
                padding-top: 0.5rem;
                width: 100%;
                border-bottom: 1px solid #d3d3d3;
                .checkout-conferma-sommario-riga-prodotto {
                    float: left;
                    width: 50%;
                    > strong {
                        width: 100%;
                       display: block;
                    }
                    > span {
                        display: inline-block;
                        font-size: 1.1rem;
                        color: $wkGreen;
                        > strong {
                            font-size: 1.3rem;
                        }
                    }
                }
                .checkout-conferma-sommario-riga-iva {
                    float: left;
                    width: 25%;
                    text-align: center;
                }
                .checkout-conferma-sommario-riga-prezzo {
                    float: left;
                    width: 25%;
                    text-align: right;
                }
                &:first-of-type {
                    .checkout-conferma-sommario-riga-prodotto,
                    .checkout-conferma-sommario-riga-iva,
                    .checkout-conferma-sommario-riga-prezzo {
                        color: $wkGray1;
                        font-weight: 300;
                    }
                }
                &.riga-small {
                    width: 50%;
                    margin-left: 50%;
                    display: inline-block;
                    @media screen and (max-width: $max-width-mobile) {
                        width: 100%;
                        margin-left: 0;
                    }
                    .checkout-conferma-sommario-riga-iva,
                    .checkout-conferma-sommario-riga-prezzo {
                        font-size: 1rem;
                        > strong {
                            display: block;
                            width: 100%;
                            font-size: 1.375rem;
                        }
                        > span {
                            font-size: 0.9rem;
                            color: $wkGray1;
                        }
                    }
                    .checkout-conferma-sommario-riga-iva {
                        text-align: left;
                        width: 60%;
                    }
                    .checkout-conferma-sommario-riga-prezzo {
                        width: 40%;
                    }
                }
            }
            .checkout-conferma-sommario-info {
                padding-top: 2rem;
                p {
                    line-height: 1.5;
                }
            }
        }
        .wk-alert[wk-context='info'] {
            margin-top: 2rem;
            font-size: 1rem;
            line-height: 1.5;
        }
    }
    &.checkout-metodo-pagamento {
        .row {
            > div {
                margin-top: 2rem;
                @media screen and (max-width: $max-width-mobile) {
                    margin-top: 1rem;
                    &:not(:first-of-type) {
                        margin-top: 0;
                    }
                    &:last-of-type {
                        margin-bottom: 2rem;
                    }
                }
            }
        }
        .checkout-carrello {
            margin-top: 0;
        }
    }
}

.checkout-card {
    border: 1px solid #dadada;
    background-color: $white;
    margin-bottom: 1rem;
    // Utilizzato per pareggiare le altezze nella pagin Accesso
    .element-invisible {
        visibility: hidden;
        @media screen and (max-width: $min-width-tablet) {
            display: none;
        }
    }
    .checkout-card-title {
        color: $white;
        font-family: $font-stack-serif;
        font-style: italic;
        padding: 1rem;
        font-size: 1.3rem;
        width: auto;
        position: absolute;
        top: -2.6rem;
        @media screen and (max-width: $max-width-mobile) {
            position: relative;
            top: 0;
        }
        &.blue {
            background-color: $wkBlueDarker;
        }
        &.orange {
            background-color: $wkOrange;
        }
        &.purple {
            background-color: $wkPurple;
        }
    }
    .checkout-card-content {
        padding: 1.6rem;
        .wk-h2 {
            margin-bottom: 1rem;
            margin-top: 0;
        }
        p {
            font-size: 1rem;
        }
        .form-nota {
            margin-top: 1rem;
            margin-bottom: 0;
            color: $wkGray1;
            > span {
                color: $navBlue;
            }
        }
        .field-scadenza{
            margin-right: 0!important;
            width: 50%;
            @media screen and (max-width: $min-width-tablet) {
                width: 100%;
            }
        }
        .checkout-metodi-accettati {
            img {
                display: inline-block;
                height: 54px;
                margin-right: 1rem;
                @media screen and (max-width: $min-width-tablet) {
                    width: 20%;
                    height: auto;
                    margin-right: 8px;
                }
            }
        }
        .cvv-field {
            padding: 2rem;
            border: 1px solid $wkGray4;
            float: none;
            display: inline-block;
            .wk-field-body {
                display: block;
                float: left;
                width: 40%;
                @media screen and (max-width: $max-width-mobile) {
                    width: 100%;
                }
                input {
                    width: 7rem;
                    @media screen and (max-width: $max-width-mobile) {
                        width: 100%;
                    }
                }
            }
            .cvv-help-container {
            	display: block;
                float: left;
                width: 60%;
                @media screen and (max-width: $max-width-mobile) {
                    margin-top: 1rem;
                    width: 100%;
                }
                .cvv-help-img,
                .cid-help-img {
                    display: inline-block;
                    position: relative;
                    margin-right: 2rem;
                    cursor: pointer;
                    @media screen and (max-width: 400px) {
                        img {
                            width: 50px;
                        }
                    }
                    &:after {
                        content: '\004a';
                        display: block;
                        font-family: 'genya icons';
                        font-size: 1rem;
                        color: $wkGray3;
                        position: absolute;
                        top: 0.2rem;
                        right: -1.4rem;
                        cursor: pointer;
                    }
                }
                .cvv-help {
                    position: relative;
                    display: inline-block;
                    margin-right: 1rem;
                    width: 100%;
                    .cvv-help-text,
                    .cid-help-text {
                        display: none;
                        width: 240px;
                        padding: 4px;
                        @media screen and (max-width: $min-width-tablet) {
                            width: 100%;
                        }
                    }
                    .cid-help-text {
                    	margin-left: 120px;
                        width: 70%;
                        @media screen and (max-width: $max-width-mobile) {
                            margin-left: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
        .pagaclick-field,
        .ricorrenza-pagamento-field {
            border: 1px solid $wkGray4;
            padding: 2rem;
            float: left;
            > span {
                font-weight: normal;
                font-size: 1.3rem;
                margin-bottom: 1rem;
                display: block;
            }
            > p {
                line-height: 1.5;
                margin-bottom: 1rem;
            }
            > .wk-radio-field {
                display: inline-block;
                float: left;
                margin-right: 1rem;
            }
        }
        .opzione-pagamento-rinnovo {
            border: 1px solid $wkGray4;
            padding: 2rem;
            > .wk-field-header {
                margin-bottom: 1rem;
            }
            > .wk-radio-field {
                display: inline-block;
                float: left;
                margin-right: 1rem;
                @media screen and (max-width: $max-width-mobile) {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
    .checkout-card-selezione-metodo {
        display: inline-block;
    	padding: 1.6rem 1.6rem 0 1.6rem;
        //margin-bottom: 0;
        .wk-field-header {
            margin: 1rem 0 2rem 0;
            font-size: 1.2rem;
            line-height: 1.5;
        }
        .wk-radio-field {
            display: inline-block;;
            width: 45%;
            float: left;
            @media screen and (max-width: $max-width-mobile) {
                width: 100%;
                margin-bottom: 1rem;
            }
            label {
                position: relative;
                font-size: 1.1rem;
                line-height: 1.5;
                font-weight: 500;
                input {
                    &.radio-carta + span,
                    &.radio-cc + span {
                        &:before {
                            content: '';
                            display: block;
                            width: 2rem;
                            height: 2rem;
                            background-size: contain;
                            background-repeat: no-repeat;
                            display: block;
                            vertical-align: top;
                            margin: 0 0.5rem;
                            position: absolute;
                            top: 0;
                            left: 1rem;
                        }
                    }
                    &.radio-carta + span {
                        &:before {
                            background-image: url('../images/credit-card-icon.png');
                        }
                    }
                    &.radio-cc  + span {
                        &:before {
                            background-image: url('../images/bank-account-icon.png');
                        }
                    }
                }
                span:not(:first-of-type) {
                    display: block;
                    margin-left: 4.5rem;
                    margin-top: -2rem;
                    vertical-align: top;
                }
            }
        }
    }
    .metodo-carta,
    .metodo-cc,
    .metodo-salvato {
        display: none;
    }
    .metodo-salvato {
        a[wk-button] {
            min-width: 140px;
        }
    }
    .field-metodo-salvato {
        padding: 1.6rem;
        margin-bottom: 0;
        .wk-h3 {
            margin-bottom: 2rem;
        }
        .wk-field-body {
            width: 50%;
            @media screen and (max-width: 100%) {
                width: 100%;
            }
        }
        & + .checkout-card-selezione-metodo {
            display: none;
        }
    }
    &.checkout-card-1-3 {
        float: left;
    }
}

.checkout-carrello {
    margin-top: 4rem;
    padding: 1rem;
    background-color: $white;
    border: 3px solid $wkBlue;
    @media screen and (max-width: $min-width-tablet) {
        margin-top: 1rem;
    }
    &.affix {
        margin-top: 0;
        @media screen and (max-width: $min-width-md) {
            position: relative;
            width: 100%!important;
        }
    }
    > span {
        display: block;
        font-size: 1.2rem;
        padding-bottom: 0.5rem;
    }
    .checkout-carrello-riga {
        font-weight: 300;
        width: 100%;
        border-top: 1px solid #d3d3d3;
        padding: 1rem 0;
        .checkout-carrello-riga-riepilogo-prezzo {
            float: right;
            text-align: right;
            font-weight: 400;
            font-style: normal;
        }
        .checkout-carrello-riga-riepilogo-nomepacchetto {
            font-size: 0.8rem;
            font-style: normal;
            margin-bottom: 0;
        }
        .checkout-carrello-riga-riepilogo-totaleanagrafiche {
            font-size: 0.8rem;
            color: $wkGray2;
            font-weight: normal;
        }
        > p {
            width: 100%;
            font-weight: 300;
            font-size: 0.8rem;
        }
        &.checkout-carrello-riga-riepilogo {
            font-weight: 500;
            line-height: 1.5;
            font-size: 0.8rem;
            .checkout-carrello-riga-riepilogo-top {
                > span:first-of-type {
                    font-style: italic;
                }
            }
        }
        &.checkout-carrello-riga-totale {
            > span {
                font-weight: 500;
            }
        }
    }
    .checkout-carrello-rinnovo {
        background-color: $wkGray5;
        padding: 0.5rem;
        > span {
            float: right;
            text-align: right;
            font-weight: 400;
        }
        > p {
            width: 100%;
            font-weight: 300;
            font-size: 0.8rem;
        }
    }
}

.checkout-carrello-riga {
    &.checkout-carrello-upgrade {
        .checkout-carrello-riga-riepilogo-infopacchetto {
            font-size: 0.8rem;
            font-weight: normal;
            color: $wkGray1;
        }
        .checkout-carrello-riga-riepilogo-nomepacchetto {
            font-size: 0.8rem;
            font-style: italic;
            margin-bottom: 0;
        }
        .checkout-carrello-riga-riepilogo-totaleanagrafiche {
            font-size: 0.8rem;
            color: $wkGray2;
        }
        &.checkout-carrello-riga-riepilogo {
            span:first-of-type.checkout-carrello-riga-riepilogo-upgrade {
                font-style: normal;
                font-weight: 700;
                color: $textColor;
            }
            .checkout-carrello-riga-riepilogo-prezzo {
                font-style: normal;
            }
            .checkout-carrello-riga-riepilogo-infopacchetto {
                > span {
                    font-style: italic;
                    font-weight: normal;
                    color: $wkGray1;
                    font-size: 0.8rem;
                }
            }
        }
    }
}

.checkout-col-accesso {
    padding-right: 0;
    @media screen and (max-width: $max-width-tablet) {
        //padding-right: 15px;
    }
    @media screen and (max-width: $max-width-mobile) {
        margin-top: 2rem;
        padding-right: 15px;
    }
    .checkout-card {
        width: 48%;
        margin-right: 2%;
        float: left;
        margin-top: 4rem;
        position: relative;
        @media screen and (max-width: $min-width-tablet) {
            width: 100%;
            margin-right: 0;
            margin-top: 3rem;
            //margin-bottom: 2rem;
        }
        @media screen and (max-width: 360px) {
            margin-top: 0;
            margin-bottom: 1rem;
        }
        .checkout-card-content {
            padding: 2rem;
            p {
                font-size: 1.3rem;
                min-height: 6rem;
                margin-bottom: 2rem;
            }
            &.checkout-card-verifica-iva {
                .form-container {
                    .wk-field-item {
                        .wk-field-body {
                            input {
                                color: $textColor;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
        a[wk-button="wk-button"] {
            width: 100%;
        }
    }
    & + div {
        padding-left: 0;
        @media screen and (max-width: $max-width-sm) {
            padding-left: 15px;
            .checkout-carrello {
                margin-top: 1rem;
            }
        }
        @media screen and (max-width: $max-width-mobile) {
            margin-top: 0;
            padding-left: 15px;
        }
    }
}

/* Area Riservata */
.reserved-area-card {
    position: relative;
    background-color: $white;
    margin-top: 5rem;
    @media screen and (max-width: $max-width-mobile) {
        margin-top: 2rem;
    }
    .reserved-area-card-title {
        color: $white;
        font-family: $font-stack-serif;
        font-style: italic;
        padding: 1rem;
        font-size: 1.3rem;
        width: auto;
        position: absolute;
        top: -2.6rem;
        @media screen and (max-width: $max-width-mobile) {
            position: relative;
            top: 0;
        }
        &.blue {
            background-color: $wkBlueDarker;
        }
        &.orange {
            background-color: $wkOrange;
        }
        &.purple {
            background-color: $wkPurple;
        }
    }
    .reserved-area-card-content {
        background-color: $white;
        padding: 3rem 1rem 1rem 1rem;
        @media screen and (max-width: $max-width-mobile) {
            padding: 1rem;
        }
        p {
            font-size: 1.2rem;
            > span {
                color: $infoTextColor;
            }
            > span.stato-abbonamento-attivo {
                color: $wkGreen;
            }
        }
        .reserved-area-card-content-sub {
            border-top: 1px solid #d3d3d3;
            margin-top: 2rem;
            padding-top: 1rem;
            p {
                font-size: 1.2rem;
            	margin: 1rem 0;
            }
            .wk-h3 {
                font-size: 1.5rem;
            }
            .icon-genya-phone {
                font-size: 1rem;
            }
        }
        .panel + .reserved-area-card-content-sub {
            border-top: none;
            margin-top: 0;
        }
        &.pacchetto {
            .wk-h4 {
                padding: 0 1rem;
                font-style: italic;
                font-size: 1.6rem;
                font-weight: 500;
                color: $textColor;
            }
            .pacchetto-anagrafiche {
                width: 50%;
            	float: left;
            	border: 0;
            	white-space: normal;
            	font-size: 1rem;
            	padding-right: 0.5rem;
                @media screen and (max-width: $max-width-mobile) {
                    width: 100%;
                }
            }
            .pacchetto-incluso {
                border-right: none;
                border-left: 1px solid #d3d3d3;
                padding: 0 1rem;
                ul {
                    li {
                        @media screen and (max-width: $min-width-tablet) {
                            font-size: 0.8rem;
                        }
                    }
                }
                @media screen and (max-width: $max-width-mobile) {
                    border-left: 0;
                    border-top: 1px solid #d3d3d3;
                    padding: 1rem 0;
                }
                @media screen and (max-width: $min-width-md) {
                    padding-left: 1rem;
                    padding-right: 0;
                }
            }
            .pacchetto-dettagli {
            	width: 100%;
                padding: 0;
            	margin: 1rem auto 0 auto;
            }
            .pacchetto-button {
                display: inline-block;
            	//width: 50%;
                @media screen and (max-width: $max-width-mobile) {
                    display: block;
                    width: 100%;
                }
            }
        }
        &.fields-container {
            .wk-field-item {
                display: inline-block;
                width: 100%;
                .wk-field-header {
                    width: 30%;
                    float: left;
                    padding: 0.6rem 0;
                    margin: 0;
                    @media screen and (max-width: $max-width-mobile) {
                        width: 100%;
                    }
                }
                .wk-field-body {
                    width: 70%;
                    float: left;
                    @media screen and (max-width: $max-width-mobile) {
                        width: 100%;
                    }
                    input {
                        color: $textColor;
                        font-weight: 400;
                    }
                }
            }
        }
        .panel,
        .panel-group {
            margin-bottom: 0;
        	border-radius: 0;
        }
        .panel {
            .wk-field-item {
                label {
                    font-size: 1.1rem;
                    font-weight: 400;
                }
            }
            a[wk-button] {
                min-width: 7rem;
            }
        }
        .panel-title {
            > a:first-child {
                max-width: 90%;
                display: inline-block;
            }
        }
        .panel-default {
            border-bottom: 0;
            margin-top: 0;
            > .panel-heading {
                border-bottom: 1px solid #ddd;
                position: relative;
                color: $textColor;
                .wk-h4 {
                    a {
                        font-size: 1.2rem;
                        font-weight: 400;
                    }
                }
            }
            .panel-body {
                border-bottom: 1px solid #ddd;
            }
        }
        .accordion-sottoscrizioni-toggle {
            &:before {
                content: '-';
                position: absolute;
                width: 24px;
                height: 24px;
                border-radius: 99px;
                background-color: $wkGreen;
                color: white;
                font-size: 23px;
                text-align: center;
                font-weight: 300;
                top: 8px;
                right: 16px;
            }
            &.collapsed {
                &:before {
                    content: '+';
                }
            }
        }
    }
}

.trial-topbar {
    padding: 12px;
    text-align: center;
    background-color: #F8D6A6;
    color: $wkGray1;
    cursor: default;
    font-size: 1rem;
	line-height: 1.5;
    @media screen and (max-width: $min-width-tablet) {
        display: none;
    }
}

.inner-topbar {
    max-width: 75rem;
    margin: 0 auto;
    padding: 0.5rem;
}

.multiuser-topbar {
    background-color: $colore-pacchetto-professional-04;
    text-align: right;
    font-size: 1rem;
    line-height: 1rem;
    @media screen and (max-width: $min-width-tablet) {
        position: absolute;
        top: 3.5rem;
        left: 0;
        width: 100%;
        z-index: 9;
    }
    a {
        position: relative;
        color: $white;
        &:visited {
            color: $white;
        }
        &:hover {
            color: $white;
        }
        &:active {
            color: $white;
        }
    }
    [class*="icon-genya-"] {
        /*position: absolute;
        top: 0;
        margin-left: 0.4rem;
        font-size: 1rem;*/
        float: right;
        margin-top: -2px;
	    margin-left: 0.5rem;
        @media screen and (max-width: $min-width-tablet) {
            //position: relative;
        }
    }
}

.genya-topbar {
    width: 100%;
    background-color: #f2f2f2;
    @media screen and (max-width: $min-width-tablet) {
        display: none;
    }
    .genya-topbar-links {
        max-width: 75rem;
        margin: auto;
        padding: 7px;
        color: $textColor;
        cursor: default;
        font-size: 1rem;
        line-height: 1.5;
        a {
            color: $textColor;
            &:visited {
                color: $textColor;
            }
            &:hover {
                color: lighten($textColor, 20%);
            }
            &:active {
                color: $textColor;
            }
        }
        a:last-child {
            float: right;
        }
        [class*='icon-genya-'] {
            vertical-align: text-top;
        	margin-right: 4px;
        }
    }
}

/* Error Pages */
body.error-page {
    background-color: $white;
    .wk-page-content {
        max-width: 40rem;
        text-align: center;
        .wk-logo-large {
            display: inline-block;
            margin: 1rem auto;
        }
        .error-icon {
            display: block;
            width: 100%;
            max-width: 150px;
            margin: 2rem auto;
            @media screen and (max-width: $max-width-mobile) {
                margin: 1.4rem auto;
                max-width: 180px;
            }
        }
        .icon-404 {
            max-width: 220px;
        }
        .wk-h1 {
            font-weight: 400;
            line-height: 1.5;
        }
        .wk-h3 {
            font-weight: 400;
            line-height: 1.5;
            margin-top: 0;
        }
        p {
            margin: 2rem auto;
            font-size: 1.2rem;
            line-height: 1.5;
            @include opacity(0.8);
        }
        a[wk-button] {
            min-width: 180px;
        }
        [class*='icon-genya-'] {
            vertical-align: middle;
        }
    }
}

/* Autocomplete */
.autocomplete-suggestions { border: 1px solid #999; background: #FFF; overflow: auto; }
.autocomplete-suggestion { padding: 0.8rem 1rem; white-space: nowrap; overflow: hidden; }
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #3399FF; }
.autocomplete-group { padding: 0.8rem 1rem; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }


.inline {
    display: inline-block;
    width: auto !important;
}

.uppercase {
    text-transform: uppercase;
}

.capitalcase {
    text-transform: capitalize;
}

.lowercase {
    text-transform: lowercase;
}

.bind-addon-type {
    font-size: inherit !important;
}

.cart-content, .cart-notification 
{
    display: none;
}

.pacchetto-fatturasmart-aziende .pacchetto-button > a 
{
    background-color: #E5202E;
    &:hover {
        background-color: lighten(#E5202E, 20%) !important;
    }
}