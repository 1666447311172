$darkgrey: #474747;
$bluebrand: #302779;
$lightblue: #008CBB;
$cyan: #009391;
$brandgreen: #90B22A;
$orange: #DD9100;
$red: #DA363C;
$purple: #932384;

$wkBlue: #007ac3;
$wkBlueDarker: #241866;
$wkGray1: #757575;
$wkGray2: #a3a3a3;
$wkGray3: #bfbfbf;
$wkGray4: #dadada;
$wkGray5: #ededed;
$wkGray6: #f6f6f6;
$wkGreen: #85bc20;
$wkGreen2: #009881;
$wkOrange: #ea8f00;
$wkRed: #e5202e;
$wkPurple: #940c72;

$black: #000;
$white: #fff;

$textColor: #474747;

$seedColor: #5b528c;

$colore-pacchetto-professional-01: #241866;
$colore-pacchetto-professional-02: #2f246e;
$colore-pacchetto-professional-03: #3a3076;
$colore-pacchetto-professional-04: #453a7d;
$colore-pacchetto-professional-05: #504685;
$colore-pacchetto-professional-06: #5b528c;
$colore-pacchetto-professional-07: #665e94;
$colore-pacchetto-professional-08: #70699b;

$colore-pacchetto-aziende-01: #6f0955;
$colore-pacchetto-aziende-02: #76165e;
$colore-pacchetto-aziende-03: #7d2166;
$colore-pacchetto-aziende-04: #842e6e;
$colore-pacchetto-aziende-05: #8c3a77;
$colore-pacchetto-aziende-06: #934780;
$colore-pacchetto-aziende-07: #9a5388;
$colore-pacchetto-aziende-08: #a15f90;

$colore-pacchetto-fatturasmart-aziende-06: #006556;
$colore-pacchetto-fatturasmart-aziende-07: #007f6b;
$colore-pacchetto-fatturasmart-aziende-08: #009881;


$lightgray: lighten($wkGray6, 2%);
$navBlue: #005b92;
$infoTextColor: #A3A3A3;

$font-stack-sans: 'Fira Sans', 'Helvetica Neue', Arial, sans-serif;
$font-stack-serif: 'Franziska', serif;


$max-width-mobile: 767px;
$min-width-tablet: 768px;
$max-width-sm: 991px;
$min-width-md: 992px;
$max-width-tablet: 1024px;
