/* Cart */
.genya-modal {
    .wk-modal-content {
        position: static;
        background-color: $wkGray6;
        width: 48rem;
        margin: 2rem auto;
        @media screen and (max-width: $min-width-md) {
            left: 46%;
            width: 46rem;
        }
        @media screen and (max-width: $min-width-tablet) {
            position: relative;
            padding: 0;
            top: 0;
            left: 0;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
            width: auto;
        }
        @media screen and (max-width: $max-width-mobile) {
            top: 0;
            left: 0;
            width: auto;
        }
        .wk-modal-header {
            min-height: auto;
            button,
            a {
                background-color: transparent;
                z-index: 9999;
            }
            button.close-button,
            a.close-button {
            	padding: 0;
            	color: $white;
                font-size: 2rem;
                position: absolute;
                top: 0;
                right: 0;
            }
            .wk-modal-title {
                [class*='icon-genya-'] {
                    vertical-align: middle;
                    margin-right: 0.4rem;
                }
            }
        }
        .wk-modal-body {
        	padding: 2rem;
            width: 100%;
            @media screen and (max-width: $max-width-mobile) {
                padding: 2rem 0 0;
            }
            .cart-pacchetto-aggiunto {
                margin-top: 1rem;
                padding-left: 1rem;
                @media screen and (max-width: $max-width-mobile) {
                    width: 100%;
                    margin-top: 0;
                    padding-left: 0;
                }
            }
        }
    }
}

.cart-modal,
.anagrafiche-modal,
.cart-suite-modal {
    width: 100%;
    @media screen and (max-width: $max-width-tablet) {
        width: 100%;
        padding: 2rem!important;
    }
    @media screen and (max-width: $max-width-mobile) {
        width: 100%;
        padding: 0!important;
    }
    .wk-modal-content {
        @media screen and (max-width: $max-width-mobile) {
            margin: 0 auto;
        }
        .wk-modal-body {
            .cart-pacchetto-aggiunto-feedback {
                position: relative;
                font-size: 1.6rem;
                margin-bottom: 2rem;
                padding-left: 2rem;
                &:before {
                    position: absolute;
                    content: '\e828';
                    font-family: "wk-icons";
                    font-size: 3.5rem;
                    color: $wkGreen;
                    top: -1.3rem;
                    left: -1rem;
                }
                @media screen and (max-width: $max-width-mobile) {
                    width: 100%;
                    padding: 1rem;
                    border-bottom: 1px solid #d3d3d3;
                    > span {
                        display: block;
                        width: 100%;
                        font-size: 1.4rem;
                    }
                    &:before {
                        right: 2rem;
                    	font-size: 4rem;
                    	text-align: right;
                    }
                }
            }
            .cart-pacchetto-aggiunto-nome {
                font-size: 2rem;
                margin-bottom: 1rem;
                @media screen and (max-width: $max-width-mobile) {
                    padding: 0 1rem;
                }
                > span {
                    display: block;
                    width: 100%;
                    font-size: 1rem;
                    font-style: italic;
                }
            }
            .cart-pacchetto-aggiunto-dettagli {
                font-size: 0.9rem;
                @media screen and (max-width: $max-width-mobile) {
                    padding: 0 1rem;
                    margin-bottom: 2rem;
                }
                > span {
                    font-size: 2rem;
                    font-family: $font-stack-serif;
                    font-style: italic;
                    font-weight: 700;
                    margin-right: 4px;
                }
                ul {
                    margin-top: 0.5rem;
                    list-style-position: outside;
                    list-style-type: none;
                    padding-left: 0.5rem;
                    li {
                        font-family: $font-stack-sans;
                        font-size: 0.9rem;
                        font-style: normal;
                        position: relative;
                        padding-left: 2rem;
                        &:before {
                            content: '\e828';
                            font-family: "wk-icons";
                            font-size: 1.6rem;
                            color: $wkGreen;
                            font-weight: bold;
                            position: absolute;
                            left: 0;
                            top: -0.5rem;
                        }
                    }
                }
            }
            .cart-aggiungi-moduli {
                margin-top: 1.5rem;

                .wk-h5 {
                    font-size: 1.3rem;
                }

                .cart-piu-moduli {
                    width: 100%;
                    padding: 0.65rem 0;

                    span[wk-icon="add-on"] {
                        font-size: 1.15rem;
                        margin-top: 0.15rem;
                    }
                    .bind-descrizione-modulo {
                        font-size: 1rem;
                    }
                    .cart-piu-anagrafiche-prezzo {
                        padding: 0;
                    }
					 @media screen and (max-width: $max-width-mobile) {
						.cart-piu-moduli-descrizione {
							margin-bottom: 1rem;
							.wk-row {
								div {
									float: left;
								}
								div:first-child {
									margin-right: 0.5rem;
								}
								div:nth-child(2) {
									width: 85%;
								}
							}
						}
						.cart-piu-anagrafiche-prezzo {
							a[data-action='addToCart'] {
								margin-left: 0;
							}
						}
					}
                }
            }
            .cart-aggiungi-moduli, .cart-aggiungi-anagrafiche {
                position: relative;
                background-color: $white;
                border: 1px solid #d3d3d3;
                padding: 1rem;
                min-height: 2rem;
                @media screen and (max-width: $max-width-mobile) {
                    width: 100%;
                    padding: 2rem 1rem;
                    border: 0;
                }
                .wk-h5 {
                    font-size: 1.2rem;
                }
                .cart-piu-anagrafiche, .cart-piu-moduli {
                    position: relative;
                    padding: 0.5rem 0;
                    border-bottom: 1px solid #d3d3d3;
                    width: 90%;
                    margin: auto;
                    @media screen and (max-width: $max-width-tablet) {
                        width: 100%;
                    }
                    @media screen and (max-width: $max-width-mobile) {
                        width: 100%;
                    }
                    &:last-of-type {
                        border-bottom: 0;
                    }
                    .cart-piu-anagrafiche-numero {
                        display: inline-block;
                        width: 25%;
                        color: $wkGreen;
                        font-size: 0.9rem;
                        @media screen and (max-width: $max-width-mobile) {
                            font-size: 1rem;
                            width: 15%;
                        }
                        @media screen and (max-width: 380px) {
                            font-size: 1rem;
                            width: 30%;
                        }

                        > span {
                            font-family: $font-stack-serif;
                            font-size: 2rem;
                            font-style: italic;
                        	font-weight: 700;
                        }
                    }
                    .cart-piu-anagrafiche-prezzo {
                        display: inline-block;
                    	position: relative;
                    	padding: 1rem 0;
                        font-size: 1rem;
                        width: 75%;
                        text-align: center;
                        float: right;
                        @media screen and (max-width: $max-width-tablet) {
                            width: 70%;
                            padding: 0;
                        }
                        @media screen and (max-width: $max-width-mobile) {
                            width: 70%;
                            padding: 0;
                        }
                        @media screen and (max-width: 380px) {
                            width: 70%;
                        }
                        > span {
                            display: inline-block;
                            font-size: 1.6rem;
                        }
                        button,
                        a {
                            position: relative;
                            float: right;
                            padding: 0 10px;
                            margin-left: 1rem;
                            font-size: 1.6rem;
                            width: 36px;
                            height: 36px;
                            height: 36px;
                            line-height: 2.2rem;
                            @media screen and (max-width: $max-width-tablet) {
                                margin-left: 0.5rem;
                            }
                            @media screen and (max-width: $max-width-mobile) {
                                margin-left: 4rem;
                            }
                            @media screen and (max-width: 360px) {
                                margin-left: 0.5rem;
                            }
                            > span {
                                &:before {
                                    font-size: 1.1rem;
                                    line-height: 1.5;
                                    padding: 0;
                                }
                            }
                            &:visited {
                                text-decoration: none;
                            }
                            &:hover {
                                text-decoration: none;
                            }
                            &:active {
                                text-decoration: none;
                            }
                        }
                    }
                }
                .cart-piu-anagrafiche-modulo-row {
                    border-bottom: 1px solid #d3d3d3;
                    &:first-of-type {
                        margin-top: 2rem;
                    }
                    @media screen and (max-width: $max-width-mobile) {
                        margin-right: 0.4rem;
                    }
                    .cart-piu-anagrafiche-modulo {
                        margin: 1rem 0;
                        padding: 0 0.5rem;
                        > span:first-child {
                            display: inline-block;
                            font-size: 1.4rem;
                            color: #474747;
                            font-weight: 700;
                            font-family: "Merriweather", serif;
                            font-style: italic;
                        }
                        .aggiungi-anagrafiche-small-button {
                            padding: 0.25rem 0.75rem;
                            font-size: 0.8rem;
                            float: right;
                            margin-right: 0.75rem;
                            @media screen and (max-width: $max-width-mobile) {
                                width: auto;
                                margin-right: 0;
                            }
                        }
                    }
                }
                .cart-piu-anagrafiche-modulo-opzioni {
                    display: none;
                    margin-bottom: 1rem;
                    .cart-piu-anagrafiche {
                        border: 0;
                        min-height: 2rem;
                        .aggiungi-anagrafiche-small-button-close {
                            padding: 0.25rem 0.75rem;
                            font-size: 0.8rem;
                            text-align: right;
                            float: right;
                            background: $wkGray2;
                            @media screen and (max-width: $max-width-mobile) {
                                width: 90px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
            .cart-piu-anagrafiche-modulo-elimina-aggiuntive {
                display: block;
                text-align: right;
                margin: 1rem 0 0;
                background-color: white;
                text-decoration: underline;
            }
            .cart-totale {
                position: relative;
                background-color: $white;
                border: 1px solid #d3d3d3;
                padding: 1rem;
                margin-top: 2rem;
                display: block;
                @media screen and (max-width: $max-width-mobile) {
                    display: none;
                }
                .cart-totale-anagrafiche {
                    width: 70%;
                    float: left;
                    font-weight: 500;
                    text-align: center;
                    padding: 1rem 1rem 0 1rem;
                    @media screen and (max-width: $min-width-tablet) {
                        width: 50%;
                    }
                    > span {
                        width: 100%;
                        display: block;
                        font-size: 2rem;
                        padding-bottom: 0.8rem;
                        font-style: italic;
                        font-family: $font-stack-serif;
                        font-weight: 700;
                    }
                    .cart-totale-anagrafiche-dettaglio, .cart-totale-moduli-dettaglio {
						clear: both;
                        border-top: 1px solid #d3d3d3;
                        padding: 0.3rem 0;
                        color: $wkGray1;
                        font-weight: 400;
                        text-align: left;
                        line-height: 1.6rem;

                        .bind-addon-type {
                            float: left;
                        }
                        > span:first-child {
                        	margin-right: 0.75rem;
                            font-size: 1.4rem;
                        	width: 18%;
                        	display: inline-block;
                        	overflow: visible;
                        	//height: 3rem;
                            @media screen and (max-width: $max-width-tablet) {
                                display: inline-block;
                                float: left;
                            }
                        }
                        > span:nth-child(2) {
                            //color: #757575;
                        	font-weight: 400;
                        	text-align: left;
                        	line-height: 1.6rem;
                            vertical-align: text-bottom;
                        }
                        button {
                            background-color: $wkGreen;
                            padding: 0px;
                            margin-left: 1rem;
                            font-size: 1.4rem;
                            > span {
                                &:before {
                                	font-weight: 700;
                                }
                            }
                        }
                        .icon-action {
                            display: inline-block;
                            float: right;
                            line-height: 2rem;
                        }
                        .bind-totale-anagrafiche-aggiuntivi {
						    float: left;
						    min-height: 2.8rem;
					    }
					    .bind-addon-type  {
						    line-height: 0.8rem;					
					    }	
                        .bind-pacchetto-aggiunto-label-anagrafiche {
						    min-height: 2.8rem;		
					    }
                    }
					.cart-totale-moduli-dettaglio {
						border-top: none;
					}
					.cart-totale-moduli-header {
						font-weight: 500;
					}
                }
                .cart-totale-prezzo {
                    width: 30%;
                    float: left;
                    display: block;
                    border-left: 1px solid #d3d3d3;
                    @media screen and (max-width: $min-width-tablet) {
                        width: 50%;
                    }
                    > div {
                        width: 100%;
                        float: left;
                        padding: 1rem 0.5rem;
                        &:first-child {
                            font-weight: 500;
                            text-align: center;
                            > span {
                                font-size: 0.7rem;
                                color: $wkGray1;
                            }
                            > span:first-of-type {
                                display: block;
                                width: 100%;
                                font-size: 2rem;
                                color: $textColor;
                                font-weight: 400;
                                font-family: "Merriweather", serif;
                            }
                            > p {
                                margin-top: 0.5rem;
                                text-transform: none;
                                font-weight: 400;
                                font-size: 0.9rem;
                            	padding: 0 1rem;
                                > span {
                                    font-size: 1.1rem;
                                }
                            }
                        }
                        &:last-child {
                        	padding: 1rem 0.5rem;
                            text-align: center;
                            button,
                            a:first-of-type {
                                margin-bottom: 2rem;
	                            padding: 1.4rem 2rem;
                            }
                            a:last-of-type {
                                text-decoration: underline;
                                display: block;
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .cart-totale-mobile {
                display: none;
                background-color: $wkBlue;
                color: $white;
                font-size: 0.9rem;
                padding: 0.5rem 0.5rem 0 0.5rem;

                @media screen and (max-width: $max-width-mobile) {
                    display: block;
                }
                @media screen and (max-width: 380px) {
                    font-size: 0.8rem;
                }
                .cart-totale-mobile-riepilogo {
                    width: 100%;
                    div {
                        float: left;
                        padding: 0.5rem;
                        &:first-child {
                            width: 60%;
                            > span {
                                font-size: 1.4rem;
                            }
                        }
                        &:last-child {
                            width: 40%;
                            padding-bottom: 0;
                        }
                    }
                    button {
                        background-color: $wkGreen;
                    }
                    a.cart-totale-mobile-opener {
                        display: block;
                        width: 100%;
                        color: $white;
                        text-decoration: underline;
                        margin-top: 1rem;
                        margin-bottom: 0.5rem;
                    }
                }
                .cart-totale-mobile-dettagli {
                    border-top: 1px solid $white;
                    width: 100%;
                    padding: 0.5rem;
                    > div {
                        float: left;
                        padding: 0 0.5rem;
                        margin-top: 0.5rem;
                        font-size: 0.8rem;
                        &:first-child {
                            padding-left: 0;
                            width: 60%;
                            padding-top: 0;
                            border-right: 1px solid $white;
                        }
                        &:last-child {
                            padding-right: 0;
                            width: 40%;
                            font-size: 0.9rem;
                        }
                        .cart-totale-mobile-dettagli-row {
                            font-size: 0.9rem;
                            line-height: 1.1rem;
                            max-height: 3.3rem;
                            overflow-y: hidden;
                            [class*="icon-genya-"] {
                                color: $white;
                            }
                            .icon-action {
                                display: inline-block;
                            	vertical-align: middle;
                            }
                            > span:first-child {
                                font-size: 1.4rem;
                                margin-right: 1.3rem;
                                width: 17%;
                                display: block;
                                float: left;
                                overflow: visible;
                                height: 3rem;
                            }
                            > span:nth-child(2) {
                                font-size: 0.9rem;
                            	line-height: 1.1rem;
                            	max-height: 3.3rem;
                            	overflow-y: hidden;
                            }
                            button {
                                margin-bottom: 1rem;
                            	background-color: $wkGreen;
                            	padding: 0px;
                            	margin-left: 1rem;
                            	font-size: 1.4rem;
                                width: 36px;
                                height: 36px;
                                @media screen and (max-width: $max-width-mobile) {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.anagrafiche-modal {
    .wk-modal-content {
        width: 52rem;
        @media screen and (max-width: $max-width-tablet) {
            width: 46rem;
        }
        @media screen and (max-width: $min-width-tablet) {
        	width: auto;
        }
        @media screen and (max-width: $max-width-mobile) {
            width: 100%;
            margin: 0 auto;
        }
        .wk-modal-body {
            @media screen and (max-width: $max-width-mobile) {
                width: 100%;
                border-bottom: 1px solid #d3d3d3;
                padding-top: 0;
                > span {
                    display: block;
                    width: 100%;
                    font-size: 1.4rem;
                }
                &:before {
                    right: 2rem;
                    font-size: 4rem;
                    text-align: right;
                }
            }
            .cart-aggiungi-anagrafiche {
                background-color: transparent;
                border: none;
                @media screen and (max-width: $max-width-mobile) {
                    background-color: $white;
                    margin-top: 0;
                    padding: 2rem;
                }
                .wk-h3 {
                    font-weight: 400;
                    padding-left: 1rem;
                    margin-bottom: 1rem;
                    @media screen and (max-width: $max-width-mobile) {
                        font-size: 1.2rem;
                        padding: 0 1rem;;
                    }
                }
                .cart-pacchetto-aggiunto-nome {
                    padding-left: 1rem;
                    font-size: 2rem;
                    line-height: 1.5;
                }
                .cart-piu-anagrafiche {
                    .cart-piu-anagrafiche-numero {
                        @media screen and (max-width: $max-width-tablet) {
                            width: 15%;
                        }
                    }
                    .cart-piu-anagrafiche-prezzo {
                        @media screen and (min-width: $min-width-tablet) {
                            font-size: 1rem;
                            > span {
                                font-size: 2rem;
                            }
                            button,
                            a {
                                @media screen and (max-width: $max-width-mobile) {
                                    margin-left: 0;
                                }
                                &:hover {
                                    background-color: #a4cd58;
                                }
                            }
                        }
                    }
                }
            }
            .cart-totale {
                margin-top: 0;
                .cart-totale-anagrafiche {
                    width: 50%;
                    .cart-totale-anagrafiche-dettaglio {
                        padding-bottom: 0;
                        > span {
                            @media screen and (max-width: $max-width-tablet) {
                                display: inline-block;
                                float: none;
                            }
                        }
                        .icon-action {
                            float: left;
							clear: both;
                            margin-top: 0;
                            @media screen and (max-width: $max-width-tablet) {
                                margin-top: 0.5rem;
                            }
                        }
                    }
                }
                .cart-totale-prezzo {
                    width: 50%;
                    float: left;
                    display: block;
                    text-transform: uppercase;
                    font-weight: 500;
                    text-align: center;
                	margin-top: 1rem;
                    > span {
                        font-size: 0.7rem;
                        color: $textColor;
                        margin: 0.8rem auto;
                        font-family: $font-stack-serif;
                        text-transform: none;
                    }
                    > span:first-of-type {
                        display: block;
                        width: 100%;
                        font-size: 2rem;
                        color: $textColor;
                        font-weight: 400;
                    }
                    > p {
                        margin-top: 0.5rem;
                        text-transform: none;
                        font-weight: 400;
                        font-size: 0.9rem;
                    	padding: 0 1rem;
                        > span {
                            font-size: 1.1rem;
                        }
                    }
                }
                > div:last-child {
                    width: 100%;
                    padding: 1rem;
                    display: inline-block;
                    border-top: 1px solid #d3d3d3;
                    margin-top: 1rem;
                    > a {
                        display: block;
                        text-align: center;
                        &:first-child {
                            width: 40%;
                            margin: 1rem auto;
                        }
                        &:last-child {
                            width: 100%;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .cart-totale-mobile {
                .cart-totale-mobile-dettagli {
                    > div {
                        .cart-totale-mobile-dettagli-row {
                            [class*='icon-genya-'] {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.trial-modal,
.request-modal {
    width: 100%;
    @media screen and (max-width: $max-width-tablet) {
        width: 100%;
        padding: 0 2rem;
    }
    @media screen and (max-width: $max-width-mobile) {
        width: 100%;
        padding: 0;
        top: 2rem;
    }
    .wk-modal-content {
        max-width: 32rem;
        .wk-modal-close {
            line-height: normal;
        }
        .wk-modal-body {
        	padding: 1rem;
            display: inline-block;
            .icon-genya-info-and-request,
            .icon-genya-check {
                font-size: 2.2rem;
                width: 100%;
                display: block;
                margin-bottom: 1rem;
            }
            p {
                padding: 1rem 2rem;
                font-size: 1.125rem;
                line-height: 1.5;
                //float: right;
                &.buttons {
                    text-align: center;
                }
                > a {
                    text-align: right;

                    > span {
                        padding: 1.4rem;
                    }
                }
                > a[wk-button] {
                    min-width: 6rem;
                    text-align: center;
                    //padding: 1rem 2rem;
                }
            }
        }
    }
}

.condizioni-modal {
	z-index: 9999;
    .wk-modal-content {
        .wk-modal-title {
            padding-bottom: 0px;
	        line-height: 1.5;
        }
        .wk-modal-close {
            padding: 0px!important;
            > span {
                padding: 3px;
            }
        }
        .wk-modal-body {
            position: relative;

            .condizioni-generali-testo {
                display: inline-block;
                max-height: 300px;
                overflow-y: scroll;
                padding-right: 24px;
                > ol.lst-kix_list_1-0 {
                    &:not(:first-of-type) {
                        margin-top: 1.5rem;
                    }
                    .c1 {
                        font-size: 1.2rem;
                        font-weight: bold;
                       .c5 {
                           font-size: 1.2rem;
                       }
                    }
                }
                @media screen and (max-device-height: 480px) {
                    max-height: 240px;
                }
                @media screen and (max-width: $max-width-mobile) {
                    padding: 0;
                    width: 88%;
                }
            }
            .condizioni-generali-scroll-button {
                display: none;
                position: absolute;
                cursor: pointer;
                background-color: $wkGreen;
                font-size: 1.4rem;
                line-height: 1;
                z-index: 99999;
                border-radius: 99px;
                color: #fff;
                padding: 14px;
                bottom: -1rem;
                left: 47%;
                @media screen and (max-width: 600px) {
                    left: 45%;
                }
                @media screen and (max-width: 480px) {
                    left: 43%;
                }
                @media screen and (max-width: 360px) {
                    left: 40%;
                }
            }
        }
        .condizioni-generali-accettazione,
        .condizioni-generali-accettazione-2, {
            margin: 12px 0;
            font-size: 1rem;
            border: 1px solid #007ac3;
            padding: 8px;
            > span {
                margin-right: 2rem;
            }
            .wk-field-item {
                margin-bottom: 0;
            }
        }
        .condizioni-generali-accettazione-more {
            display: none;
        }
        .wk-modal-footer {
            background-color: $wkGray4;
            padding: 1rem;
            //margin-top: -1rem;
            .condizioni-generali-buttons {
                //display: none;
                a[wk-button] {
                    @media screen and (max-width: $max-width-mobile) {
                        display: inline-block;
                        width: 47%;
                        margin-bottom: 0;
                    }
                }
                a[wk-button="secondary"] {
                    background-color: transparent;
                    @media screen and (max-width: 600px) {
                        //margin-top: 2rem;
                    }
                }
            }
        }
    }
    /*::-webkit-scrollbar {
        width: 16px;
        height: 16px;
        border-bottom: 1px solid #eee;
        border-top: 1px solid #eee;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #c3c3c3;
        border: 8px solid $wkBlue;
    }
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    }*/
}

input[type=range][orient=vertical] {
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical;
	width: 20px;
    height: 80%;
    padding: 0 5px;
    position: absolute;
    right: 16px;
    &::-webkit-slider-thumb {
      height: 24px;
      width: 24px;
      background: #ffffff;
      cursor: pointer;
      -webkit-appearance: none;
    }
}

.cart-suite-modal {
    .wk-modal-content {
        width: 58rem;
        @media screen and (max-width: $max-width-tablet) {
            width: 100%;
        }
        @media screen and (max-width: $max-width-mobile) {
            width: 100%;
        }
        .wk-modal-body {
            .cart-pacchetto-aggiunto {
                margin-top: 0;
                @media screen and (max-width: $max-width-tablet) {
                    width: 100%;
                }
                @media screen and (max-width: $max-width-mobile) {
                    width: 100%;
                }
            }
            .cart-aggiungi-anagrafiche {
                @media screen and (max-width: $max-width-tablet) {
                    width: 100%;
                    margin-top: 2rem;
                }
            }
        }
    }
}

// Video tutorial modal
.tutorial-modal {
    .wk-modal-content {
        .wk-modal-body {
            @media screen and (max-width: $max-width-mobile) {
                padding: 0;
            }
        }
    }
    .video-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}