.wk-header {
    @media screen and (max-width: 320px) {
        width: 320px;
    }
    .wk-header-container {
        @media screen and (max-width: $min-width-tablet) {
            background-color: $navBlue;
            padding: 0;
            position: relative;
            top: 0;
            width: 100%;
            z-index: 9999;
        }
        > a {
            @media screen and (max-width: $min-width-tablet) {
    	       margin: .78125rem .5rem .8125rem 1rem;
            }
            > img {
                &.wk-logo-small {
                    display: none;
                    @media screen and (max-width: $min-width-tablet) {
                        display: block;
                        width: 195px;
                    }
                }
                &.wk-logo-large {
                    display: block;
                    width: 200px;
                    @media screen and (max-width: $min-width-tablet) {
                        display: none;
                    }
                }
            }
        }
        ul.wk-nav {
            @media screen and (max-width: $min-width-tablet) {
                display: none;
                background-color: #474747;
                color: #fff;
                float: left;
                width: 100%;
            }
            [class*="icon-genya-"] {
                font-size: 1.2rem;
                line-height: 1.2rem;
                vertical-align: middle;
            }
            li {
                position: relative;
                font-size: 1.063rem; // 17px
                font-weight: 300;
                &:not(:last-of-type) {
                    border-right: 1px solid #d3d3d3;
                }
                .cart-notification {
                    position: absolute;
                    top: 7px;
                    left: 28px;
                }
                > a {
                    @media screen and (max-width: $min-width-md) {
                        padding: .625rem .5rem;
                        font-size: 0.8rem;
                    }
                }
            }
        }
        .wk-more-menu {
            display: none;
            @media screen and (max-width: $min-width-tablet) {
                display: block;
            }
            > li {
                position: relative;
                float: left;
                .cart-notification {
                    position: absolute;
                    top: 8px;
                    right: 16px;
                    border: 1.5px solid $navBlue;
                    @media screen and (max-width: $min-width-tablet) {
                        font-size: 0.6rem;
                    }
                }
            }
        }
    }
}

.wk-navbar {
    display: block;
    @media screen and (max-width: $min-width-tablet) {
        display: none;
    }
    .wk-product-name {
        &:after {
            background-color: $wkBlue;
        }
    }
    ul.wk-nav {
        > li {
            > a {
                font-size: 1.0625rem;
                font-weight: 300;
            }
            &.navbar-external-link {
                > a {
                    background-color: #409cd2;
                    font-weight: normal;
                    [class*="icon-genya-"] {
                        line-height: 0;
                        vertical-align: sub;
                    	margin-right: 0.3rem;
                    }
                    &:hover {
                        background-color: lighten(#409cd2, 10%);
                    }
                }
            }
        }
        span.icon-genya-cart:before {
            @media screen and (min-width: 769px) {
                color: #757575!important;
            }
        }
    }
    .wk-product-name {
        font-size: 1.0625rem;
        line-height: 1.625rem;
    }
}

.mobile-navbar {
    display: none;
    position: absolute;
    width: 100%;
    background-color: transparent;
    height: 380px;
    height: 90vh;
    @media screen and (min-width: 600px) and (max-width: $min-width-tablet) {
        position: absolute;
        right: 0;
        height: 840px;
        height: 90vh;
        width: 50%;
    }
    @media screen and (max-width: 320px) {
        width: 100%;
        max-width: 320px;
    }
    .wk-navbar-container {
        max-width: 75rem;
        margin-left: auto;
        margin-right: auto;
        position: fixed;
        background-color: transparent;
        top: 57px;
        right: 0;
        bottom: 0;
        z-index: 9999;
        @media screen and (max-width: $max-width-tablet) {
            width: 50%;
        }
        @media screen and (max-width: $max-width-mobile) {
            width: 100%;
        }
    }
    ul.wk-nav {
        border-radius: 0;
        display: block;
        height: 100%;
        z-index: 1050;
        top: 0;
        margin: 0;
        position: absolute;
        width: 100%;
        background-color: transparent;
        border: none;
        padding-top: 0;
        overflow-y: auto;
        li {
            position: relative;
            width: inherit;
            &.wk-active {
                > a {
                    background-color: $wkBlue;
                }
            }
            a {
                color: #fff;
                clear: both;
                display: block;
                font-weight: normal;
                padding-left: 18px;
                white-space: normal;
                font-size: 20px;
                line-height: 2.4;
                background-color: $wkBlue;
                background-image: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            }
            span.submenu-icon {
                display: block;
                position: absolute;
                top: 1.2rem;
                z-index: 9999;
                right: 1rem;
                line-height: 2.4rem;
                color: $white;
                cursor: pointer;
            }
            .submenu-mobile {
                display: none;
                .nome-utente {
                    background: #005b92;
                    display: block;
                    color: #fff;
                    clear: both;
                    display: block;
                    font-weight: 300;
                    white-space: normal;
                    font-size: 20px;
                    line-height: 2.4;
                    padding: 0 1rem;
                }
            }
            ul {
                list-style-type: none;
            	padding: 0;
            	width: inherit;
            	overflow: hidden;
                li {
                    background-color: $navBlue;
                    a {
                        color: $white;
                        clear: both;
                    	display: block;
                    	font-weight: normal;
                    	white-space: normal;
                    	font-size: 20px;
                    	line-height: 2.4;
                    	background-color: transparent;
                    	background-image: none;
                    	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    }
                }
            }
            &.mobile-menu-separator {
                border-top: 2px solid $lightblue;
            }
            &.mobile-menu-item {
                .mobile-menu-icon {
                    padding-right: 0.3rem;
                	vertical-align: middle;
                }
            }
        }
    }
}

.hamburger-menu-container {
    cursor: pointer;
    .opener-mobile-menu {
        background-color: $wkRed;
        width: 57px;
        height: 57px;
        color: white!important;
        font-weight: bolder;
        @media screen and (max-width: 336px) {
            position: absolute;
	        right: 0;
        }
        span {
            &.wk-icon-menu,
            &.wk-icon-close-empty {
                &:before {
                    color: $white;
                    font-size: 38px;
                    margin-left: 0;
                    position: absolute;
                    top: 12px;
                    right: 2px;
                }
            }
        }
    }
}

.cart-notification {
    text-align: center;
    border-radius: 99px;
    background-color: #85bc20;
    width: 1rem;
    height: 1rem;
    display: block;
    color: $white;
    font-size: 0.625rem;
    line-height: 1.5;
    border: 1.5px solid $white;
}

.cart-menu-icon-container {
    @media screen and (max-width: $min-width-tablet) {
        span {
            font-size: 1.4rem;
        }
    }
    @media screen and (max-width: 336px) {
        position: absolute;
        right: 48px;
    }
}

/* Icona carrello mobile */
.wk-header .wk-more-menu>li [class*=' wk-icon-']::before, .wk-header .wk-more-menu>li .icon-genya-bag::before, .wk-header .wk-nav>li [class*=' wk-icon-']::before, .wk-header .wk-nav>li .icon-genya-bag::before {
    @media screen and (max-width: $min-width-tablet) {
        color: $white;
        font-size: 24px;
        font-size: 22px;
        line-height: 26px;
    }
}

.opener-menu-profilo,
.opener-menu-carrello {
    > a {
        border: 1px solid transparent;
    }
}

.opener-menu-profilo,
.opener-menu-carrello,
.wk-more-menu {
    position: relative;
    .nav-profile-menu,
    .nav-carrello-menu {
        display: none;
        position: absolute;
        top: 44px;
        right: 0;
        height: auto;
        z-index: 9998;
        color: $textColor;
        background-color: $white;
    	padding: 1.6rem 2.4rem;
        width: 280px;
        border: 1px solid $wkBlue;
        > p {
            white-space: normal;
            word-wrap: break-word;
            > span {
                color: $wkGray1;
            }
            > a {
                color: $wkGreen;
                text-decoration: underline;
            }
        }
        > a {
            width: 100%;
        }
    }
    .nav-profile-menu {
        > p {
            > span {
                display: block;
                width: 100%;
            }
        }
    }
    .wk-icon-angle-up {
        display: none;
    }
    .wk-icon-angle-down {
        display: inline;
    }
    &.open-menu {
        z-index: 9999;
        > a {
            border: 1px solid $wkBlue;
            border-bottom: 0px solid transparent;
            background: $white;
            position: relative;
            z-index: 99999;
        }
        .wk-icon-angle-up {
            display: inline;
        }
        .wk-icon-angle-down {
            display: none;
        }
    }
}

.dropdown-cart,
.dropdown-cart-mobile {
    display: none;
    position: absolute;
    top: 44px;
    right: 0;
    background-color: $white;
    padding: 2rem;
    z-index: 9998;
    width: 360px;
    color: $textColor;
    border: 1px solid #007ac3;
    @media screen and (max-width: $min-width-tablet) {
        top: 57px;
        right: 58px;
    }
    @media screen and (max-width: $max-width-mobile) {
        right: 0;
        top: 57px;
        width: 100%;
    }
    .wk-h4 {
        margin-top: 0;
        font-size: 1.2rem;
    }
    .dropdown-cart-row {
        font-weight: 300;
        width: 100%;
        border-top: 1px solid #d3d3d3;
        padding: 1rem 0;
        > span {
            float: right;
            text-align: right;
            font-weight: 400;
        }
        > p {
            width: 100%;
            font-weight: 300;
            font-size: 1rem;
        }
        .dropdown-cart-row-link {
            width: 70%;
            float: left;
        }
        .dropdown-cart-row-button {
            float: right;
        }

        &.dropdown-cart-row-summary  {
            font-weight: 500;
            display: inline-block;
            .cart-upselling > div,
            .cart-upgrade > div {
                &:first-child {
                    > span:first-of-type {
                        font-size: 1.063rem;
                        font-weight: 500;
                        float: left;
                        text-align: left;
                        max-width: 80%;
                    }
                    > span:nth-of-type(2) {
                        float: right;
                        text-align: right;
                        font-weight: 400;
                    }
                }
            }
            .cart-upselling > p,
            .cart-upgrade > p {
                float: left;
                font-weight: 300;
                font-size: 1rem;
				clear: both;
            }
            .dropdown-cart-actions {
                width: 100%;
	            display: inline-block;
                a {
                    font-weight: 400;
                }
                a:first-child {
                    display: inline-block;
                    margin-right: 1.6rem;
                    &:before {
                        content: '\0047';
                        font-family: 'genya icons';
                        text-decoration: none;
                        color: $lightblue;
                        padding-right: 5px;
                    }
                }
                a:last-child {
                    display: inline-block;
                    text-align: right;
                    &:before {
                        content: '\0046';
                        font-family: 'genya icons';
                        text-decoration: none;
                        color: $lightblue;
                        padding-right: 5px;
                    }
                }
            }
        }
        &.dropdown-cart-row-total {
            > span {
                font-weight: 500;
            }
        }
    }
}

.dropdown-cart-mobile {
    display: none;
    overflow-y: auto;
    max-height: calc(100vh - 50px);
    position: fixed;
    @media screen and (min-width: $max-width-tablet) {
        display: none!important;
    }
}
