@import url("https://fonts.googleapis.com/css?family=Merriweather:300i,400");
@font-face {
  font-family: 'Fira Sans';
  src: url("../fonts/firasans/FiraSans-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/firasans/FiraSans-Regular.eot") format("embedded-opentype"), url("../fonts/firasans/FiraSans-Regular.woff2") format("woff2"), url("../fonts/firasans/FiraSans-Regular.woff") format("woff"), url("../fonts/firasans/FiraSans-Regular.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: normal; }

@font-face {
  font-family: 'Franziska';
  font-weight: italic;
  src: url("../fonts/franziska/FranziskaPro-MediumItalic.otf") format("opentype"); }

@font-face {
  font-family: 'genya icons';
  src: url("../fonts/genya-icons/genya-icons.eot");
  src: url("../fonts/genya-icons/genya-icons.eot?#iefix") format("embedded-opentype"), url("../fonts/genya-icons/genya-icons.woff") format("woff"), url("../fonts/genya-icons/genya-icons.ttf") format("truetype"), url("../fonts/genya-icons/genya-icons.svg#genya-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class*='icon-genya-']:before {
  display: inline-block;
  font-family: 'genya icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 4px;
  margin-top: 2px;
  display: inline-block; }

.icon-genya-accounting:before {
  content: '\0041'; }

.icon-genya-bag:before {
  content: '\0042'; }

.icon-genya-balance:before {
  content: '\0043'; }

.icon-genya-cart:before {
  content: '\0044'; }

.icon-genya-check:before {
  content: '\0045'; }

.icon-genya-delete:before {
  content: '\0046'; }

.icon-genya-edit:before {
  content: '\0047'; }

.icon-genya-exclamation-point:before {
  content: '\0048'; }

.icon-genya-login:before {
  content: '\0049'; }

.icon-genya-info-and-request:before {
  content: '\004a'; }

.icon-genya-minus:before {
  content: '\004b'; }

.icon-genya-phone:before {
  content: '\004c'; }

.icon-genya-plus:before {
  content: '\004d'; }

.icon-genya-reserved-area:before {
  content: '\004e'; }

.icon-genya-statements:before {
  content: '\004f'; }

.icon-genya-support:before {
  content: '\0050'; }

.icon-genya-down-arrow:before {
  content: '\0051'; }

.icon-genya-tab:before {
  content: '\0052'; }

.icon-genya-404:before {
  content: '\0053'; }

.icon-genya-cloud-1:before {
  content: '\0054'; }

.icon-genya-cloud-2:before {
  content: '\0055'; }

.icon-genya-lock:before {
  content: '\0056'; }

.icon-genya-external-link:before {
  content: '\0057'; }

.icon-genya-play-square:before {
  content: '\0058'; }

@media screen and (max-width: 320px) {
  .wk-header {
    width: 320px; } }

@media screen and (max-width: 768px) {
  .wk-header .wk-header-container {
    background-color: #005b92;
    padding: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 9999; } }

@media screen and (max-width: 768px) {
  .wk-header .wk-header-container > a {
    margin: .78125rem .5rem .8125rem 1rem; } }

.wk-header .wk-header-container > a > img.wk-logo-small {
  display: none; }
  @media screen and (max-width: 768px) {
    .wk-header .wk-header-container > a > img.wk-logo-small {
      display: block;
      width: 195px; } }

.wk-header .wk-header-container > a > img.wk-logo-large {
  display: block;
  width: 200px; }
  @media screen and (max-width: 768px) {
    .wk-header .wk-header-container > a > img.wk-logo-large {
      display: none; } }

@media screen and (max-width: 768px) {
  .wk-header .wk-header-container ul.wk-nav {
    display: none;
    background-color: #474747;
    color: #fff;
    float: left;
    width: 100%; } }

.wk-header .wk-header-container ul.wk-nav [class*="icon-genya-"] {
  font-size: 1.2rem;
  line-height: 1.2rem;
  vertical-align: middle; }

.wk-header .wk-header-container ul.wk-nav li {
  position: relative;
  font-size: 1.063rem;
  font-weight: 300; }
  .wk-header .wk-header-container ul.wk-nav li:not(:last-of-type) {
    border-right: 1px solid #d3d3d3; }
  .wk-header .wk-header-container ul.wk-nav li .cart-notification {
    position: absolute;
    top: 7px;
    left: 28px; }
  @media screen and (max-width: 992px) {
    .wk-header .wk-header-container ul.wk-nav li > a {
      padding: .625rem .5rem;
      font-size: 0.8rem; } }

.wk-header .wk-header-container .wk-more-menu {
  display: none; }
  @media screen and (max-width: 768px) {
    .wk-header .wk-header-container .wk-more-menu {
      display: block; } }
  .wk-header .wk-header-container .wk-more-menu > li {
    position: relative;
    float: left; }
    .wk-header .wk-header-container .wk-more-menu > li .cart-notification {
      position: absolute;
      top: 8px;
      right: 16px;
      border: 1.5px solid #005b92; }
      @media screen and (max-width: 768px) {
        .wk-header .wk-header-container .wk-more-menu > li .cart-notification {
          font-size: 0.6rem; } }

.wk-navbar {
  display: block; }
  @media screen and (max-width: 768px) {
    .wk-navbar {
      display: none; } }
  .wk-navbar .wk-product-name:after {
    background-color: #007ac3; }
  .wk-navbar ul.wk-nav > li > a {
    font-size: 1.0625rem;
    font-weight: 300; }
  .wk-navbar ul.wk-nav > li.navbar-external-link > a {
    background-color: #409cd2;
    font-weight: normal; }
    .wk-navbar ul.wk-nav > li.navbar-external-link > a [class*="icon-genya-"] {
      line-height: 0;
      vertical-align: sub;
      margin-right: 0.3rem; }
    .wk-navbar ul.wk-nav > li.navbar-external-link > a:hover {
      background-color: #69b1dc; }
  @media screen and (min-width: 769px) {
    .wk-navbar ul.wk-nav span.icon-genya-cart:before {
      color: #757575 !important; } }
  .wk-navbar .wk-product-name {
    font-size: 1.0625rem;
    line-height: 1.625rem; }

.mobile-navbar {
  display: none;
  position: absolute;
  width: 100%;
  background-color: transparent;
  height: 380px;
  height: 90vh; }
  @media screen and (min-width: 600px) and (max-width: 768px) {
    .mobile-navbar {
      position: absolute;
      right: 0;
      height: 840px;
      height: 90vh;
      width: 50%; } }
  @media screen and (max-width: 320px) {
    .mobile-navbar {
      width: 100%;
      max-width: 320px; } }
  .mobile-navbar .wk-navbar-container {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    background-color: transparent;
    top: 57px;
    right: 0;
    bottom: 0;
    z-index: 9999; }
    @media screen and (max-width: 1024px) {
      .mobile-navbar .wk-navbar-container {
        width: 50%; } }
    @media screen and (max-width: 767px) {
      .mobile-navbar .wk-navbar-container {
        width: 100%; } }
  .mobile-navbar ul.wk-nav {
    border-radius: 0;
    display: block;
    height: 100%;
    z-index: 1050;
    top: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    background-color: transparent;
    border: none;
    padding-top: 0;
    overflow-y: auto; }
    .mobile-navbar ul.wk-nav li {
      position: relative;
      width: inherit; }
      .mobile-navbar ul.wk-nav li.wk-active > a {
        background-color: #007ac3; }
      .mobile-navbar ul.wk-nav li a {
        color: #fff;
        clear: both;
        display: block;
        font-weight: normal;
        padding-left: 18px;
        white-space: normal;
        font-size: 20px;
        line-height: 2.4;
        background-color: #007ac3;
        background-image: none;
        -webkit-tap-highlight-color: transparent; }
      .mobile-navbar ul.wk-nav li span.submenu-icon {
        display: block;
        position: absolute;
        top: 1.2rem;
        z-index: 9999;
        right: 1rem;
        line-height: 2.4rem;
        color: #fff;
        cursor: pointer; }
      .mobile-navbar ul.wk-nav li .submenu-mobile {
        display: none; }
        .mobile-navbar ul.wk-nav li .submenu-mobile .nome-utente {
          background: #005b92;
          display: block;
          color: #fff;
          clear: both;
          display: block;
          font-weight: 300;
          white-space: normal;
          font-size: 20px;
          line-height: 2.4;
          padding: 0 1rem; }
      .mobile-navbar ul.wk-nav li ul {
        list-style-type: none;
        padding: 0;
        width: inherit;
        overflow: hidden; }
        .mobile-navbar ul.wk-nav li ul li {
          background-color: #005b92; }
          .mobile-navbar ul.wk-nav li ul li a {
            color: #fff;
            clear: both;
            display: block;
            font-weight: normal;
            white-space: normal;
            font-size: 20px;
            line-height: 2.4;
            background-color: transparent;
            background-image: none;
            -webkit-tap-highlight-color: transparent; }
      .mobile-navbar ul.wk-nav li.mobile-menu-separator {
        border-top: 2px solid #008CBB; }
      .mobile-navbar ul.wk-nav li.mobile-menu-item .mobile-menu-icon {
        padding-right: 0.3rem;
        vertical-align: middle; }

.hamburger-menu-container {
  cursor: pointer; }
  .hamburger-menu-container .opener-mobile-menu {
    background-color: #e5202e;
    width: 57px;
    height: 57px;
    color: white !important;
    font-weight: bolder; }
    @media screen and (max-width: 336px) {
      .hamburger-menu-container .opener-mobile-menu {
        position: absolute;
        right: 0; } }
    .hamburger-menu-container .opener-mobile-menu span.wk-icon-menu:before, .hamburger-menu-container .opener-mobile-menu span.wk-icon-close-empty:before {
      color: #fff;
      font-size: 38px;
      margin-left: 0;
      position: absolute;
      top: 12px;
      right: 2px; }

.cart-notification {
  text-align: center;
  border-radius: 99px;
  background-color: #85bc20;
  width: 1rem;
  height: 1rem;
  display: block;
  color: #fff;
  font-size: 0.625rem;
  line-height: 1.5;
  border: 1.5px solid #fff; }

@media screen and (max-width: 768px) {
  .cart-menu-icon-container span {
    font-size: 1.4rem; } }

@media screen and (max-width: 336px) {
  .cart-menu-icon-container {
    position: absolute;
    right: 48px; } }

/* Icona carrello mobile */
@media screen and (max-width: 768px) {
  .wk-header .wk-more-menu > li [class*=' wk-icon-']::before, .wk-header .wk-more-menu > li .icon-genya-bag::before, .wk-header .wk-nav > li [class*=' wk-icon-']::before, .wk-header .wk-nav > li .icon-genya-bag::before {
    color: #fff;
    font-size: 24px;
    font-size: 22px;
    line-height: 26px; } }

.opener-menu-profilo > a,
.opener-menu-carrello > a {
  border: 1px solid transparent; }

.opener-menu-profilo,
.opener-menu-carrello,
.wk-more-menu {
  position: relative; }
  .opener-menu-profilo .nav-profile-menu,
  .opener-menu-profilo .nav-carrello-menu,
  .opener-menu-carrello .nav-profile-menu,
  .opener-menu-carrello .nav-carrello-menu,
  .wk-more-menu .nav-profile-menu,
  .wk-more-menu .nav-carrello-menu {
    display: none;
    position: absolute;
    top: 44px;
    right: 0;
    height: auto;
    z-index: 9998;
    color: #474747;
    background-color: #fff;
    padding: 1.6rem 2.4rem;
    width: 280px;
    border: 1px solid #007ac3; }
    .opener-menu-profilo .nav-profile-menu > p,
    .opener-menu-profilo .nav-carrello-menu > p,
    .opener-menu-carrello .nav-profile-menu > p,
    .opener-menu-carrello .nav-carrello-menu > p,
    .wk-more-menu .nav-profile-menu > p,
    .wk-more-menu .nav-carrello-menu > p {
      white-space: normal;
      word-wrap: break-word; }
      .opener-menu-profilo .nav-profile-menu > p > span,
      .opener-menu-profilo .nav-carrello-menu > p > span,
      .opener-menu-carrello .nav-profile-menu > p > span,
      .opener-menu-carrello .nav-carrello-menu > p > span,
      .wk-more-menu .nav-profile-menu > p > span,
      .wk-more-menu .nav-carrello-menu > p > span {
        color: #757575; }
      .opener-menu-profilo .nav-profile-menu > p > a,
      .opener-menu-profilo .nav-carrello-menu > p > a,
      .opener-menu-carrello .nav-profile-menu > p > a,
      .opener-menu-carrello .nav-carrello-menu > p > a,
      .wk-more-menu .nav-profile-menu > p > a,
      .wk-more-menu .nav-carrello-menu > p > a {
        color: #85bc20;
        text-decoration: underline; }
    .opener-menu-profilo .nav-profile-menu > a,
    .opener-menu-profilo .nav-carrello-menu > a,
    .opener-menu-carrello .nav-profile-menu > a,
    .opener-menu-carrello .nav-carrello-menu > a,
    .wk-more-menu .nav-profile-menu > a,
    .wk-more-menu .nav-carrello-menu > a {
      width: 100%; }
  .opener-menu-profilo .nav-profile-menu > p > span,
  .opener-menu-carrello .nav-profile-menu > p > span,
  .wk-more-menu .nav-profile-menu > p > span {
    display: block;
    width: 100%; }
  .opener-menu-profilo .wk-icon-angle-up,
  .opener-menu-carrello .wk-icon-angle-up,
  .wk-more-menu .wk-icon-angle-up {
    display: none; }
  .opener-menu-profilo .wk-icon-angle-down,
  .opener-menu-carrello .wk-icon-angle-down,
  .wk-more-menu .wk-icon-angle-down {
    display: inline; }
  .opener-menu-profilo.open-menu,
  .opener-menu-carrello.open-menu,
  .wk-more-menu.open-menu {
    z-index: 9999; }
    .opener-menu-profilo.open-menu > a,
    .opener-menu-carrello.open-menu > a,
    .wk-more-menu.open-menu > a {
      border: 1px solid #007ac3;
      border-bottom: 0px solid transparent;
      background: #fff;
      position: relative;
      z-index: 99999; }
    .opener-menu-profilo.open-menu .wk-icon-angle-up,
    .opener-menu-carrello.open-menu .wk-icon-angle-up,
    .wk-more-menu.open-menu .wk-icon-angle-up {
      display: inline; }
    .opener-menu-profilo.open-menu .wk-icon-angle-down,
    .opener-menu-carrello.open-menu .wk-icon-angle-down,
    .wk-more-menu.open-menu .wk-icon-angle-down {
      display: none; }

.dropdown-cart,
.dropdown-cart-mobile {
  display: none;
  position: absolute;
  top: 44px;
  right: 0;
  background-color: #fff;
  padding: 2rem;
  z-index: 9998;
  width: 360px;
  color: #474747;
  border: 1px solid #007ac3; }
  @media screen and (max-width: 768px) {
    .dropdown-cart,
    .dropdown-cart-mobile {
      top: 57px;
      right: 58px; } }
  @media screen and (max-width: 767px) {
    .dropdown-cart,
    .dropdown-cart-mobile {
      right: 0;
      top: 57px;
      width: 100%; } }
  .dropdown-cart .wk-h4,
  .dropdown-cart-mobile .wk-h4 {
    margin-top: 0;
    font-size: 1.2rem; }
  .dropdown-cart .dropdown-cart-row,
  .dropdown-cart-mobile .dropdown-cart-row {
    font-weight: 300;
    width: 100%;
    border-top: 1px solid #d3d3d3;
    padding: 1rem 0; }
    .dropdown-cart .dropdown-cart-row > span,
    .dropdown-cart-mobile .dropdown-cart-row > span {
      float: right;
      text-align: right;
      font-weight: 400; }
    .dropdown-cart .dropdown-cart-row > p,
    .dropdown-cart-mobile .dropdown-cart-row > p {
      width: 100%;
      font-weight: 300;
      font-size: 1rem; }
    .dropdown-cart .dropdown-cart-row .dropdown-cart-row-link,
    .dropdown-cart-mobile .dropdown-cart-row .dropdown-cart-row-link {
      width: 70%;
      float: left; }
    .dropdown-cart .dropdown-cart-row .dropdown-cart-row-button,
    .dropdown-cart-mobile .dropdown-cart-row .dropdown-cart-row-button {
      float: right; }
    .dropdown-cart .dropdown-cart-row.dropdown-cart-row-summary,
    .dropdown-cart-mobile .dropdown-cart-row.dropdown-cart-row-summary {
      font-weight: 500;
      display: inline-block; }
      .dropdown-cart .dropdown-cart-row.dropdown-cart-row-summary .cart-upselling > div:first-child > span:first-of-type,
      .dropdown-cart .dropdown-cart-row.dropdown-cart-row-summary .cart-upgrade > div:first-child > span:first-of-type,
      .dropdown-cart-mobile .dropdown-cart-row.dropdown-cart-row-summary .cart-upselling > div:first-child > span:first-of-type,
      .dropdown-cart-mobile .dropdown-cart-row.dropdown-cart-row-summary .cart-upgrade > div:first-child > span:first-of-type {
        font-size: 1.063rem;
        font-weight: 500;
        float: left;
        text-align: left;
        max-width: 80%; }
      .dropdown-cart .dropdown-cart-row.dropdown-cart-row-summary .cart-upselling > div:first-child > span:nth-of-type(2),
      .dropdown-cart .dropdown-cart-row.dropdown-cart-row-summary .cart-upgrade > div:first-child > span:nth-of-type(2),
      .dropdown-cart-mobile .dropdown-cart-row.dropdown-cart-row-summary .cart-upselling > div:first-child > span:nth-of-type(2),
      .dropdown-cart-mobile .dropdown-cart-row.dropdown-cart-row-summary .cart-upgrade > div:first-child > span:nth-of-type(2) {
        float: right;
        text-align: right;
        font-weight: 400; }
      .dropdown-cart .dropdown-cart-row.dropdown-cart-row-summary .cart-upselling > p,
      .dropdown-cart .dropdown-cart-row.dropdown-cart-row-summary .cart-upgrade > p,
      .dropdown-cart-mobile .dropdown-cart-row.dropdown-cart-row-summary .cart-upselling > p,
      .dropdown-cart-mobile .dropdown-cart-row.dropdown-cart-row-summary .cart-upgrade > p {
        float: left;
        font-weight: 300;
        font-size: 1rem;
        clear: both; }
      .dropdown-cart .dropdown-cart-row.dropdown-cart-row-summary .dropdown-cart-actions,
      .dropdown-cart-mobile .dropdown-cart-row.dropdown-cart-row-summary .dropdown-cart-actions {
        width: 100%;
        display: inline-block; }
        .dropdown-cart .dropdown-cart-row.dropdown-cart-row-summary .dropdown-cart-actions a,
        .dropdown-cart-mobile .dropdown-cart-row.dropdown-cart-row-summary .dropdown-cart-actions a {
          font-weight: 400; }
        .dropdown-cart .dropdown-cart-row.dropdown-cart-row-summary .dropdown-cart-actions a:first-child,
        .dropdown-cart-mobile .dropdown-cart-row.dropdown-cart-row-summary .dropdown-cart-actions a:first-child {
          display: inline-block;
          margin-right: 1.6rem; }
          .dropdown-cart .dropdown-cart-row.dropdown-cart-row-summary .dropdown-cart-actions a:first-child:before,
          .dropdown-cart-mobile .dropdown-cart-row.dropdown-cart-row-summary .dropdown-cart-actions a:first-child:before {
            content: '\0047';
            font-family: 'genya icons';
            text-decoration: none;
            color: #008CBB;
            padding-right: 5px; }
        .dropdown-cart .dropdown-cart-row.dropdown-cart-row-summary .dropdown-cart-actions a:last-child,
        .dropdown-cart-mobile .dropdown-cart-row.dropdown-cart-row-summary .dropdown-cart-actions a:last-child {
          display: inline-block;
          text-align: right; }
          .dropdown-cart .dropdown-cart-row.dropdown-cart-row-summary .dropdown-cart-actions a:last-child:before,
          .dropdown-cart-mobile .dropdown-cart-row.dropdown-cart-row-summary .dropdown-cart-actions a:last-child:before {
            content: '\0046';
            font-family: 'genya icons';
            text-decoration: none;
            color: #008CBB;
            padding-right: 5px; }
    .dropdown-cart .dropdown-cart-row.dropdown-cart-row-total > span,
    .dropdown-cart-mobile .dropdown-cart-row.dropdown-cart-row-total > span {
      font-weight: 500; }

.dropdown-cart-mobile {
  display: none;
  overflow-y: auto;
  max-height: calc(100vh - 50px);
  position: fixed; }
  @media screen and (min-width: 1024px) {
    .dropdown-cart-mobile {
      display: none !important; } }

.form-avvisa-container {
  position: relative;
  max-width: 58%;
  margin: auto;
  border: 1px solid #009881;
  color: #009881;
  font-size: 1.3rem;
  padding: 1.5rem 1rem; }
  @media screen and (max-width: 1024px) {
    .form-avvisa-container {
      max-width: 80%; } }
  @media screen and (max-width: 767px) {
    .form-avvisa-container {
      max-width: 100%; } }
  .form-avvisa-container .wk-field-item {
    margin-top: 1rem;
    margin-bottom: 0; }
    @media screen and (max-width: 768px) {
      .form-avvisa-container .wk-field-item {
        margin-top: 2rem; } }
    @media screen and (max-width: 600px) {
      .form-avvisa-container .wk-field-item {
        margin-top: 0; } }
    .form-avvisa-container .wk-field-item .wk-field-header label {
      font-weight: normal;
      font-size: 1.1rem; }
    .form-avvisa-container .wk-field-item .wk-field-body input {
      margin: auto;
      display: inline-block;
      float: left;
      width: 85%; }
      @media screen and (max-width: 768px) {
        .form-avvisa-container .wk-field-item .wk-field-body input {
          width: 80%; } }
      @media screen and (max-width: 767px) {
        .form-avvisa-container .wk-field-item .wk-field-body input {
          display: block;
          float: left;
          width: 75%; } }
      @media screen and (max-width: 360px) {
        .form-avvisa-container .wk-field-item .wk-field-body input {
          width: 60%; } }
    .form-avvisa-container .wk-field-item .wk-field-body > a[wk-button] {
      display: inline-block;
      float: left;
      width: 15%;
      background-color: #85bc20;
      height: 3rem;
      font-size: 1rem;
      line-height: 1rem;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .form-avvisa-container .wk-field-item .wk-field-body > a[wk-button] {
          width: 20%; } }
      @media screen and (max-width: 767px) {
        .form-avvisa-container .wk-field-item .wk-field-body > a[wk-button] {
          width: auto; } }
      .form-avvisa-container .wk-field-item .wk-field-body > a[wk-button]:hover {
        background-color: #afd36c; }
    .form-avvisa-container .wk-field-item .wk-checkbox-field {
      font-size: 1.1rem; }
      .form-avvisa-container .wk-field-item .wk-checkbox-field a {
        text-decoration: underline; }
        .form-avvisa-container .wk-field-item .wk-checkbox-field a:visited {
          text-decoration: underline; }
        .form-avvisa-container .wk-field-item .wk-checkbox-field a:hover {
          text-decoration: underline; }
        .form-avvisa-container .wk-field-item .wk-checkbox-field a:active {
          text-decoration: underline; }

/* Form */
.form-container,
.fields-container {
  line-height: 1.5; }
  .form-container .form-question,
  .fields-container .form-question {
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 1.3rem; }
  .form-container .wk-field-item .wk-field-header,
  .fields-container .wk-field-item .wk-field-header {
    font-size: 1.1rem;
    font-weight: 400; }
    .form-container .wk-field-item .wk-field-header label,
    .fields-container .wk-field-item .wk-field-header label {
      font-size: 1.1rem;
      font-weight: 400; }
      .form-container .wk-field-item .wk-field-header label > span,
      .fields-container .wk-field-item .wk-field-header label > span {
        color: #007ac3; }
  .form-container .wk-field-item .wk-radio-field label,
  .fields-container .wk-field-item .wk-radio-field label {
    font-size: 1rem;
    font-weight: 400; }
  .form-container .wk-field-item .wk-checkbox-field label,
  .fields-container .wk-field-item .wk-checkbox-field label {
    font-weight: 400;
    font-size: 1rem; }
  .form-container .wk-field-item .wk-checkbox-field a,
  .fields-container .wk-field-item .wk-checkbox-field a {
    text-decoration: underline; }
    .form-container .wk-field-item .wk-checkbox-field a:visited,
    .fields-container .wk-field-item .wk-checkbox-field a:visited {
      text-decoration: underline; }
    .form-container .wk-field-item .wk-checkbox-field a:hover,
    .fields-container .wk-field-item .wk-checkbox-field a:hover {
      text-decoration: underline; }
    .form-container .wk-field-item .wk-checkbox-field a:active,
    .fields-container .wk-field-item .wk-checkbox-field a:active {
      text-decoration: underline; }
  .form-container .wk-field-item .field-info,
  .fields-container .wk-field-item .field-info {
    font-size: 1.1rem;
    font-weight: 400; }
  .form-container .wk-field-item.full-width-item,
  .fields-container .wk-field-item.full-width-item {
    width: 100% !important; }
    .form-container .wk-field-item.full-width-item .wk-field-header,
    .fields-container .wk-field-item.full-width-item .wk-field-header {
      margin-bottom: 0.8rem; }
  .form-container .wk-field-item.input-accettato input:not([type=radio]):not([type=checkbox]),
  .form-container .wk-field-item.input-accettato select:not(:disabled),
  .form-container .wk-field-item.input-accettato textarea:not(:disabled),
  .fields-container .wk-field-item.input-accettato input:not([type=radio]):not([type=checkbox]),
  .fields-container .wk-field-item.input-accettato select:not(:disabled),
  .fields-container .wk-field-item.input-accettato textarea:not(:disabled) {
    border-color: #85bc20;
    color: #85bc20; }
  .form-container .wk-field-item.input-accettato input[type="text"]:disabled,
  .fields-container .wk-field-item.input-accettato input[type="text"]:disabled {
    color: #474747; }
  .form-container .wk-field-item.horizontal-options .wk-radio-field,
  .form-container .wk-field-item.horizontal-options .wk-checkbox-field,
  .fields-container .wk-field-item.horizontal-options .wk-radio-field,
  .fields-container .wk-field-item.horizontal-options .wk-checkbox-field {
    display: inline-block;
    float: left;
    margin-right: 1rem; }
  .form-container .wk-field-item.horizontal-options.wk-field-has-error .wk-field-error,
  .fields-container .wk-field-item.horizontal-options.wk-field-has-error .wk-field-error {
    display: inline-block; }
  .form-container .wk-field-item.form-bottom.form-bottom-right-aligned,
  .fields-container .wk-field-item.form-bottom.form-bottom-right-aligned {
    width: 60%;
    float: right;
    padding: 1rem; }
    @media screen and (max-width: 768px) {
      .form-container .wk-field-item.form-bottom.form-bottom-right-aligned,
      .fields-container .wk-field-item.form-bottom.form-bottom-right-aligned {
        width: 100%;
        float: none;
        padding: 0; } }
    .form-container .wk-field-item.form-bottom.form-bottom-right-aligned a.link-accedi,
    .fields-container .wk-field-item.form-bottom.form-bottom-right-aligned a.link-accedi {
      display: inline-block;
      font-size: 1rem;
      line-height: 1.5;
      margin-top: 1rem; }
      @media screen and (max-width: 768px) {
        .form-container .wk-field-item.form-bottom.form-bottom-right-aligned a.link-accedi,
        .fields-container .wk-field-item.form-bottom.form-bottom-right-aligned a.link-accedi {
          margin-top: 0;
          margin-bottom: 1rem; } }
      @media screen and (max-width: 767px) {
        .form-container .wk-field-item.form-bottom.form-bottom-right-aligned a.link-accedi,
        .fields-container .wk-field-item.form-bottom.form-bottom-right-aligned a.link-accedi {
          width: 100%; } }
    .form-container .wk-field-item.form-bottom.form-bottom-right-aligned a[wk-button],
    .fields-container .wk-field-item.form-bottom.form-bottom-right-aligned a[wk-button] {
      display: inline-block;
      min-width: 200px;
      width: auto;
      margin-top: 0; }
    .form-container .wk-field-item.form-bottom.form-bottom-right-aligned a.link-accedi + a[wk-button],
    .fields-container .wk-field-item.form-bottom.form-bottom-right-aligned a.link-accedi + a[wk-button] {
      float: right; }
      @media screen and (max-width: 767px) {
        .form-container .wk-field-item.form-bottom.form-bottom-right-aligned a.link-accedi + a[wk-button],
        .fields-container .wk-field-item.form-bottom.form-bottom-right-aligned a.link-accedi + a[wk-button] {
          float: left; } }
  .form-container .wk-field-item.form-bottom > a[wk-button],
  .fields-container .wk-field-item.form-bottom > a[wk-button] {
    display: inline-block;
    min-width: 140px;
    width: auto;
    margin-top: 1.5rem; }
  .form-container.form-2-col,
  .fields-container.form-2-col {
    display: table;
    padding: 1rem 0;
    width: 100%; }
    @media screen and (max-width: 767px) {
      .form-container.form-2-col,
      .fields-container.form-2-col {
        display: inline-block; } }
    .form-container.form-2-col .wk-field-item,
    .fields-container.form-2-col .wk-field-item {
      width: 48%;
      float: left; }
      .form-container.form-2-col .wk-field-item.link-item,
      .fields-container.form-2-col .wk-field-item.link-item {
        padding: 3rem; }
        @media screen and (max-width: 768px) {
          .form-container.form-2-col .wk-field-item.link-item,
          .fields-container.form-2-col .wk-field-item.link-item {
            padding: 0; } }
        .form-container.form-2-col .wk-field-item.link-item a,
        .fields-container.form-2-col .wk-field-item.link-item a {
          text-decoration: underline; }
      .form-container.form-2-col .wk-field-item:not(:last-child),
      .fields-container.form-2-col .wk-field-item:not(:last-child) {
        margin-right: 2%; }
      .form-container.form-2-col .wk-field-item .wk-alert,
      .fields-container.form-2-col .wk-field-item .wk-alert {
        margin-right: 2%;
        font-size: 1.4rem; }
      @media screen and (max-width: 768px) {
        .form-container.form-2-col .wk-field-item,
        .fields-container.form-2-col .wk-field-item {
          width: 100%;
          margin: 0.5rem auto; } }
      .form-container.form-2-col .wk-field-item input:disabled,
      .fields-container.form-2-col .wk-field-item input:disabled {
        color: #000;
        font-weight: 500; }
  .form-container .link-accedi,
  .fields-container .link-accedi {
    text-decoration: underline; }

.wk-field-body.select-data .select-data-mese {
  float: left;
  width: 33%;
  margin-right: 2%; }

.wk-field-body.select-data .select-data-anno {
  float: left;
  width: 65%; }

.informativa {
  margin-bottom: 2rem;
  font-size: 1rem;
  line-height: 1.5; }

/* reCaptcha */
.g-recaptcha {
  -moz-transform: scale(0.85);
  -o-transform: scale(0.85);
  -ms-transform: scale(0.85);
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
  moz-transform-origin: left top;
  -o-transform-origin: left top;
  -ms-transform-origin: left top;
  -webkit-transform-origin: left top;
  transform-origin: left top; }

/* Cart */
.genya-modal .wk-modal-content {
  position: static;
  background-color: #f6f6f6;
  width: 48rem;
  margin: 2rem auto; }
  @media screen and (max-width: 992px) {
    .genya-modal .wk-modal-content {
      left: 46%;
      width: 46rem; } }
  @media screen and (max-width: 768px) {
    .genya-modal .wk-modal-content {
      position: relative;
      padding: 0;
      top: 0;
      left: 0;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      width: auto; } }
  @media screen and (max-width: 767px) {
    .genya-modal .wk-modal-content {
      top: 0;
      left: 0;
      width: auto; } }
  .genya-modal .wk-modal-content .wk-modal-header {
    min-height: auto; }
    .genya-modal .wk-modal-content .wk-modal-header button,
    .genya-modal .wk-modal-content .wk-modal-header a {
      background-color: transparent;
      z-index: 9999; }
    .genya-modal .wk-modal-content .wk-modal-header button.close-button,
    .genya-modal .wk-modal-content .wk-modal-header a.close-button {
      padding: 0;
      color: #fff;
      font-size: 2rem;
      position: absolute;
      top: 0;
      right: 0; }
    .genya-modal .wk-modal-content .wk-modal-header .wk-modal-title [class*='icon-genya-'] {
      vertical-align: middle;
      margin-right: 0.4rem; }
  .genya-modal .wk-modal-content .wk-modal-body {
    padding: 2rem;
    width: 100%; }
    @media screen and (max-width: 767px) {
      .genya-modal .wk-modal-content .wk-modal-body {
        padding: 2rem 0 0; } }
    .genya-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto {
      margin-top: 1rem;
      padding-left: 1rem; }
      @media screen and (max-width: 767px) {
        .genya-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto {
          width: 100%;
          margin-top: 0;
          padding-left: 0; } }

.cart-modal,
.anagrafiche-modal,
.cart-suite-modal {
  width: 100%; }
  @media screen and (max-width: 1024px) {
    .cart-modal,
    .anagrafiche-modal,
    .cart-suite-modal {
      width: 100%;
      padding: 2rem !important; } }
  @media screen and (max-width: 767px) {
    .cart-modal,
    .anagrafiche-modal,
    .cart-suite-modal {
      width: 100%;
      padding: 0 !important; } }
  @media screen and (max-width: 767px) {
    .cart-modal .wk-modal-content,
    .anagrafiche-modal .wk-modal-content,
    .cart-suite-modal .wk-modal-content {
      margin: 0 auto; } }
  .cart-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-feedback,
  .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-feedback,
  .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-feedback {
    position: relative;
    font-size: 1.6rem;
    margin-bottom: 2rem;
    padding-left: 2rem; }
    .cart-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-feedback:before,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-feedback:before,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-feedback:before {
      position: absolute;
      content: '\e828';
      font-family: "wk-icons";
      font-size: 3.5rem;
      color: #85bc20;
      top: -1.3rem;
      left: -1rem; }
    @media screen and (max-width: 767px) {
      .cart-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-feedback,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-feedback,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-feedback {
        width: 100%;
        padding: 1rem;
        border-bottom: 1px solid #d3d3d3; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-feedback > span,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-feedback > span,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-feedback > span {
          display: block;
          width: 100%;
          font-size: 1.4rem; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-feedback:before,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-feedback:before,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-feedback:before {
          right: 2rem;
          font-size: 4rem;
          text-align: right; } }
  .cart-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-nome,
  .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-nome,
  .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-nome {
    font-size: 2rem;
    margin-bottom: 1rem; }
    @media screen and (max-width: 767px) {
      .cart-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-nome,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-nome,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-nome {
        padding: 0 1rem; } }
    .cart-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-nome > span,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-nome > span,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-nome > span {
      display: block;
      width: 100%;
      font-size: 1rem;
      font-style: italic; }
  .cart-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli,
  .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli,
  .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli {
    font-size: 0.9rem; }
    @media screen and (max-width: 767px) {
      .cart-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli {
        padding: 0 1rem;
        margin-bottom: 2rem; } }
    .cart-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli > span,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli > span,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli > span {
      font-size: 2rem;
      font-family: "Franziska", serif;
      font-style: italic;
      font-weight: 700;
      margin-right: 4px; }
    .cart-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli ul,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli ul,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli ul {
      margin-top: 0.5rem;
      list-style-position: outside;
      list-style-type: none;
      padding-left: 0.5rem; }
      .cart-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli ul li,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli ul li,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli ul li {
        font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
        font-size: 0.9rem;
        font-style: normal;
        position: relative;
        padding-left: 2rem; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli ul li:before,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli ul li:before,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto-dettagli ul li:before {
          content: '\e828';
          font-family: "wk-icons";
          font-size: 1.6rem;
          color: #85bc20;
          font-weight: bold;
          position: absolute;
          left: 0;
          top: -0.5rem; }
  .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli,
  .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli,
  .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli {
    margin-top: 1.5rem; }
    .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .wk-h5,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .wk-h5,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .wk-h5 {
      font-size: 1.3rem; }
    .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli {
      width: 100%;
      padding: 0.65rem 0; }
      .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli span[wk-icon="add-on"],
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli span[wk-icon="add-on"],
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli span[wk-icon="add-on"] {
        font-size: 1.15rem;
        margin-top: 0.15rem; }
      .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .bind-descrizione-modulo,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .bind-descrizione-modulo,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .bind-descrizione-modulo {
        font-size: 1rem; }
      .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo {
        padding: 0; }
      @media screen and (max-width: 767px) {
        .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-moduli-descrizione,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-moduli-descrizione,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-moduli-descrizione {
          margin-bottom: 1rem; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-moduli-descrizione .wk-row div,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-moduli-descrizione .wk-row div,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-moduli-descrizione .wk-row div {
            float: left; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-moduli-descrizione .wk-row div:first-child,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-moduli-descrizione .wk-row div:first-child,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-moduli-descrizione .wk-row div:first-child {
            margin-right: 0.5rem; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-moduli-descrizione .wk-row div:nth-child(2),
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-moduli-descrizione .wk-row div:nth-child(2),
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-moduli-descrizione .wk-row div:nth-child(2) {
            width: 85%; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a[data-action='addToCart'],
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a[data-action='addToCart'],
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a[data-action='addToCart'] {
          margin-left: 0; } }
  .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche,
  .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli,
  .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche,
  .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli,
  .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche {
    position: relative;
    background-color: #fff;
    border: 1px solid #d3d3d3;
    padding: 1rem;
    min-height: 2rem; }
    @media screen and (max-width: 767px) {
      .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche {
        width: 100%;
        padding: 2rem 1rem;
        border: 0; } }
    .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .wk-h5, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .wk-h5,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .wk-h5,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .wk-h5,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .wk-h5,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .wk-h5 {
      font-size: 1.2rem; }
    .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli {
      position: relative;
      padding: 0.5rem 0;
      border-bottom: 1px solid #d3d3d3;
      width: 90%;
      margin: auto; }
      @media screen and (max-width: 1024px) {
        .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli {
          width: 100%; } }
      @media screen and (max-width: 767px) {
        .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli {
          width: 100%; } }
      .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche:last-of-type, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli:last-of-type, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche:last-of-type, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli:last-of-type,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche:last-of-type,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli:last-of-type,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche:last-of-type,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli:last-of-type,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche:last-of-type,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli:last-of-type,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche:last-of-type,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli:last-of-type {
        border-bottom: 0; }
      .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-numero, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-numero, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-numero, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-numero,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-numero,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-numero,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-numero,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-numero,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-numero,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-numero,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-numero,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-numero {
        display: inline-block;
        width: 25%;
        color: #85bc20;
        font-size: 0.9rem; }
        @media screen and (max-width: 767px) {
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-numero, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-numero, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-numero, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-numero,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-numero,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-numero,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-numero,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-numero,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-numero,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-numero,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-numero,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-numero {
            font-size: 1rem;
            width: 15%; } }
        @media screen and (max-width: 380px) {
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-numero, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-numero, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-numero, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-numero,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-numero,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-numero,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-numero,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-numero,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-numero,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-numero,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-numero,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-numero {
            font-size: 1rem;
            width: 30%; } }
        .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-numero > span, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-numero > span, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-numero > span, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-numero > span,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-numero > span,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-numero > span,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-numero > span,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-numero > span,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-numero > span,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-numero > span,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-numero > span,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-numero > span {
          font-family: "Franziska", serif;
          font-size: 2rem;
          font-style: italic;
          font-weight: 700; }
      .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo {
        display: inline-block;
        position: relative;
        padding: 1rem 0;
        font-size: 1rem;
        width: 75%;
        text-align: center;
        float: right; }
        @media screen and (max-width: 1024px) {
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo {
            width: 70%;
            padding: 0; } }
        @media screen and (max-width: 767px) {
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo {
            width: 70%;
            padding: 0; } }
        @media screen and (max-width: 380px) {
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo {
            width: 70%; } }
        .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo > span, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo > span, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo > span, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo > span,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo > span,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo > span,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo > span,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo > span,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo > span,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo > span,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo > span,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo > span {
          display: inline-block;
          font-size: 1.6rem; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
        .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
        .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
        .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
        .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a {
          position: relative;
          float: right;
          padding: 0 10px;
          margin-left: 1rem;
          font-size: 1.6rem;
          width: 36px;
          height: 36px;
          height: 36px;
          line-height: 2.2rem; }
          @media screen and (max-width: 1024px) {
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a {
              margin-left: 0.5rem; } }
          @media screen and (max-width: 767px) {
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a {
              margin-left: 4rem; } }
          @media screen and (max-width: 360px) {
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a {
              margin-left: 0.5rem; } }
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button > span:before,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a > span:before, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button > span:before,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a > span:before, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button > span:before,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a > span:before, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button > span:before,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a > span:before,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button > span:before,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a > span:before,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button > span:before,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a > span:before,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button > span:before,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a > span:before,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button > span:before,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a > span:before,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button > span:before,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a > span:before,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button > span:before,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a > span:before,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button > span:before,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a > span:before,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button > span:before,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a > span:before {
            font-size: 1.1rem;
            line-height: 1.5;
            padding: 0; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:visited,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:visited, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:visited,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:visited, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:visited,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:visited, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:visited,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:visited,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:visited,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:visited,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:visited,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:visited,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:visited,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:visited,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:visited,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:visited,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:visited,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:visited,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:visited,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:visited,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:visited,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:visited,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:visited,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:visited {
            text-decoration: none; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:hover,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:hover, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:hover,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:hover, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:hover,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:hover, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:hover,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:hover,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:hover,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:hover,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:hover,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:hover,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:hover,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:hover,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:hover,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:hover,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:hover,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:hover,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:hover,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:hover,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:hover,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:hover,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:hover,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:hover {
            text-decoration: none; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:active,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:active, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:active,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:active, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:active,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:active, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:active,
          .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:active,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:active,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:active,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:active,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:active,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:active,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:active,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:active,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:active,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:active,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:active,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:active,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:active,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:active,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:active,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo button:active,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-moduli .cart-piu-anagrafiche-prezzo a:active {
            text-decoration: none; }
    .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row {
      border-bottom: 1px solid #d3d3d3; }
      .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row:first-of-type, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row:first-of-type,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row:first-of-type,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row:first-of-type,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row:first-of-type,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row:first-of-type {
        margin-top: 2rem; }
      @media screen and (max-width: 767px) {
        .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row {
          margin-right: 0.4rem; } }
      .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo {
        margin: 1rem 0;
        padding: 0 0.5rem; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo > span:first-child, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo > span:first-child,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo > span:first-child,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo > span:first-child,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo > span:first-child,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo > span:first-child {
          display: inline-block;
          font-size: 1.4rem;
          color: #474747;
          font-weight: 700;
          font-family: "Merriweather", serif;
          font-style: italic; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo .aggiungi-anagrafiche-small-button, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo .aggiungi-anagrafiche-small-button,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo .aggiungi-anagrafiche-small-button,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo .aggiungi-anagrafiche-small-button,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo .aggiungi-anagrafiche-small-button,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo .aggiungi-anagrafiche-small-button {
          padding: 0.25rem 0.75rem;
          font-size: 0.8rem;
          float: right;
          margin-right: 0.75rem; }
          @media screen and (max-width: 767px) {
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo .aggiungi-anagrafiche-small-button, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo .aggiungi-anagrafiche-small-button,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo .aggiungi-anagrafiche-small-button,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo .aggiungi-anagrafiche-small-button,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo .aggiungi-anagrafiche-small-button,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-row .cart-piu-anagrafiche-modulo .aggiungi-anagrafiche-small-button {
              width: auto;
              margin-right: 0; } }
    .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-opzioni, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-opzioni,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-opzioni,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-opzioni,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-opzioni,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-opzioni {
      display: none;
      margin-bottom: 1rem; }
      .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche {
        border: 0;
        min-height: 2rem; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche .aggiungi-anagrafiche-small-button-close, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche .aggiungi-anagrafiche-small-button-close,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche .aggiungi-anagrafiche-small-button-close,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche .aggiungi-anagrafiche-small-button-close,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche .aggiungi-anagrafiche-small-button-close,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche .aggiungi-anagrafiche-small-button-close {
          padding: 0.25rem 0.75rem;
          font-size: 0.8rem;
          text-align: right;
          float: right;
          background: #a3a3a3; }
          @media screen and (max-width: 767px) {
            .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche .aggiungi-anagrafiche-small-button-close, .cart-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche .aggiungi-anagrafiche-small-button-close,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche .aggiungi-anagrafiche-small-button-close,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche .aggiungi-anagrafiche-small-button-close,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-moduli .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche .aggiungi-anagrafiche-small-button-close,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche-modulo-opzioni .cart-piu-anagrafiche .aggiungi-anagrafiche-small-button-close {
              width: 90px;
              text-align: center; } }
  .cart-modal .wk-modal-content .wk-modal-body .cart-piu-anagrafiche-modulo-elimina-aggiuntive,
  .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-piu-anagrafiche-modulo-elimina-aggiuntive,
  .cart-suite-modal .wk-modal-content .wk-modal-body .cart-piu-anagrafiche-modulo-elimina-aggiuntive {
    display: block;
    text-align: right;
    margin: 1rem 0 0;
    background-color: white;
    text-decoration: underline; }
  .cart-modal .wk-modal-content .wk-modal-body .cart-totale,
  .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale,
  .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale {
    position: relative;
    background-color: #fff;
    border: 1px solid #d3d3d3;
    padding: 1rem;
    margin-top: 2rem;
    display: block; }
    @media screen and (max-width: 767px) {
      .cart-modal .wk-modal-content .wk-modal-body .cart-totale,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale {
        display: none; } }
    .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche {
      width: 70%;
      float: left;
      font-weight: 500;
      text-align: center;
      padding: 1rem 1rem 0 1rem; }
      @media screen and (max-width: 768px) {
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche {
          width: 50%; } }
      .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche > span,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche > span,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche > span {
        width: 100%;
        display: block;
        font-size: 2rem;
        padding-bottom: 0.8rem;
        font-style: italic;
        font-family: "Franziska", serif;
        font-weight: 700; }
      .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio, .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio {
        clear: both;
        border-top: 1px solid #d3d3d3;
        padding: 0.3rem 0;
        color: #757575;
        font-weight: 400;
        text-align: left;
        line-height: 1.6rem; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .bind-addon-type, .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio .bind-addon-type,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .bind-addon-type,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio .bind-addon-type,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .bind-addon-type,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio .bind-addon-type {
          float: left; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio > span:first-child, .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio > span:first-child,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio > span:first-child,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio > span:first-child,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio > span:first-child,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio > span:first-child {
          margin-right: 0.75rem;
          font-size: 1.4rem;
          width: 18%;
          display: inline-block;
          overflow: visible; }
          @media screen and (max-width: 1024px) {
            .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio > span:first-child, .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio > span:first-child,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio > span:first-child,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio > span:first-child,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio > span:first-child,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio > span:first-child {
              display: inline-block;
              float: left; } }
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio > span:nth-child(2), .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio > span:nth-child(2),
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio > span:nth-child(2),
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio > span:nth-child(2),
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio > span:nth-child(2),
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio > span:nth-child(2) {
          font-weight: 400;
          text-align: left;
          line-height: 1.6rem;
          vertical-align: text-bottom; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio button, .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio button,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio button,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio button,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio button,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio button {
          background-color: #85bc20;
          padding: 0px;
          margin-left: 1rem;
          font-size: 1.4rem; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio button > span:before, .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio button > span:before,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio button > span:before,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio button > span:before,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio button > span:before,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio button > span:before {
            font-weight: 700; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .icon-action, .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio .icon-action,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .icon-action,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio .icon-action,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .icon-action,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio .icon-action {
          display: inline-block;
          float: right;
          line-height: 2rem; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .bind-totale-anagrafiche-aggiuntivi, .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio .bind-totale-anagrafiche-aggiuntivi,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .bind-totale-anagrafiche-aggiuntivi,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio .bind-totale-anagrafiche-aggiuntivi,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .bind-totale-anagrafiche-aggiuntivi,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio .bind-totale-anagrafiche-aggiuntivi {
          float: left;
          min-height: 2.8rem; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .bind-addon-type, .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio .bind-addon-type,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .bind-addon-type,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio .bind-addon-type,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .bind-addon-type,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio .bind-addon-type {
          line-height: 0.8rem; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .bind-pacchetto-aggiunto-label-anagrafiche, .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio .bind-pacchetto-aggiunto-label-anagrafiche,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .bind-pacchetto-aggiunto-label-anagrafiche,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio .bind-pacchetto-aggiunto-label-anagrafiche,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .bind-pacchetto-aggiunto-label-anagrafiche,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio .bind-pacchetto-aggiunto-label-anagrafiche {
          min-height: 2.8rem; }
      .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-dettaglio {
        border-top: none; }
      .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-header,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-header,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-moduli-header {
        font-weight: 500; }
    .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo {
      width: 30%;
      float: left;
      display: block;
      border-left: 1px solid #d3d3d3; }
      @media screen and (max-width: 768px) {
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo {
          width: 50%; } }
      .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div {
        width: 100%;
        float: left;
        padding: 1rem 0.5rem; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:first-child,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:first-child,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:first-child {
          font-weight: 500;
          text-align: center; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:first-child > span,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:first-child > span,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:first-child > span {
            font-size: 0.7rem;
            color: #757575; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:first-child > span:first-of-type,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:first-child > span:first-of-type,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:first-child > span:first-of-type {
            display: block;
            width: 100%;
            font-size: 2rem;
            color: #474747;
            font-weight: 400;
            font-family: "Merriweather", serif; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:first-child > p,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:first-child > p,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:first-child > p {
            margin-top: 0.5rem;
            text-transform: none;
            font-weight: 400;
            font-size: 0.9rem;
            padding: 0 1rem; }
            .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:first-child > p > span,
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:first-child > p > span,
            .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:first-child > p > span {
              font-size: 1.1rem; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:last-child,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:last-child,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:last-child {
          padding: 1rem 0.5rem;
          text-align: center; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:last-child button,
          .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:last-child a:first-of-type,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:last-child button,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:last-child a:first-of-type,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:last-child button,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:last-child a:first-of-type {
            margin-bottom: 2rem;
            padding: 1.4rem 2rem; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:last-child a:last-of-type,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:last-child a:last-of-type,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > div:last-child a:last-of-type {
            text-decoration: underline;
            display: block;
            width: 100%; }
  .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile,
  .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile,
  .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile {
    display: none;
    background-color: #007ac3;
    color: #fff;
    font-size: 0.9rem;
    padding: 0.5rem 0.5rem 0 0.5rem; }
    @media screen and (max-width: 767px) {
      .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile {
        display: block; } }
    @media screen and (max-width: 380px) {
      .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile {
        font-size: 0.8rem; } }
    .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo {
      width: 100%; }
      .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo div,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo div,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo div {
        float: left;
        padding: 0.5rem; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo div:first-child,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo div:first-child,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo div:first-child {
          width: 60%; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo div:first-child > span,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo div:first-child > span,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo div:first-child > span {
            font-size: 1.4rem; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo div:last-child,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo div:last-child,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo div:last-child {
          width: 40%;
          padding-bottom: 0; }
      .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo button,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo button,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo button {
        background-color: #85bc20; }
      .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo a.cart-totale-mobile-opener,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo a.cart-totale-mobile-opener,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-riepilogo a.cart-totale-mobile-opener {
        display: block;
        width: 100%;
        color: #fff;
        text-decoration: underline;
        margin-top: 1rem;
        margin-bottom: 0.5rem; }
    .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli,
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli {
      border-top: 1px solid #fff;
      width: 100%;
      padding: 0.5rem; }
      .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div,
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div,
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div {
        float: left;
        padding: 0 0.5rem;
        margin-top: 0.5rem;
        font-size: 0.8rem; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div:first-child,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div:first-child,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div:first-child {
          padding-left: 0;
          width: 60%;
          padding-top: 0;
          border-right: 1px solid #fff; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div:last-child,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div:last-child,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div:last-child {
          padding-right: 0;
          width: 40%;
          font-size: 0.9rem; }
        .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row,
        .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row {
          font-size: 0.9rem;
          line-height: 1.1rem;
          max-height: 3.3rem;
          overflow-y: hidden; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row [class*="icon-genya-"],
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row [class*="icon-genya-"],
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row [class*="icon-genya-"] {
            color: #fff; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row .icon-action,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row .icon-action,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row .icon-action {
            display: inline-block;
            vertical-align: middle; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row > span:first-child,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row > span:first-child,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row > span:first-child {
            font-size: 1.4rem;
            margin-right: 1.3rem;
            width: 17%;
            display: block;
            float: left;
            overflow: visible;
            height: 3rem; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row > span:nth-child(2),
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row > span:nth-child(2),
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row > span:nth-child(2) {
            font-size: 0.9rem;
            line-height: 1.1rem;
            max-height: 3.3rem;
            overflow-y: hidden; }
          .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row button,
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row button,
          .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row button {
            margin-bottom: 1rem;
            background-color: #85bc20;
            padding: 0px;
            margin-left: 1rem;
            font-size: 1.4rem;
            width: 36px;
            height: 36px; }
            @media screen and (max-width: 767px) {
              .cart-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row button,
              .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row button,
              .cart-suite-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row button {
                margin: 0; } }

.anagrafiche-modal .wk-modal-content {
  width: 52rem; }
  @media screen and (max-width: 1024px) {
    .anagrafiche-modal .wk-modal-content {
      width: 46rem; } }
  @media screen and (max-width: 768px) {
    .anagrafiche-modal .wk-modal-content {
      width: auto; } }
  @media screen and (max-width: 767px) {
    .anagrafiche-modal .wk-modal-content {
      width: 100%;
      margin: 0 auto; } }
  @media screen and (max-width: 767px) {
    .anagrafiche-modal .wk-modal-content .wk-modal-body {
      width: 100%;
      border-bottom: 1px solid #d3d3d3;
      padding-top: 0; }
      .anagrafiche-modal .wk-modal-content .wk-modal-body > span {
        display: block;
        width: 100%;
        font-size: 1.4rem; }
      .anagrafiche-modal .wk-modal-content .wk-modal-body:before {
        right: 2rem;
        font-size: 4rem;
        text-align: right; } }
  .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche {
    background-color: transparent;
    border: none; }
    @media screen and (max-width: 767px) {
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche {
        background-color: #fff;
        margin-top: 0;
        padding: 2rem; } }
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .wk-h3 {
      font-weight: 400;
      padding-left: 1rem;
      margin-bottom: 1rem; }
      @media screen and (max-width: 767px) {
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .wk-h3 {
          font-size: 1.2rem;
          padding: 0 1rem; } }
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-pacchetto-aggiunto-nome {
      padding-left: 1rem;
      font-size: 2rem;
      line-height: 1.5; }
    @media screen and (max-width: 1024px) {
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-numero {
        width: 15%; } }
    @media screen and (min-width: 768px) {
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo {
        font-size: 1rem; }
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo > span {
          font-size: 2rem; } }
  @media screen and (min-width: 768px) and (max-width: 767px) {
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button,
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a {
      margin-left: 0; } }
    @media screen and (min-width: 768px) {
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo button:hover,
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche .cart-piu-anagrafiche .cart-piu-anagrafiche-prezzo a:hover {
          background-color: #a4cd58; } }
  .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale {
    margin-top: 0; }
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche {
      width: 50%; }
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio {
        padding-bottom: 0; }
        @media screen and (max-width: 1024px) {
          .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio > span {
            display: inline-block;
            float: none; } }
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .icon-action {
          float: left;
          clear: both;
          margin-top: 0; }
          @media screen and (max-width: 1024px) {
            .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-anagrafiche .cart-totale-anagrafiche-dettaglio .icon-action {
              margin-top: 0.5rem; } }
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo {
      width: 50%;
      float: left;
      display: block;
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;
      margin-top: 1rem; }
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > span {
        font-size: 0.7rem;
        color: #474747;
        margin: 0.8rem auto;
        font-family: "Franziska", serif;
        text-transform: none; }
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > span:first-of-type {
        display: block;
        width: 100%;
        font-size: 2rem;
        color: #474747;
        font-weight: 400; }
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > p {
        margin-top: 0.5rem;
        text-transform: none;
        font-weight: 400;
        font-size: 0.9rem;
        padding: 0 1rem; }
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale .cart-totale-prezzo > p > span {
          font-size: 1.1rem; }
    .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale > div:last-child {
      width: 100%;
      padding: 1rem;
      display: inline-block;
      border-top: 1px solid #d3d3d3;
      margin-top: 1rem; }
      .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale > div:last-child > a {
        display: block;
        text-align: center; }
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale > div:last-child > a:first-child {
          width: 40%;
          margin: 1rem auto; }
        .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale > div:last-child > a:last-child {
          width: 100%;
          text-decoration: underline; }
  .anagrafiche-modal .wk-modal-content .wk-modal-body .cart-totale-mobile .cart-totale-mobile-dettagli > div .cart-totale-mobile-dettagli-row [class*='icon-genya-'] {
    margin-top: 0; }

.trial-modal,
.request-modal {
  width: 100%; }
  @media screen and (max-width: 1024px) {
    .trial-modal,
    .request-modal {
      width: 100%;
      padding: 0 2rem; } }
  @media screen and (max-width: 767px) {
    .trial-modal,
    .request-modal {
      width: 100%;
      padding: 0;
      top: 2rem; } }
  .trial-modal .wk-modal-content,
  .request-modal .wk-modal-content {
    max-width: 32rem; }
    .trial-modal .wk-modal-content .wk-modal-close,
    .request-modal .wk-modal-content .wk-modal-close {
      line-height: normal; }
    .trial-modal .wk-modal-content .wk-modal-body,
    .request-modal .wk-modal-content .wk-modal-body {
      padding: 1rem;
      display: inline-block; }
      .trial-modal .wk-modal-content .wk-modal-body .icon-genya-info-and-request,
      .trial-modal .wk-modal-content .wk-modal-body .icon-genya-check,
      .request-modal .wk-modal-content .wk-modal-body .icon-genya-info-and-request,
      .request-modal .wk-modal-content .wk-modal-body .icon-genya-check {
        font-size: 2.2rem;
        width: 100%;
        display: block;
        margin-bottom: 1rem; }
      .trial-modal .wk-modal-content .wk-modal-body p,
      .request-modal .wk-modal-content .wk-modal-body p {
        padding: 1rem 2rem;
        font-size: 1.125rem;
        line-height: 1.5; }
        .trial-modal .wk-modal-content .wk-modal-body p.buttons,
        .request-modal .wk-modal-content .wk-modal-body p.buttons {
          text-align: center; }
        .trial-modal .wk-modal-content .wk-modal-body p > a,
        .request-modal .wk-modal-content .wk-modal-body p > a {
          text-align: right; }
          .trial-modal .wk-modal-content .wk-modal-body p > a > span,
          .request-modal .wk-modal-content .wk-modal-body p > a > span {
            padding: 1.4rem; }
        .trial-modal .wk-modal-content .wk-modal-body p > a[wk-button],
        .request-modal .wk-modal-content .wk-modal-body p > a[wk-button] {
          min-width: 6rem;
          text-align: center; }

.condizioni-modal {
  z-index: 9999;
  /*::-webkit-scrollbar {
        width: 16px;
        height: 16px;
        border-bottom: 1px solid #eee;
        border-top: 1px solid #eee;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #c3c3c3;
        border: 8px solid $wkBlue;
    }
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    }*/ }
  .condizioni-modal .wk-modal-content .wk-modal-title {
    padding-bottom: 0px;
    line-height: 1.5; }
  .condizioni-modal .wk-modal-content .wk-modal-close {
    padding: 0px !important; }
    .condizioni-modal .wk-modal-content .wk-modal-close > span {
      padding: 3px; }
  .condizioni-modal .wk-modal-content .wk-modal-body {
    position: relative; }
    .condizioni-modal .wk-modal-content .wk-modal-body .condizioni-generali-testo {
      display: inline-block;
      max-height: 300px;
      overflow-y: scroll;
      padding-right: 24px; }
      .condizioni-modal .wk-modal-content .wk-modal-body .condizioni-generali-testo > ol.lst-kix_list_1-0:not(:first-of-type) {
        margin-top: 1.5rem; }
      .condizioni-modal .wk-modal-content .wk-modal-body .condizioni-generali-testo > ol.lst-kix_list_1-0 .c1 {
        font-size: 1.2rem;
        font-weight: bold; }
        .condizioni-modal .wk-modal-content .wk-modal-body .condizioni-generali-testo > ol.lst-kix_list_1-0 .c1 .c5 {
          font-size: 1.2rem; }
      @media screen and (max-device-height: 480px) {
        .condizioni-modal .wk-modal-content .wk-modal-body .condizioni-generali-testo {
          max-height: 240px; } }
      @media screen and (max-width: 767px) {
        .condizioni-modal .wk-modal-content .wk-modal-body .condizioni-generali-testo {
          padding: 0;
          width: 88%; } }
    .condizioni-modal .wk-modal-content .wk-modal-body .condizioni-generali-scroll-button {
      display: none;
      position: absolute;
      cursor: pointer;
      background-color: #85bc20;
      font-size: 1.4rem;
      line-height: 1;
      z-index: 99999;
      border-radius: 99px;
      color: #fff;
      padding: 14px;
      bottom: -1rem;
      left: 47%; }
      @media screen and (max-width: 600px) {
        .condizioni-modal .wk-modal-content .wk-modal-body .condizioni-generali-scroll-button {
          left: 45%; } }
      @media screen and (max-width: 480px) {
        .condizioni-modal .wk-modal-content .wk-modal-body .condizioni-generali-scroll-button {
          left: 43%; } }
      @media screen and (max-width: 360px) {
        .condizioni-modal .wk-modal-content .wk-modal-body .condizioni-generali-scroll-button {
          left: 40%; } }
  .condizioni-modal .wk-modal-content .condizioni-generali-accettazione,
  .condizioni-modal .wk-modal-content .condizioni-generali-accettazione-2 {
    margin: 12px 0;
    font-size: 1rem;
    border: 1px solid #007ac3;
    padding: 8px; }
    .condizioni-modal .wk-modal-content .condizioni-generali-accettazione > span,
    .condizioni-modal .wk-modal-content .condizioni-generali-accettazione-2 > span {
      margin-right: 2rem; }
    .condizioni-modal .wk-modal-content .condizioni-generali-accettazione .wk-field-item,
    .condizioni-modal .wk-modal-content .condizioni-generali-accettazione-2 .wk-field-item {
      margin-bottom: 0; }
  .condizioni-modal .wk-modal-content .condizioni-generali-accettazione-more {
    display: none; }
  .condizioni-modal .wk-modal-content .wk-modal-footer {
    background-color: #dadada;
    padding: 1rem; }
    @media screen and (max-width: 767px) {
      .condizioni-modal .wk-modal-content .wk-modal-footer .condizioni-generali-buttons a[wk-button] {
        display: inline-block;
        width: 47%;
        margin-bottom: 0; } }
    .condizioni-modal .wk-modal-content .wk-modal-footer .condizioni-generali-buttons a[wk-button="secondary"] {
      background-color: transparent; }

input[type=range][orient=vertical] {
  writing-mode: bt-lr;
  /* IE */
  -webkit-appearance: slider-vertical;
  width: 20px;
  height: 80%;
  padding: 0 5px;
  position: absolute;
  right: 16px; }
  input[type=range][orient=vertical]::-webkit-slider-thumb {
    height: 24px;
    width: 24px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none; }

.cart-suite-modal .wk-modal-content {
  width: 58rem; }
  @media screen and (max-width: 1024px) {
    .cart-suite-modal .wk-modal-content {
      width: 100%; } }
  @media screen and (max-width: 767px) {
    .cart-suite-modal .wk-modal-content {
      width: 100%; } }
  .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto {
    margin-top: 0; }
    @media screen and (max-width: 1024px) {
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto {
        width: 100%; } }
    @media screen and (max-width: 767px) {
      .cart-suite-modal .wk-modal-content .wk-modal-body .cart-pacchetto-aggiunto {
        width: 100%; } }
  @media screen and (max-width: 1024px) {
    .cart-suite-modal .wk-modal-content .wk-modal-body .cart-aggiungi-anagrafiche {
      width: 100%;
      margin-top: 2rem; } }

@media screen and (max-width: 767px) {
  .tutorial-modal .wk-modal-content .wk-modal-body {
    padding: 0; } }

.tutorial-modal .video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0; }
  .tutorial-modal .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/* Footer */
.wk-footer {
  padding-top: 3rem;
  margin-top: 0;
  line-height: 1.5; }
  @media screen and (max-width: 768px) {
    .wk-footer .wk-footer-nav .wk-footer-nav-list {
      margin-bottom: 2.5rem;
      width: 100%; } }
  .wk-footer .wk-footer-nav .wk-footer-nav-list:last-child {
    float: right;
    margin-right: 0;
    width: auto; }
    @media screen and (max-width: 768px) {
      .wk-footer .wk-footer-nav .wk-footer-nav-list:last-child {
        float: left; } }
    .wk-footer .wk-footer-nav .wk-footer-nav-list:last-child ul {
      text-align: right; }
      @media screen and (max-width: 768px) {
        .wk-footer .wk-footer-nav .wk-footer-nav-list:last-child ul {
          text-align: left; } }
  .wk-footer .wk-footer-nav .wk-footer-nav-list .wk-footer-heading {
    font-size: 1.2rem;
    margin-left: 27px;
    margin-bottom: 1rem; }
    @media screen and (max-width: 768px) {
      .wk-footer .wk-footer-nav .wk-footer-nav-list .wk-footer-heading {
        margin-left: 0; } }
  .wk-footer .wk-footer-nav .wk-footer-nav-list ul.wk-social-icons-container li a {
    display: block;
    width: 45px; }
  .wk-footer .wk-footer-meta-info li {
    color: #474747;
    line-height: 1.5; }
  .wk-footer .wk-footer-meta-info a {
    text-decoration: none; }
    .wk-footer .wk-footer-meta-info a:hover {
      text-decoration: none; }
  .wk-footer .footer-phone > a,
  .wk-footer .footer-video > a {
    font-size: 1.3rem;
    display: inline-block; }
    .wk-footer .footer-phone > a [class*='icon-genya-'],
    .wk-footer .footer-video > a [class*='icon-genya-'] {
      vertical-align: text-top; }

/* Cookie Policy Bar */
.cookie-policy-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(71, 71, 71, 0.9);
  padding: 1.2rem;
  z-index: 9999;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5; }
  @media screen and (max-width: 767px) {
    .cookie-policy-bar {
      font-size: 0.8rem;
      padding: 0.8rem;
      width: 100%; } }
  .cookie-policy-bar div {
    width: 87%;
    display: inline-block;
    padding-right: 1rem; }
    @media screen and (max-width: 1024px) {
      .cookie-policy-bar div {
        width: 75%; } }
    @media screen and (max-width: 767px) {
      .cookie-policy-bar div {
        padding-right: 0;
        width: 100%; } }
  .cookie-policy-bar a {
    color: #fff;
    text-decoration: underline; }
    .cookie-policy-bar a:hover {
      color: #fff; }
    .cookie-policy-bar a:active {
      color: #fff; }
    .cookie-policy-bar a:focus {
      color: #fff; }
  .cookie-policy-bar a[wk-button] {
    width: 13%;
    float: right;
    padding: 6px;
    margin-top: 4px;
    font-weight: 500;
    background-color: #e5202e;
    text-decoration: none; }
    .cookie-policy-bar a[wk-button]:hover {
      background-color: #e83743 !important; }
    @media screen and (max-width: 1024px) {
      .cookie-policy-bar a[wk-button] {
        margin-top: 1rem;
        width: 25%; } }
    @media screen and (max-width: 767px) {
      .cookie-policy-bar a[wk-button] {
        font-size: 0.8rem;
        width: 100%; } }

/* Cookie policy banner 2 */
#banner_consenso_cookie {
  position: fixed;
  font-size: 100%;
  left: 0pt;
  width: 100%;
  background-color: #0082c8;
  background-color: rgba(0, 130, 200, 0.95);
  display: none;
  z-index: 10000;
  clear: both;
  float: none;
  top: 0pt;
  padding: 8pt;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10.75pt;
  line-height: 1.3;
  font-weight: 400;
  background-image: none;
  letter-spacing: normal;
  text-align: left;
  text-indent: 0;
  vertical-align: top;
  word-spacing: normal;
  white-space: normal;
  margin: 0pt;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  height: auto;
  right: auto;
  box-sizing: border-box;
  border-collapse: collapse;
  border-spacing: 0px;
  table-layout: fixed;
  background-color: rgba(66, 66, 66, 0.95) !important;
  float: inherit !important;
  top: inherit !important;
  bottom: 0 !important;
  padding-top: 15px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-bottom: 10px !important; }

#testo_banner {
  font-size: 14px;
  line-height: 130%;
  font-weight: normal;
  font-family: "Source Sans Pro",Helvetica Neue,Helvetica,Arial,sans-serif !important;
  color: #fff;
  font-style: normal;
  padding: 0 !important;
  margin: 0 !important;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-shadow: none !important;
  bottom: inherit !important;
  float: left !important;
  margin-bottom: 10px !important;
  width: 82% !important;
  margin-right: 2% !important; }

#testo_banner a {
  color: #fff !important;
  text-decoration: underline !important; }

#chiusura_banner {
  display: block;
  cursor: pointer;
  background-color: transparent !important;
  font-size: 14px;
  line-height: 100%;
  font-weight: bold;
  font-family: "Source Sans Pro",Helvetica Neue,Helvetica,Arial,sans-serif !important;
  margin: 0 !important;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  width: 14% !important;
  text-align: center !important;
  text-indent: -99999px !important;
  position: relative !important;
  height: 35px !important;
  float: left !important;
  top: 0 !important;
  right: 0 !important; }

#chiusura_banner::after {
  content: "Accetto";
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: auto !important;
  bottom: auto !important;
  border-style: none;
  cursor: pointer;
  vertical-align: middle;
  -moz-box-shadow: rgba(12, 12, 15, 0.2) 0 2px 1px;
  -webkit-box-shadow: rgba(12, 12, 15, 0.2) 0 2px 1px;
  box-shadow: rgba(12, 12, 15, 0.2) 0 2px 1px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #e5202e !important;
  color: #fff !important;
  border-style: none;
  width: 100% !important;
  text-align: center !important;
  height: 35px !important;
  font-size: 14px;
  line-height: 230%;
  font-weight: bold;
  float: none !important;
  padding: 0 !important;
  margin: 0 !important;
  visibility: visible;
  overflow: visible;
  clear: both;
  text-decoration: none;
  text-indent: 0;
  word-spacing: normal;
  z-index: 999999;
  -moz-transition: all 150ms ease-out;
  -o-transition: all 150ms ease-out;
  -webkit-transition: all 150ms ease-out;
  transition: all 150ms ease-out; }

#chiusura_banner:hover::after {
  background-color: #e83743 !important; }

@media screen and (max-width: 600px) {
  #testo_banner {
    width: 100% !important;
    font-size: 12px !important; }
  #chiusura_banner {
    width: 100% !important; } }

html {
  /* LT IE9 */ }
  html.ie .row-pacchetti-obscured > div {
    cursor: not-allowed;
    -ms-filter: alpha(opacity=50);
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
    filter: alpha(opacity=50);
    opacity: 0.5; }

body {
  /* Common rules for IE and Edege */
  /* IE */
  /* Edge */ }
  body.msie .opener-menu-profilo .nav-profile-menu,
  body.msie .opener-menu-profilo .nav-carrello-menu,
  body.msie .opener-menu-carrello .nav-profile-menu,
  body.msie .opener-menu-carrello .nav-carrello-menu, body.msedge .opener-menu-profilo .nav-profile-menu,
  body.msedge .opener-menu-profilo .nav-carrello-menu,
  body.msedge .opener-menu-carrello .nav-profile-menu,
  body.msedge .opener-menu-carrello .nav-carrello-menu {
    top: 43px; }
  body.msie .dropdown-cart, body.msedge .dropdown-cart {
    top: 43px; }

body {
  font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ededed;
  color: #474747;
  margin-top: 0 !important; }
  body > form {
    background-color: #fff; }
    body > form.transparent {
      background-color: inherit; }
    body > form > .wk-page-content {
      margin-top: 1rem; }
  body.contact-page form {
    background-color: #ededed; }
  body.contact-page .page-content-centered {
    max-width: 50rem;
    margin: 3rem auto;
    background-color: #fff;
    padding: 2rem; }
    @media screen and (max-width: 768px) {
      body.contact-page .page-content-centered {
        margin: 1rem auto; } }
    @media screen and (max-width: 767px) {
      body.contact-page .page-content-centered {
        padding: 1rem; } }
    body.contact-page .page-content-centered > .wk-h2,
    body.contact-page .page-content-centered > .wk-h3 {
      font-weight: 400;
      line-height: 1.5; }
    body.contact-page .page-content-centered p.form-nota {
      font-size: 1rem;
      margin: 1.5rem 0;
      line-height: 1.5; }
      body.contact-page .page-content-centered p.form-nota > span {
        color: #005b92; }

.fixed {
  position: fixed;
  overflow: hidden;
  width: 100%; }

.wk-h1,
.wk-h2,
.wk-h3 {
  font-weight: 500; }

.heading-blue {
  color: #007ac3;
  font-family: "Franziska", serif;
  font-style: italic; }

.redText {
  color: #e5202e; }

.grayText {
  color: #A3A3A3; }

.greenText {
  color: #85bc20; }

.wk-green-icons [class*='icon-genya-'] {
  color: #85bc20; }

.green-icon,
.green-icon:before,
.green-icon:after {
  color: #85bc20; }

.gray-icon,
.gray-icon:before,
.gray-icon:after {
  color: #757575; }

.icon-action [class*="icon-genya-"] {
  display: inline-block;
  margin-right: 0;
  color: #007ac3; }

a:hover {
  text-decoration: none; }

a:active {
  text-decoration: none; }

a:focus {
  text-decoration: none; }

a[wk-button],
button[wk-button] {
  font-size: 1rem;
  line-height: 1.5; }
  a[wk-button]:hover,
  button[wk-button]:hover {
    text-decoration: none; }
  a[wk-button].wk-icon-arrow-right,
  button[wk-button].wk-icon-arrow-right {
    padding-left: 1.3rem; }
    a[wk-button].wk-icon-arrow-right:before,
    button[wk-button].wk-icon-arrow-right:before {
      width: 3.6rem;
      right: 0;
      padding: 1.3rem;
      background: none; }

a.phone-number {
  color: #474747; }
  a.phone-number:hover {
    color: #005b92; }
  a.phone-number:active {
    color: #005b92; }
  a.phone-number:visited {
    color: #474747;
    text-decoration: none; }

/*
* Link e icone disabilitate dopo il click
*/
.disabled-link {
  color: #bfbfbf !important;
  cursor: not-allowed; }
  .disabled-link:before {
    color: #bfbfbf !important;
    cursor: not-allowed; }
  .disabled-link:visited {
    color: #bfbfbf !important;
    cursor: not-allowed; }
  .disabled-link:hover {
    color: #bfbfbf !important;
    cursor: not-allowed; }
  .disabled-link:active {
    color: #bfbfbf !important;
    cursor: not-allowed; }

.wk-page-content {
  margin: 3rem auto; }
  @media screen and (max-width: 767px) {
    .wk-page-content {
      margin: 1rem auto; } }

.genya-top-header {
  position: relative;
  height: 320px;
  width: 100%;
  margin: 0 auto;
  background-image: url("../images/header_1.jpg");
  background-size: cover;
  background-color: #fff;
  background-repeat: no-repeat; }
  @media screen and (min-width: 1024px) {
    .genya-top-header {
      background-position: 20% 30%; } }
  @media screen and (max-width: 480px) {
    .genya-top-header {
      background-size: contain; } }
  .genya-top-header .wk-h2,
  .genya-top-header .wk-h3 {
    color: #fff; }
  .genya-top-header .wk-h2 {
    margin-top: 0;
    font-family: "Franziska", serif;
    font-style: italic; }
  .genya-top-header .wk-h3 {
    font-size: 1rem; }
  .genya-top-header .genya-top-header-wrapper {
    width: 100%;
    max-width: 75rem;
    margin: auto; }
  .genya-top-header .genya-top-header-logged,
  .genya-top-header .genya-top-header-guest {
    width: 40%;
    position: absolute;
    bottom: 0; }
    @media screen and (max-width: 768px) {
      .genya-top-header .genya-top-header-logged,
      .genya-top-header .genya-top-header-guest {
        max-width: 100%;
        width: 100%;
        padding-left: 0;
        position: absolute;
        bottom: -3.6rem; } }
    @media screen and (max-width: 599px) {
      .genya-top-header .genya-top-header-logged,
      .genya-top-header .genya-top-header-guest {
        bottom: -1rem; } }
  @media screen and (max-width: 768px) {
    .genya-top-header .genya-top-header-logged {
      bottom: 0; } }
  .genya-top-header .genya-top-header-guest-button {
    width: 100%; }
    .genya-top-header .genya-top-header-guest-button a[wk-button] {
      float: right; }
      @media screen and (max-width: 767px) {
        .genya-top-header .genya-top-header-guest-button a[wk-button] {
          font-size: 0.9rem; } }
  .genya-top-header .genya-top-header-section {
    display: inline-block;
    width: 100%;
    padding: 2rem 3rem;
    margin-bottom: -0.1px; }
    .genya-top-header .genya-top-header-section .wk-h2 {
      line-height: 1.5; }
      @media screen and (max-width: 767px) {
        .genya-top-header .genya-top-header-section .wk-h2 {
          font-size: 1.4rem; } }
    .genya-top-header .genya-top-header-section .wk-h3 {
      line-height: 1.5; }
    @media screen and (max-width: 768px) {
      .genya-top-header .genya-top-header-section {
        padding: 1rem 1.2rem;
        background-color: #241866;
        background: #241866;
        color: #241866; } }
    @media screen and (max-width: 767px) {
      .genya-top-header .genya-top-header-section {
        background-color: #241866; } }
  .genya-top-header.genya-top-header-landing {
    background-image: url("../images/landing-header.jpg"); }
    @media screen and (max-width: 767px) {
      .genya-top-header.genya-top-header-landing {
        background-size: cover;
        height: 330px; } }
    @media screen and (max-width: 360px) {
      .genya-top-header.genya-top-header-landing {
        background-size: contain;
        height: 300px; } }
    .genya-top-header.genya-top-header-landing .genya-top-header-guest {
      bottom: 2rem; }
      @media screen and (max-width: 1024px) {
        .genya-top-header.genya-top-header-landing .genya-top-header-guest {
          bottom: 0; } }
    .genya-top-header.genya-top-header-landing .genya-top-header-section {
      background-color: rgba(0, 122, 195, 0.8); }
      @media screen and (max-width: 767px) {
        .genya-top-header.genya-top-header-landing .genya-top-header-section {
          background-color: #007ac3; } }

.genya-top-header-professional {
  background-image: url("../images/genya_header_professional.jpg"); }
  .genya-top-header-professional .genya-top-header-section {
    background-color: rgba(36, 24, 102, 0.8); }
    @media screen and (max-width: 767px) {
      .genya-top-header-professional .genya-top-header-section {
        background-color: #241866; } }

.genya-top-header-aziende {
  background-image: url("../images/genya_header_corporate.jpg");
  background-position: 20% 80%; }
  @media screen and (max-width: 767px) {
    .genya-top-header-aziende {
      background-position: 0 0; } }
  .genya-top-header-aziende .genya-top-header-section {
    background-color: rgba(148, 12, 114, 0.8); }
    @media screen and (max-width: 767px) {
      .genya-top-header-aziende .genya-top-header-section {
        background-color: #940c72; } }

.genya-top-header-fatturasmart {
  background-image: url("../images/genya_header_fatturasmart.jpg");
  background-position: 50% 45%; }
  @media screen and (max-width: 767px) {
    .genya-top-header-fatturasmart {
      background-position: 0 0; } }
  .genya-top-header-fatturasmart .genya-top-header-section {
    background-color: rgba(0, 152, 129, 0.8); }
    @media screen and (max-width: 767px) {
      .genya-top-header-fatturasmart .genya-top-header-section {
        background-color: #009881; } }

.genya-tab-container {
  /* iOS scrolling bars*/ }
  .genya-tab-container .wk-nav-tab-bar {
    padding-top: 2rem;
    margin-bottom: 0;
    background-color: #fff; }
    @media screen and (min-width: 600px) and (max-width: 768px) {
      .genya-tab-container .wk-nav-tab-bar {
        padding-top: 3.2rem; } }
  .genya-tab-container ul.wk-nav-tabs li a {
    font-size: 1rem;
    color: #007ac2; }
    .genya-tab-container ul.wk-nav-tabs li a [class*="icon-genya-"] {
      color: #757575;
      vertical-align: sub;
      font-size: 1.4rem;
      margin-left: 0.25rem;
      line-height: 0.9; }
    .genya-tab-container ul.wk-nav-tabs li a .icon-genya-lock {
      font-size: 1.1rem;
      vertical-align: middle; }
  .genya-tab-container ul.wk-nav-tabs li.active a {
    background-color: #ededed; }
  .genya-tab-container .tab-content {
    padding: 1rem 0;
    background-color: #ededed; }
    .genya-tab-container .tab-content .wk-h3 {
      margin-bottom: 2rem;
      font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 1.875rem;
      -webkit-font-smoothing: subpixel-antialiased;
      -moz-osx-font-smoothing: auto; }
      @media screen and (max-width: 420px) {
        .genya-tab-container .tab-content .wk-h3 {
          max-width: 85%; } }
      .genya-tab-container .tab-content .wk-h3 strong {
        font-weight: 500; }
    .genya-tab-container .tab-content .wk-page-content {
      margin: 2rem auto; }
    .genya-tab-container .tab-content p {
      font-size: 1.25rem;
      line-height: 1.5; }
  .genya-tab-container .tab-pane {
    position: relative; }
    .genya-tab-container .tab-pane .tab-presto-online {
      margin-bottom: 2rem; }
      .genya-tab-container .tab-pane .tab-presto-online .wk-h3 {
        max-width: 80%; }
      .genya-tab-container .tab-pane .tab-presto-online:before {
        content: ' ';
        background-image: url("../images/presto_online.png");
        background-size: 100%;
        background-repeat: no-repeat;
        width: 10rem;
        height: 10rem;
        display: block;
        position: absolute;
        top: -4rem;
        right: 0; }
        @media screen and (max-width: 768px) {
          .genya-tab-container .tab-pane .tab-presto-online:before {
            width: 6rem;
            height: 6rem; } }
        @media screen and (max-width: 767px) {
          .genya-tab-container .tab-pane .tab-presto-online:before {
            top: -2.1rem; } }
  .genya-tab-container ::-webkit-scrollbar {
    height: 8px;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee; }
  .genya-tab-container ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #C3C3C3;
    border: 1px solid #eee; }
  .genya-tab-container ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2); }

.genya-mobile-full-container {
  padding-left: 0;
  padding-right: 0; }
  .genya-mobile-full-container p,
  .genya-mobile-full-container .wk-h3 {
    padding-left: 1rem;
    padding-right: 1rem; }

/* Landing page */
.landing-content {
  margin-top: 0; }
  @media screen and (max-width: 767px) {
    .landing-content {
      margin: 1rem auto; } }
  .landing-content .landing-content-text .wk-h3 {
    font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
    margin-bottom: 2rem;
    line-height: 1.5;
    font-weight: 300;
    font-size: 1.875rem;
    font-style: normal;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto; }
    .landing-content .landing-content-text .wk-h3 strong {
      font-weight: 500; }
  .landing-content .landing-content-text p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 2rem; }
  .landing-content .landing-box {
    background-color: #fff;
    padding: 1rem 2rem;
    font-size: 1.2rem; }
    @media screen and (max-width: 1024px) {
      .landing-content .landing-box {
        margin-bottom: 2rem; } }
    .landing-content .landing-box .wk-h2 {
      font-family: "Franziska", serif;
      font-style: italic; }
    .landing-content .landing-box .landing-subtitle {
      color: #757575; }
      @media screen and (max-width: 767px) {
        .landing-content .landing-box .landing-subtitle {
          line-height: 1.2rem; } }
    .landing-content .landing-box .landing-soluzione {
      position: relative;
      width: 100%;
      margin: 1rem 0;
      padding: 1.4rem 0;
      border-top: 1px solid #d3d3d3; }
      .landing-content .landing-box .landing-soluzione > span {
        display: inline-block;
        padding: 0.8rem;
        font-size: 2.2rem;
        line-height: 2.2rem; }
      .landing-content .landing-box .landing-soluzione .landing-soluzione-descrizione {
        display: inline-block;
        vertical-align: top;
        max-width: 50%;
        color: #757575;
        margin-left: 0.5rem;
        line-height: 1.5; }
        @media screen and (min-width: 768px) and (max-width: 991px) {
          .landing-content .landing-box .landing-soluzione .landing-soluzione-descrizione {
            max-width: 65%; } }
        @media screen and (max-width: 767px) {
          .landing-content .landing-box .landing-soluzione .landing-soluzione-descrizione {
            max-width: 180px;
            font-size: 1rem;
            line-height: 1rem; } }
        @media screen and (max-width: 360px) {
          .landing-content .landing-box .landing-soluzione .landing-soluzione-descrizione {
            max-width: 140px; } }
        .landing-content .landing-box .landing-soluzione .landing-soluzione-descrizione strong:first-of-type {
          width: 100%;
          display: block;
          line-height: 1.8rem; }
          @media screen and (max-width: 767px) {
            .landing-content .landing-box .landing-soluzione .landing-soluzione-descrizione strong:first-of-type {
              line-height: normal; } }
        .landing-content .landing-box .landing-soluzione .landing-soluzione-descrizione + a {
          position: absolute;
          top: 2.3rem;
          right: 0;
          padding: 1rem 2rem;
          font-size: 1.2rem; }
          @media screen and (max-width: 768px) {
            .landing-content .landing-box .landing-soluzione .landing-soluzione-descrizione + a {
              padding: 1rem 2rem;
              font-size: 1.2rem; } }
          @media screen and (max-width: 767px) {
            .landing-content .landing-box .landing-soluzione .landing-soluzione-descrizione + a {
              position: relative;
              width: 100%;
              top: 1rem; } }
  .landing-content .landing-box-professionisti .wk-h2 {
    color: #241866; }
  .landing-content .landing-box-professionisti .landing-soluzione > span {
    color: #6253b9;
    background-color: #3a3076; }
  .landing-content .landing-box-aziende .wk-h2 {
    color: #940c72; }
  .landing-content .landing-box-aziende .landing-soluzione > span {
    color: #c31096;
    background-color: #940c72; }

/* Pacchetti */
.wk-row.row-pacchetti {
  margin-top: 2rem; }

.pacchetto {
  background-color: #fff;
  margin-top: 2rem; }
  .pacchetto .wk-h4 {
    padding: 1rem;
    margin: 0 0 1rem 0;
    font-family: "Franziska", serif;
    font-style: italic;
    color: #fff;
    font-size: 1.4375rem;
    font-weight: 400; }
  .pacchetto .pacchetto-info {
    padding: 0 1rem 1rem 1rem; }
  .pacchetto .pacchetto-anagrafiche {
    position: relative;
    font-size: 1.1875rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    word-wrap: break-word;
    white-space: nowrap;
    border-bottom: 1px solid #d3d3d3;
    overflow: hidden;
    text-overflow: ellipsis; }
    .pacchetto .pacchetto-anagrafiche [class*="icon-"] {
      font-size: 1rem; }
    .pacchetto .pacchetto-anagrafiche.descrizione-suite {
      white-space: unset; }
    .pacchetto .pacchetto-anagrafiche > span {
      font-family: "Franziska", serif;
      font-size: 2rem;
      font-style: italic; }
    .pacchetto .pacchetto-anagrafiche > a {
      float: none;
      margin-top: 1rem;
      color: #a3a3a3; }
      .pacchetto .pacchetto-anagrafiche > a:visited {
        color: #a3a3a3; }
      .pacchetto .pacchetto-anagrafiche > a:hover, .pacchetto .pacchetto-anagrafiche > a:active {
        color: #008CBB; }
    .pacchetto .pacchetto-anagrafiche .pacchetto-info-popup {
      display: none;
      position: absolute;
      font-size: 0.9rem;
      background-color: #fff;
      top: -7rem;
      right: -17rem;
      width: 300px;
      padding: 1rem;
      border: 1px solid #d3d3d3;
      z-index: 999; }
    .pacchetto .pacchetto-anagrafiche .pacchetto-info-accordion {
      display: none;
      position: relative;
      font-size: 0.9rem;
      padding-top: 1rem;
      color: #A3A3A3;
      background-color: #fff;
      z-index: 999;
      white-space: normal; }
  .pacchetto .pacchetto-incluso {
    font-size: 1.1875rem;
    font-style: italic;
    font-family: "Franziska", serif; }
    .pacchetto .pacchetto-incluso ul {
      margin-top: 0.5rem;
      list-style-position: outside;
      list-style-type: none;
      padding-left: 0.5rem; }
      .pacchetto .pacchetto-incluso ul li {
        font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
        font-size: 0.9rem;
        font-style: normal; }
        .pacchetto .pacchetto-incluso ul li:before {
          content: '\0045';
          font-family: "genya icons";
          font-size: 0.9rem;
          color: #85bc20;
          font-weight: bold;
          margin-right: 4px;
          padding-right: 5px; }
  .pacchetto .pacchetto-prezzo {
    margin: 2rem auto;
    font-size: 1.6rem; }
  .pacchetto .pacchetto-dettagli {
    margin: 2rem auto; }
  .pacchetto .pacchetto-button {
    text-align: center;
    padding: 1rem; }
    .pacchetto .pacchetto-button > a {
      width: 100%;
      font-size: 1rem;
      line-height: 1.5;
      display: block;
      white-space: normal; }
      @media screen and (min-width: 992px) and (max-width: 1100px) {
        .pacchetto .pacchetto-button > a {
          font-size: 0.8rem; } }
      @media screen and (max-width: 767px) {
        .pacchetto .pacchetto-button > a {
          font-size: 0.9rem; } }
    .pacchetto .pacchetto-button > span {
      display: inline-block;
      min-height: 53px; }
  .pacchetto.pacchetto-obscured .pacchetto-anagrafiche > a {
    cursor: default;
    float: right;
    margin-top: 1rem;
    color: #a3a3a3; }
    .pacchetto.pacchetto-obscured .pacchetto-anagrafiche > a:visited {
      color: #a3a3a3; }
    .pacchetto.pacchetto-obscured .pacchetto-anagrafiche > a:hover, .pacchetto.pacchetto-obscured .pacchetto-anagrafiche > a:active {
      color: #a3a3a3; }
    @media screen and (max-width: 1024px) {
      .pacchetto.pacchetto-obscured .pacchetto-anagrafiche > a {
        float: none; } }
  .pacchetto.pacchetto-col-6 .pacchetto-info {
    display: inline-block;
    width: 100%; }
    .pacchetto.pacchetto-col-6 .pacchetto-info .pacchetto-incluso,
    .pacchetto.pacchetto-col-6 .pacchetto-info .pacchetto-prezzo,
    .pacchetto.pacchetto-col-6 .pacchetto-info .pacchetto-dettagli {
      width: 50%;
      float: left; }
      @media screen and (max-width: 767px) {
        .pacchetto.pacchetto-col-6 .pacchetto-info .pacchetto-incluso,
        .pacchetto.pacchetto-col-6 .pacchetto-info .pacchetto-prezzo,
        .pacchetto.pacchetto-col-6 .pacchetto-info .pacchetto-dettagli {
          width: 100%; } }
    .pacchetto.pacchetto-col-6 .pacchetto-info .pacchetto-incluso {
      border-right: 1px solid #d3d3d3; }
      @media screen and (max-width: 767px) {
        .pacchetto.pacchetto-col-6 .pacchetto-info .pacchetto-incluso {
          border: 0; } }
    .pacchetto.pacchetto-col-6 .pacchetto-info .pacchetto-prezzo {
      padding: 0 1rem;
      text-align: center; }
      @media screen and (max-width: 767px) {
        .pacchetto.pacchetto-col-6 .pacchetto-info .pacchetto-prezzo {
          text-align: left; } }
    .pacchetto.pacchetto-col-6 .pacchetto-info .pacchetto-dettagli {
      padding: 0 2rem;
      text-align: left; }
      @media screen and (max-width: 767px) {
        .pacchetto.pacchetto-col-6 .pacchetto-info .pacchetto-dettagli {
          padding: 0 1rem; } }
  .pacchetto.pacchetto-disabled {
    -ms-filter: alpha(opacity=40);
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha Opacity=40;
    filter: alpha(opacity=40);
    opacity: 0.4;
    cursor: not-allowed; }
    .pacchetto.pacchetto-disabled .pacchetto-anagrafiche > a {
      float: none;
      margin-top: 1rem;
      color: #a3a3a3;
      cursor: not-allowed; }
      .pacchetto.pacchetto-disabled .pacchetto-anagrafiche > a:visited {
        color: #a3a3a3; }
      .pacchetto.pacchetto-disabled .pacchetto-anagrafiche > a:hover, .pacchetto.pacchetto-disabled .pacchetto-anagrafiche > a:active {
        color: #a3a3a3; }
    .pacchetto.pacchetto-disabled button {
      cursor: not-allowed; }

@media screen and (max-width: 767px) {
  .pacchetto-owned,
  .pacchetto-in-evidenza {
    margin-top: 3rem; } }

.pacchetto-in-evidenza {
  -webkit-box-shadow: 0px 0px 15px 7px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 0px 15px 7px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 15px 7px rgba(0, 0, 0, 0.12); }
  .pacchetto-in-evidenza .pacchetto-in-evidenza-alert {
    font-size: 1rem;
    font-weight: 500; }
    .pacchetto-in-evidenza .pacchetto-in-evidenza-alert img {
      width: 1.375rem;
      margin-right: 0.5rem;
      vertical-align: top; }

.pacchetto-owned-alert,
.pacchetto-in-evidenza-alert {
  position: absolute;
  margin-top: -2rem;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.6rem; }
  .pacchetto-owned-alert > [class*='icon-genya-'],
  .pacchetto-in-evidenza-alert > [class*='icon-genya-'] {
    margin-right: 0.25rem;
    vertical-align: text-top;
    font-size: 1.2rem; }

.colore-pacchetto-professional-01 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-professional-01 h4 {
    background-color: #241866; }
  .colore-pacchetto-professional-01.pacchetto-owned {
    border-right: 4px solid #241866;
    border-left: 4px solid #241866;
    border-bottom: 4px solid #241866; }
  .colore-pacchetto-professional-01 .pacchetto-owned-alert,
  .colore-pacchetto-professional-01 .pacchetto-in-evidenza-alert {
    color: #241866; }
  .colore-pacchetto-professional-01[class*='icon-genya-'] {
    color: #241866 !important; }

.colore-pacchetto-professional-02 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-professional-02 h4 {
    background-color: #2f246e; }
  .colore-pacchetto-professional-02.pacchetto-owned {
    border-right: 4px solid #2f246e;
    border-left: 4px solid #2f246e;
    border-bottom: 4px solid #2f246e; }
  .colore-pacchetto-professional-02 .pacchetto-owned-alert,
  .colore-pacchetto-professional-02 .pacchetto-in-evidenza-alert {
    color: #2f246e; }
  .colore-pacchetto-professional-02[class*='icon-genya-'] {
    color: #2f246e !important; }

.colore-pacchetto-professional-03 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-professional-03 h4 {
    background-color: #3a3076; }
  .colore-pacchetto-professional-03.pacchetto-owned {
    border-right: 4px solid #3a3076;
    border-left: 4px solid #3a3076;
    border-bottom: 4px solid #3a3076; }
  .colore-pacchetto-professional-03 .pacchetto-owned-alert,
  .colore-pacchetto-professional-03 .pacchetto-in-evidenza-alert {
    color: #3a3076; }
  .colore-pacchetto-professional-03[class*='icon-genya-'] {
    color: #3a3076 !important; }

.colore-pacchetto-professional-04 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-professional-04 h4 {
    background-color: #453a7d; }
  .colore-pacchetto-professional-04.pacchetto-owned {
    border-right: 4px solid #453a7d;
    border-left: 4px solid #453a7d;
    border-bottom: 4px solid #453a7d; }
  .colore-pacchetto-professional-04 .pacchetto-owned-alert,
  .colore-pacchetto-professional-04 .pacchetto-in-evidenza-alert {
    color: #453a7d; }
  .colore-pacchetto-professional-04[class*='icon-genya-'] {
    color: #453a7d !important; }

.colore-pacchetto-professional-05 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-professional-05 h4 {
    background-color: #504685; }
  .colore-pacchetto-professional-05.pacchetto-owned {
    border-right: 4px solid #504685;
    border-left: 4px solid #504685;
    border-bottom: 4px solid #504685; }
  .colore-pacchetto-professional-05 .pacchetto-owned-alert,
  .colore-pacchetto-professional-05 .pacchetto-in-evidenza-alert {
    color: #504685; }
  .colore-pacchetto-professional-05[class*='icon-genya-'] {
    color: #504685 !important; }

.colore-pacchetto-professional-06 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-professional-06 h4 {
    background-color: #5b528c; }
  .colore-pacchetto-professional-06.pacchetto-owned {
    border-right: 4px solid #5b528c;
    border-left: 4px solid #5b528c;
    border-bottom: 4px solid #5b528c; }
  .colore-pacchetto-professional-06 .pacchetto-owned-alert,
  .colore-pacchetto-professional-06 .pacchetto-in-evidenza-alert {
    color: #5b528c; }
  .colore-pacchetto-professional-06[class*='icon-genya-'] {
    color: #5b528c !important; }

.colore-pacchetto-professional-07 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-professional-07 h4 {
    background-color: #665e94; }
  .colore-pacchetto-professional-07.pacchetto-owned {
    border-right: 4px solid #665e94;
    border-left: 4px solid #665e94;
    border-bottom: 4px solid #665e94; }
  .colore-pacchetto-professional-07 .pacchetto-owned-alert,
  .colore-pacchetto-professional-07 .pacchetto-in-evidenza-alert {
    color: #665e94; }
  .colore-pacchetto-professional-07[class*='icon-genya-'] {
    color: #665e94 !important; }

.colore-pacchetto-professional-08 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-professional-08 h4 {
    background-color: #70699b; }
  .colore-pacchetto-professional-08.pacchetto-owned {
    border-right: 4px solid #70699b;
    border-left: 4px solid #70699b;
    border-bottom: 4px solid #70699b; }
  .colore-pacchetto-professional-08 .pacchetto-owned-alert,
  .colore-pacchetto-professional-08 .pacchetto-in-evidenza-alert {
    color: #70699b; }
  .colore-pacchetto-professional-08[class*='icon-genya-'] {
    color: #70699b !important; }

.colore-pacchetto-aziende-01 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-aziende-01 h4 {
    background-color: #6f0955; }
  .colore-pacchetto-aziende-01.pacchetto-owned {
    border-right: 4px solid #6f0955;
    border-left: 4px solid #6f0955;
    border-bottom: 4px solid #6f0955; }
  .colore-pacchetto-aziende-01 .pacchetto-owned-alert,
  .colore-pacchetto-aziende-01 .pacchetto-in-evidenza-alert {
    color: #6f0955; }
  .colore-pacchetto-aziende-01[class*='icon-genya-'] {
    color: #6f0955 !important; }

.colore-pacchetto-aziende-02 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-aziende-02 h4 {
    background-color: #76165e; }
  .colore-pacchetto-aziende-02.pacchetto-owned {
    border-right: 4px solid #76165e;
    border-left: 4px solid #76165e;
    border-bottom: 4px solid #76165e; }
  .colore-pacchetto-aziende-02 .pacchetto-owned-alert,
  .colore-pacchetto-aziende-02 .pacchetto-in-evidenza-alert {
    color: #76165e; }
  .colore-pacchetto-aziende-02[class*='icon-genya-'] {
    color: #76165e !important; }

.colore-pacchetto-aziende-03 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-aziende-03 h4 {
    background-color: #7d2166; }
  .colore-pacchetto-aziende-03.pacchetto-owned {
    border-right: 4px solid #7d2166;
    border-left: 4px solid #7d2166;
    border-bottom: 4px solid #7d2166; }
  .colore-pacchetto-aziende-03 .pacchetto-owned-alert,
  .colore-pacchetto-aziende-03 .pacchetto-in-evidenza-alert {
    color: #7d2166; }
  .colore-pacchetto-aziende-03[class*='icon-genya-'] {
    color: #7d2166 !important; }

.colore-pacchetto-aziende-04 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-aziende-04 h4 {
    background-color: #842e6e; }
  .colore-pacchetto-aziende-04.pacchetto-owned {
    border-right: 4px solid #842e6e;
    border-left: 4px solid #842e6e;
    border-bottom: 4px solid #842e6e; }
  .colore-pacchetto-aziende-04 .pacchetto-owned-alert,
  .colore-pacchetto-aziende-04 .pacchetto-in-evidenza-alert {
    color: #842e6e; }
  .colore-pacchetto-aziende-04[class*='icon-genya-'] {
    color: #842e6e !important; }

.colore-pacchetto-aziende-05 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-aziende-05 h4 {
    background-color: #8c3a77; }
  .colore-pacchetto-aziende-05.pacchetto-owned {
    border-right: 4px solid #8c3a77;
    border-left: 4px solid #8c3a77;
    border-bottom: 4px solid #8c3a77; }
  .colore-pacchetto-aziende-05 .pacchetto-owned-alert,
  .colore-pacchetto-aziende-05 .pacchetto-in-evidenza-alert {
    color: #8c3a77; }
  .colore-pacchetto-aziende-05[class*='icon-genya-'] {
    color: #8c3a77 !important; }

.colore-pacchetto-aziende-06 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-aziende-06 h4 {
    background-color: #934780; }
  .colore-pacchetto-aziende-06.pacchetto-owned {
    border-right: 4px solid #934780;
    border-left: 4px solid #934780;
    border-bottom: 4px solid #934780; }
  .colore-pacchetto-aziende-06 .pacchetto-owned-alert,
  .colore-pacchetto-aziende-06 .pacchetto-in-evidenza-alert {
    color: #934780; }
  .colore-pacchetto-aziende-06[class*='icon-genya-'] {
    color: #934780 !important; }

.colore-pacchetto-aziende-07 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-aziende-07 h4 {
    background-color: #9a5388; }
  .colore-pacchetto-aziende-07.pacchetto-owned {
    border-right: 4px solid #9a5388;
    border-left: 4px solid #9a5388;
    border-bottom: 4px solid #9a5388; }
  .colore-pacchetto-aziende-07 .pacchetto-owned-alert,
  .colore-pacchetto-aziende-07 .pacchetto-in-evidenza-alert {
    color: #9a5388; }
  .colore-pacchetto-aziende-07[class*='icon-genya-'] {
    color: #9a5388 !important; }

.colore-pacchetto-aziende-08 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-aziende-08 h4 {
    background-color: #a15f90; }
  .colore-pacchetto-aziende-08.pacchetto-owned {
    border-right: 4px solid #a15f90;
    border-left: 4px solid #a15f90;
    border-bottom: 4px solid #a15f90; }
  .colore-pacchetto-aziende-08 .pacchetto-owned-alert,
  .colore-pacchetto-aziende-08 .pacchetto-in-evidenza-alert {
    color: #a15f90; }
  .colore-pacchetto-aziende-08[class*='icon-genya-'] {
    color: #a15f90 !important; }

.colore-pacchetto-fatturasmart-aziende-06 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-fatturasmart-aziende-06 h4 {
    background-color: #006556; }
  .colore-pacchetto-fatturasmart-aziende-06.pacchetto-owned {
    border-right: 4px solid #006556;
    border-left: 4px solid #006556;
    border-bottom: 4px solid #006556; }
  .colore-pacchetto-fatturasmart-aziende-06 .pacchetto-owned-alert,
  .colore-pacchetto-fatturasmart-aziende-06 .pacchetto-in-evidenza-alert {
    color: #006556; }
  .colore-pacchetto-fatturasmart-aziende-06[class*='icon-genya-'] {
    color: #006556 !important; }

.colore-pacchetto-fatturasmart-aziende-07 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-fatturasmart-aziende-07 h4 {
    background-color: #007f6b; }
  .colore-pacchetto-fatturasmart-aziende-07.pacchetto-owned {
    border-right: 4px solid #007f6b;
    border-left: 4px solid #007f6b;
    border-bottom: 4px solid #007f6b; }
  .colore-pacchetto-fatturasmart-aziende-07 .pacchetto-owned-alert,
  .colore-pacchetto-fatturasmart-aziende-07 .pacchetto-in-evidenza-alert {
    color: #007f6b; }
  .colore-pacchetto-fatturasmart-aziende-07[class*='icon-genya-'] {
    color: #007f6b !important; }

.colore-pacchetto-fatturasmart-aziende-08 {
  /*
    *   Se applicata a un'icona del font genya le forza il colore
    */ }
  .colore-pacchetto-fatturasmart-aziende-08 h4 {
    background-color: #009881; }
  .colore-pacchetto-fatturasmart-aziende-08.pacchetto-owned {
    border-right: 4px solid #009881;
    border-left: 4px solid #009881;
    border-bottom: 4px solid #009881; }
  .colore-pacchetto-fatturasmart-aziende-08 .pacchetto-owned-alert,
  .colore-pacchetto-fatturasmart-aziende-08 .pacchetto-in-evidenza-alert {
    color: #009881; }
  .colore-pacchetto-fatturasmart-aziende-08[class*='icon-genya-'] {
    color: #009881 !important; }

.genya-accordion {
  background-color: #fbfbfb;
  margin-top: 3rem; }
  .genya-accordion .accordion {
    margin: auto;
    position: relative;
    padding: 1rem 0 0 0;
    color: #fff;
    list-style: none; }
    .genya-accordion .accordion.accordion-professional {
      background-color: #241866; }
    .genya-accordion .accordion.accordion-aziende {
      background-color: #940c72; }
    .genya-accordion .accordion .accordion-title {
      color: rgba(255, 255, 255, 0.75);
      padding: 1rem 0 2rem 3rem; }
      @media screen and (max-width: 1024px) {
        .genya-accordion .accordion .accordion-title {
          padding: 2rem 0 2rem 1rem; } }
      .genya-accordion .accordion .accordion-title .accordionTitleText {
        font-size: 1.4em; }
    .genya-accordion .accordion .accordion-item {
      padding-left: 4rem; }
      @media screen and (max-width: 1024px) {
        .genya-accordion .accordion .accordion-item {
          word-wrap: break-word;
          max-width: 60%; } }
      @media screen and (max-width: 768px) {
        .genya-accordion .accordion .accordion-item {
          margin-left: 2rem;
          padding-left: 0;
          max-width: 100%; } }
      .genya-accordion .accordion .accordion-item .accordion-itemTitle {
        display: block;
        width: 60%;
        color: #fff;
        font-size: 1.175rem;
        line-height: 2.3;
        padding: 0.9rem;
        cursor: pointer; }
        @media screen and (max-width: 768px) {
          .genya-accordion .accordion .accordion-item .accordion-itemTitle {
            width: auto; } }
        .genya-accordion .accordion .accordion-item .accordion-itemTitle:hover {
          background-color: #fff; }
          .genya-accordion .accordion .accordion-item .accordion-itemTitle:hover a {
            color: #241866; }
            .genya-accordion .accordion .accordion-item .accordion-itemTitle:hover a:visited {
              color: #241866; }
            .genya-accordion .accordion .accordion-item .accordion-itemTitle:hover a:hover, .genya-accordion .accordion .accordion-item .accordion-itemTitle:hover a:active {
              color: #241866;
              text-decoration: none; }
        .genya-accordion .accordion .accordion-item .accordion-itemTitle a {
          color: #fff; }
          .genya-accordion .accordion .accordion-item .accordion-itemTitle a:visited {
            color: #fff; }
          .genya-accordion .accordion .accordion-item .accordion-itemTitle a:hover, .genya-accordion .accordion .accordion-item .accordion-itemTitle a:active {
            color: #241866;
            text-decoration: none; }
      .genya-accordion .accordion .accordion-item .accordion-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: none;
        padding: 1rem;
        width: 60%;
        background-color: #fff;
        overflow: hidden; }
        @media screen and (max-width: 768px) {
          .genya-accordion .accordion .accordion-item .accordion-content {
            position: relative;
            width: 100%;
            padding: 1rem 0.5rem;
            background-color: #ededed; } }
        .genya-accordion .accordion .accordion-item .accordion-content p {
          font-size: 1.09rem;
          line-height: 1.5;
          color: #474747;
          font-weight: 300; }
          @media screen and (max-width: 768px) {
            .genya-accordion .accordion .accordion-item .accordion-content p {
              font-size: 1.09rem;
              line-height: 1.5; } }
          .genya-accordion .accordion .accordion-item .accordion-content p a {
            text-decoration: underline; }
      .genya-accordion .accordion .accordion-item.active {
        background-color: #fff; }
        .genya-accordion .accordion .accordion-item.active .accordion-content {
          display: block; }
        .genya-accordion .accordion .accordion-item.active a {
          color: #241866; }
          .genya-accordion .accordion .accordion-item.active a:visited {
            color: #241866; }
          .genya-accordion .accordion .accordion-item.active a:hover, .genya-accordion .accordion .accordion-item.active a:active {
            color: #241866;
            text-decoration: none; }

.genya-scopri {
  position: relative;
  text-align: right;
  max-width: 55%;
  margin: 3rem auto; }
  @media screen and (max-width: 767px) {
    .genya-scopri {
      max-width: 100%;
      margin: 1rem auto; } }
  @media screen and (min-width: 600px) and (max-width: 1024px) {
    .genya-scopri {
      max-width: 80%;
      margin: 1rem auto; } }
  .genya-scopri .soluzione-azienda,
  .genya-scopri .soluzione-professional {
    font-family: "Franziska", serif;
    padding: 2rem; }
    .genya-scopri .soluzione-azienda p,
    .genya-scopri .soluzione-professional p {
      text-align: center;
      font-size: 1.6rem;
      color: white;
      font-style: italic; }
      @media screen and (max-width: 767px) {
        .genya-scopri .soluzione-azienda p,
        .genya-scopri .soluzione-professional p {
          text-align: left; } }
  .genya-scopri .soluzione-azienda {
    background-color: #940c72; }
  .genya-scopri .soluzione-professional {
    background-color: #241866; }
  .genya-scopri [wk-button] {
    padding-left: 1.3rem;
    padding-right: 1.3rem; }

.row-pacchetti-obscured {
  position: relative; }
  .row-pacchetti-obscured > div:after {
    content: ' ';
    width: 100%;
    height: 140px;
    background-image: linear-gradient(rgba(237, 237, 237, 0), rgba(237, 237, 237, 0.5), rgba(237, 237, 237, 0.8), rgba(237, 237, 237, 0.97), #ededed);
    position: absolute;
    bottom: 0;
    margin: auto; }

.nome-pacchetto {
  padding: 2rem 0;
  font-size: 2rem;
  border-bottom: 1px solid #d3d3d3; }
  .nome-pacchetto > span {
    display: block;
    width: 100%;
    font-style: italic;
    font-size: 1.1rem; }

/* Checkout con form */
.checkout {
  position: relative; }
  .checkout .checkout-fasi {
    display: inline-block;
    width: 100%;
    margin-top: 1rem; }
    .checkout .checkout-fasi > div {
      border-top: 1px solid #d3d3d3;
      border-bottom: 1px solid #d3d3d3; }
      .checkout .checkout-fasi > div:first-child {
        border-left: 1px solid #d3d3d3; }
      .checkout .checkout-fasi > div:last-child {
        border-right: 1px solid #d3d3d3; }
    .checkout .checkout-fasi .checkout-fase {
      position: relative;
      display: inline-block;
      float: left;
      text-align: center;
      width: 33.333%;
      margin: 0;
      padding: 0.5rem;
      background-color: #fff;
      color: #474747; }
      @media screen and (max-width: 600px) {
        .checkout .checkout-fasi .checkout-fase {
          border-right: 1px solid #d3d3d3; } }
      @media screen and (max-width: 767px) {
        .checkout .checkout-fasi .checkout-fase {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden; } }
      @media screen and (min-width: 600px) and (max-width: 1024px) {
        .checkout .checkout-fasi .checkout-fase {
          overflow: visible; } }
      .checkout .checkout-fasi .checkout-fase > span:before {
        content: ' ';
        width: 23px;
        height: 9px;
        position: absolute;
        border-top: 1px solid #d3d3d3;
        -webkit-transform: rotate(52deg);
        transform: rotate(52deg);
        top: 7px;
        right: 0; }
        @media screen and (max-width: 600px) {
          .checkout .checkout-fasi .checkout-fase > span:before {
            display: none; } }
      .checkout .checkout-fasi .checkout-fase > span:after {
        content: ' ';
        width: 23px;
        height: 9px;
        position: absolute;
        border-top: 1px solid #d3d3d3;
        -webkit-transform: rotate(128deg);
        transform: rotate(128deg);
        bottom: 7px;
        right: 0; }
        @media screen and (max-width: 600px) {
          .checkout .checkout-fasi .checkout-fase > span:after {
            display: none; } }
      .checkout .checkout-fasi .checkout-fase.active {
        background-color: #007ac3;
        color: #fff; }
        .checkout .checkout-fasi .checkout-fase.active > span {
          display: none; }
        .checkout .checkout-fasi .checkout-fase.active:after {
          content: '';
          width: 0;
          height: 0;
          border-top: 18px solid transparent;
          border-bottom: 18px solid transparent;
          border-left: 14px solid #007ac3;
          position: absolute;
          right: -13px;
          top: 0;
          z-index: 9; }
        .checkout .checkout-fasi .checkout-fase.active:last-child:after {
          display: none; }
        @media screen and (min-width: 600px) {
          .checkout .checkout-fasi .checkout-fase.active:not(:first-child):before {
            content: '';
            width: 0;
            height: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-left: 14px solid #ededed;
            position: absolute;
            left: -1px;
            top: -2px;
            z-index: 9; } }
      .checkout .checkout-fasi .checkout-fase.done {
        background-color: #ededed;
        border-left: 1px solid #d3d3d3; }
        .checkout .checkout-fasi .checkout-fase.done > span {
          display: none; }
        @media screen and (max-width: 767px) {
          .checkout .checkout-fasi .checkout-fase.done {
            padding-left: 28px;
            text-align: left; } }
        .checkout .checkout-fasi .checkout-fase.done:before {
          content: '\0045';
          font-family: "genya icons";
          position: absolute;
          left: 8px;
          color: green;
          font-size: 1rem;
          top: 6px;
          padding-right: 5px; }
      .checkout .checkout-fasi .checkout-fase:not(.active):last-child {
        width: 32%; }
        .checkout .checkout-fasi .checkout-fase:not(.active):last-child:after {
          content: '';
          width: 0;
          height: 0;
          border-top: 18px solid transparent;
          border-bottom: 19px solid transparent;
          border-left: 14px solid #fff;
          position: absolute;
          right: -14px;
          top: -1px;
          z-index: 9; }
        .checkout .checkout-fasi .checkout-fase:not(.active):last-child > span:before {
          content: ' ';
          width: 22px;
          height: 6px;
          position: absolute;
          border-top: 1px solid #d3d3d3;
          -moz-transform: rotate(52deg);
          -o-transform: rotate(52deg);
          -ms-transform: rotate(52deg);
          -webkit-transform: rotate(52deg);
          transform: rotate(52deg);
          top: 7px;
          right: -16px;
          z-index: 99; }
          @media screen and (max-width: 600px) {
            .checkout .checkout-fasi .checkout-fase:not(.active):last-child > span:before {
              display: none; } }
        .checkout .checkout-fasi .checkout-fase:not(.active):last-child > span:after {
          content: ' ';
          width: 23px;
          height: 9px;
          position: absolute;
          border-top: 1px solid #d3d3d3;
          -moz-transform: rotate(126deg);
          -o-transform: rotate(126deg);
          -ms-transform: rotate(126deg);
          -webkit-transform: rotate(126deg);
          transform: rotate(126deg);
          bottom: 7px;
          right: -15px;
          z-index: 99; }
          @media screen and (max-width: 600px) {
            .checkout .checkout-fasi .checkout-fase:not(.active):last-child > span:after {
              display: none; } }
  .checkout .checkout-left-column {
    position: relative;
    width: 68%;
    margin-right: 2%;
    float: left;
    background-color: #fff; }
    @media screen and (max-width: 767px) {
      .checkout .checkout-left-column {
        width: 100%;
        margin-right: 0; } }
  .checkout .checkout-dati-personali {
    margin-top: 4rem; }
    @media screen and (max-width: 767px) {
      .checkout .checkout-dati-personali {
        margin-top: 1rem; } }
  .checkout .checkout-dati-fatturazione {
    margin-top: 1rem; }
  .checkout .checkout-button-container > button {
    float: right; }
  .checkout .checkout-condizioni {
    background-color: #ededed;
    padding: 2rem; }
    .checkout .checkout-condizioni .wk-field-item {
      margin-bottom: 0; }
    .checkout .checkout-condizioni .wk-checkbox-field label {
      font-weight: 400; }
    .checkout .checkout-condizioni button + em {
      display: block;
      margin-top: 1rem; }
  .checkout .checkout-form-container {
    width: 50%;
    padding-top: 2rem; }
    @media screen and (max-width: 767px) {
      .checkout .checkout-form-container {
        width: 100%; } }
    .checkout .checkout-form-container.full {
      width: 100%; }
  .checkout .wk-field-item {
    margin-bottom: 2rem; }
    .checkout .wk-field-item.select-iban {
      float: left;
      width: 33%;
      margin-top: 1rem;
      margin-right: 2%;
      margin-bottom: 0; }
      @media screen and (max-width: 767px) {
        .checkout .wk-field-item.select-iban {
          width: 100%;
          margin-right: 0;
          margin-top: 0; } }
    .checkout .wk-field-item.codice-iban {
      float: left;
      width: 65%;
      margin-top: 1rem;
      margin-bottom: 0; }
      @media screen and (max-width: 767px) {
        .checkout .wk-field-item.codice-iban {
          width: 100%;
          margin-top: 0; } }
    .checkout .wk-field-item.metodo-carta-codice-fiscale {
      width: 50%; }
      @media screen and (max-width: 767px) {
        .checkout .wk-field-item.metodo-carta-codice-fiscale {
          width: 100%; } }
  .checkout .radiobutton-group:not(.radiobutton-group-images) .wk-radio-field {
    float: left;
    margin-right: 1rem; }
    .checkout .radiobutton-group:not(.radiobutton-group-images) .wk-radio-field span {
      font-weight: normal; }
  .checkout .radiobutton-group.radiobutton-group-images label img {
    width: 80px;
    margin-right: 1rem; }
  .checkout .checkout-conferma {
    max-width: 40rem;
    margin: auto;
    line-height: 2.4rem; }
    .checkout .checkout-conferma .checkout-conferma-sommario > strong {
      display: block;
      margin: 1rem auto;
      font-size: 1.2rem; }
    .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga {
      display: inline-block;
      font-size: 1.3rem;
      padding-bottom: 1rem;
      padding-top: 0.5rem;
      width: 100%;
      border-bottom: 1px solid #d3d3d3; }
      .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga .checkout-conferma-sommario-riga-prodotto {
        float: left;
        width: 50%; }
        .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga .checkout-conferma-sommario-riga-prodotto > strong {
          width: 100%;
          display: block; }
        .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga .checkout-conferma-sommario-riga-prodotto > span {
          display: inline-block;
          font-size: 1.1rem;
          color: #85bc20; }
          .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga .checkout-conferma-sommario-riga-prodotto > span > strong {
            font-size: 1.3rem; }
      .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga .checkout-conferma-sommario-riga-iva {
        float: left;
        width: 25%;
        text-align: center; }
      .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga .checkout-conferma-sommario-riga-prezzo {
        float: left;
        width: 25%;
        text-align: right; }
      .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga:first-of-type .checkout-conferma-sommario-riga-prodotto,
      .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga:first-of-type .checkout-conferma-sommario-riga-iva,
      .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga:first-of-type .checkout-conferma-sommario-riga-prezzo {
        color: #757575;
        font-weight: 300; }
      .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga.riga-small {
        width: 50%;
        margin-left: 50%;
        display: inline-block; }
        @media screen and (max-width: 767px) {
          .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga.riga-small {
            width: 100%;
            margin-left: 0; } }
        .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga.riga-small .checkout-conferma-sommario-riga-iva,
        .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga.riga-small .checkout-conferma-sommario-riga-prezzo {
          font-size: 1rem; }
          .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga.riga-small .checkout-conferma-sommario-riga-iva > strong,
          .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga.riga-small .checkout-conferma-sommario-riga-prezzo > strong {
            display: block;
            width: 100%;
            font-size: 1.375rem; }
          .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga.riga-small .checkout-conferma-sommario-riga-iva > span,
          .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga.riga-small .checkout-conferma-sommario-riga-prezzo > span {
            font-size: 0.9rem;
            color: #757575; }
        .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga.riga-small .checkout-conferma-sommario-riga-iva {
          text-align: left;
          width: 60%; }
        .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-riga.riga-small .checkout-conferma-sommario-riga-prezzo {
          width: 40%; }
    .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-info {
      padding-top: 2rem; }
      .checkout .checkout-conferma .checkout-conferma-sommario .checkout-conferma-sommario-info p {
        line-height: 1.5; }
    .checkout .checkout-conferma .wk-alert[wk-context='info'] {
      margin-top: 2rem;
      font-size: 1rem;
      line-height: 1.5; }
  .checkout.checkout-metodo-pagamento .row > div {
    margin-top: 2rem; }
    @media screen and (max-width: 767px) {
      .checkout.checkout-metodo-pagamento .row > div {
        margin-top: 1rem; }
        .checkout.checkout-metodo-pagamento .row > div:not(:first-of-type) {
          margin-top: 0; }
        .checkout.checkout-metodo-pagamento .row > div:last-of-type {
          margin-bottom: 2rem; } }
  .checkout.checkout-metodo-pagamento .checkout-carrello {
    margin-top: 0; }

.checkout-card {
  border: 1px solid #dadada;
  background-color: #fff;
  margin-bottom: 1rem; }
  .checkout-card .element-invisible {
    visibility: hidden; }
    @media screen and (max-width: 768px) {
      .checkout-card .element-invisible {
        display: none; } }
  .checkout-card .checkout-card-title {
    color: #fff;
    font-family: "Franziska", serif;
    font-style: italic;
    padding: 1rem;
    font-size: 1.3rem;
    width: auto;
    position: absolute;
    top: -2.6rem; }
    @media screen and (max-width: 767px) {
      .checkout-card .checkout-card-title {
        position: relative;
        top: 0; } }
    .checkout-card .checkout-card-title.blue {
      background-color: #241866; }
    .checkout-card .checkout-card-title.orange {
      background-color: #ea8f00; }
    .checkout-card .checkout-card-title.purple {
      background-color: #940c72; }
  .checkout-card .checkout-card-content {
    padding: 1.6rem; }
    .checkout-card .checkout-card-content .wk-h2 {
      margin-bottom: 1rem;
      margin-top: 0; }
    .checkout-card .checkout-card-content p {
      font-size: 1rem; }
    .checkout-card .checkout-card-content .form-nota {
      margin-top: 1rem;
      margin-bottom: 0;
      color: #757575; }
      .checkout-card .checkout-card-content .form-nota > span {
        color: #005b92; }
    .checkout-card .checkout-card-content .field-scadenza {
      margin-right: 0 !important;
      width: 50%; }
      @media screen and (max-width: 768px) {
        .checkout-card .checkout-card-content .field-scadenza {
          width: 100%; } }
    .checkout-card .checkout-card-content .checkout-metodi-accettati img {
      display: inline-block;
      height: 54px;
      margin-right: 1rem; }
      @media screen and (max-width: 768px) {
        .checkout-card .checkout-card-content .checkout-metodi-accettati img {
          width: 20%;
          height: auto;
          margin-right: 8px; } }
    .checkout-card .checkout-card-content .cvv-field {
      padding: 2rem;
      border: 1px solid #dadada;
      float: none;
      display: inline-block; }
      .checkout-card .checkout-card-content .cvv-field .wk-field-body {
        display: block;
        float: left;
        width: 40%; }
        @media screen and (max-width: 767px) {
          .checkout-card .checkout-card-content .cvv-field .wk-field-body {
            width: 100%; } }
        .checkout-card .checkout-card-content .cvv-field .wk-field-body input {
          width: 7rem; }
          @media screen and (max-width: 767px) {
            .checkout-card .checkout-card-content .cvv-field .wk-field-body input {
              width: 100%; } }
      .checkout-card .checkout-card-content .cvv-field .cvv-help-container {
        display: block;
        float: left;
        width: 60%; }
        @media screen and (max-width: 767px) {
          .checkout-card .checkout-card-content .cvv-field .cvv-help-container {
            margin-top: 1rem;
            width: 100%; } }
        .checkout-card .checkout-card-content .cvv-field .cvv-help-container .cvv-help-img,
        .checkout-card .checkout-card-content .cvv-field .cvv-help-container .cid-help-img {
          display: inline-block;
          position: relative;
          margin-right: 2rem;
          cursor: pointer; }
          @media screen and (max-width: 400px) {
            .checkout-card .checkout-card-content .cvv-field .cvv-help-container .cvv-help-img img,
            .checkout-card .checkout-card-content .cvv-field .cvv-help-container .cid-help-img img {
              width: 50px; } }
          .checkout-card .checkout-card-content .cvv-field .cvv-help-container .cvv-help-img:after,
          .checkout-card .checkout-card-content .cvv-field .cvv-help-container .cid-help-img:after {
            content: '\004a';
            display: block;
            font-family: 'genya icons';
            font-size: 1rem;
            color: #bfbfbf;
            position: absolute;
            top: 0.2rem;
            right: -1.4rem;
            cursor: pointer; }
        .checkout-card .checkout-card-content .cvv-field .cvv-help-container .cvv-help {
          position: relative;
          display: inline-block;
          margin-right: 1rem;
          width: 100%; }
          .checkout-card .checkout-card-content .cvv-field .cvv-help-container .cvv-help .cvv-help-text,
          .checkout-card .checkout-card-content .cvv-field .cvv-help-container .cvv-help .cid-help-text {
            display: none;
            width: 240px;
            padding: 4px; }
            @media screen and (max-width: 768px) {
              .checkout-card .checkout-card-content .cvv-field .cvv-help-container .cvv-help .cvv-help-text,
              .checkout-card .checkout-card-content .cvv-field .cvv-help-container .cvv-help .cid-help-text {
                width: 100%; } }
          .checkout-card .checkout-card-content .cvv-field .cvv-help-container .cvv-help .cid-help-text {
            margin-left: 120px;
            width: 70%; }
            @media screen and (max-width: 767px) {
              .checkout-card .checkout-card-content .cvv-field .cvv-help-container .cvv-help .cid-help-text {
                margin-left: 0;
                width: 100%; } }
    .checkout-card .checkout-card-content .pagaclick-field,
    .checkout-card .checkout-card-content .ricorrenza-pagamento-field {
      border: 1px solid #dadada;
      padding: 2rem;
      float: left; }
      .checkout-card .checkout-card-content .pagaclick-field > span,
      .checkout-card .checkout-card-content .ricorrenza-pagamento-field > span {
        font-weight: normal;
        font-size: 1.3rem;
        margin-bottom: 1rem;
        display: block; }
      .checkout-card .checkout-card-content .pagaclick-field > p,
      .checkout-card .checkout-card-content .ricorrenza-pagamento-field > p {
        line-height: 1.5;
        margin-bottom: 1rem; }
      .checkout-card .checkout-card-content .pagaclick-field > .wk-radio-field,
      .checkout-card .checkout-card-content .ricorrenza-pagamento-field > .wk-radio-field {
        display: inline-block;
        float: left;
        margin-right: 1rem; }
    .checkout-card .checkout-card-content .opzione-pagamento-rinnovo {
      border: 1px solid #dadada;
      padding: 2rem; }
      .checkout-card .checkout-card-content .opzione-pagamento-rinnovo > .wk-field-header {
        margin-bottom: 1rem; }
      .checkout-card .checkout-card-content .opzione-pagamento-rinnovo > .wk-radio-field {
        display: inline-block;
        float: left;
        margin-right: 1rem; }
        @media screen and (max-width: 767px) {
          .checkout-card .checkout-card-content .opzione-pagamento-rinnovo > .wk-radio-field {
            display: block;
            width: 100%; } }
  .checkout-card .checkout-card-selezione-metodo {
    display: inline-block;
    padding: 1.6rem 1.6rem 0 1.6rem; }
    .checkout-card .checkout-card-selezione-metodo .wk-field-header {
      margin: 1rem 0 2rem 0;
      font-size: 1.2rem;
      line-height: 1.5; }
    .checkout-card .checkout-card-selezione-metodo .wk-radio-field {
      display: inline-block;
      width: 45%;
      float: left; }
      @media screen and (max-width: 767px) {
        .checkout-card .checkout-card-selezione-metodo .wk-radio-field {
          width: 100%;
          margin-bottom: 1rem; } }
      .checkout-card .checkout-card-selezione-metodo .wk-radio-field label {
        position: relative;
        font-size: 1.1rem;
        line-height: 1.5;
        font-weight: 500; }
        .checkout-card .checkout-card-selezione-metodo .wk-radio-field label input.radio-carta + span:before,
        .checkout-card .checkout-card-selezione-metodo .wk-radio-field label input.radio-cc + span:before {
          content: '';
          display: block;
          width: 2rem;
          height: 2rem;
          background-size: contain;
          background-repeat: no-repeat;
          display: block;
          vertical-align: top;
          margin: 0 0.5rem;
          position: absolute;
          top: 0;
          left: 1rem; }
        .checkout-card .checkout-card-selezione-metodo .wk-radio-field label input.radio-carta + span:before {
          background-image: url("../images/credit-card-icon.png"); }
        .checkout-card .checkout-card-selezione-metodo .wk-radio-field label input.radio-cc + span:before {
          background-image: url("../images/bank-account-icon.png"); }
        .checkout-card .checkout-card-selezione-metodo .wk-radio-field label span:not(:first-of-type) {
          display: block;
          margin-left: 4.5rem;
          margin-top: -2rem;
          vertical-align: top; }
  .checkout-card .metodo-carta,
  .checkout-card .metodo-cc,
  .checkout-card .metodo-salvato {
    display: none; }
  .checkout-card .metodo-salvato a[wk-button] {
    min-width: 140px; }
  .checkout-card .field-metodo-salvato {
    padding: 1.6rem;
    margin-bottom: 0; }
    .checkout-card .field-metodo-salvato .wk-h3 {
      margin-bottom: 2rem; }
    .checkout-card .field-metodo-salvato .wk-field-body {
      width: 50%; }
      @media screen and (max-width: 100%) {
        .checkout-card .field-metodo-salvato .wk-field-body {
          width: 100%; } }
    .checkout-card .field-metodo-salvato + .checkout-card-selezione-metodo {
      display: none; }
  .checkout-card.checkout-card-1-3 {
    float: left; }

.checkout-carrello {
  margin-top: 4rem;
  padding: 1rem;
  background-color: #fff;
  border: 3px solid #007ac3; }
  @media screen and (max-width: 768px) {
    .checkout-carrello {
      margin-top: 1rem; } }
  .checkout-carrello.affix {
    margin-top: 0; }
    @media screen and (max-width: 992px) {
      .checkout-carrello.affix {
        position: relative;
        width: 100% !important; } }
  .checkout-carrello > span {
    display: block;
    font-size: 1.2rem;
    padding-bottom: 0.5rem; }
  .checkout-carrello .checkout-carrello-riga {
    font-weight: 300;
    width: 100%;
    border-top: 1px solid #d3d3d3;
    padding: 1rem 0; }
    .checkout-carrello .checkout-carrello-riga .checkout-carrello-riga-riepilogo-prezzo {
      float: right;
      text-align: right;
      font-weight: 400;
      font-style: normal; }
    .checkout-carrello .checkout-carrello-riga .checkout-carrello-riga-riepilogo-nomepacchetto {
      font-size: 0.8rem;
      font-style: normal;
      margin-bottom: 0; }
    .checkout-carrello .checkout-carrello-riga .checkout-carrello-riga-riepilogo-totaleanagrafiche {
      font-size: 0.8rem;
      color: #a3a3a3;
      font-weight: normal; }
    .checkout-carrello .checkout-carrello-riga > p {
      width: 100%;
      font-weight: 300;
      font-size: 0.8rem; }
    .checkout-carrello .checkout-carrello-riga.checkout-carrello-riga-riepilogo {
      font-weight: 500;
      line-height: 1.5;
      font-size: 0.8rem; }
      .checkout-carrello .checkout-carrello-riga.checkout-carrello-riga-riepilogo .checkout-carrello-riga-riepilogo-top > span:first-of-type {
        font-style: italic; }
    .checkout-carrello .checkout-carrello-riga.checkout-carrello-riga-totale > span {
      font-weight: 500; }
  .checkout-carrello .checkout-carrello-rinnovo {
    background-color: #ededed;
    padding: 0.5rem; }
    .checkout-carrello .checkout-carrello-rinnovo > span {
      float: right;
      text-align: right;
      font-weight: 400; }
    .checkout-carrello .checkout-carrello-rinnovo > p {
      width: 100%;
      font-weight: 300;
      font-size: 0.8rem; }

.checkout-carrello-riga.checkout-carrello-upgrade .checkout-carrello-riga-riepilogo-infopacchetto {
  font-size: 0.8rem;
  font-weight: normal;
  color: #757575; }

.checkout-carrello-riga.checkout-carrello-upgrade .checkout-carrello-riga-riepilogo-nomepacchetto {
  font-size: 0.8rem;
  font-style: italic;
  margin-bottom: 0; }

.checkout-carrello-riga.checkout-carrello-upgrade .checkout-carrello-riga-riepilogo-totaleanagrafiche {
  font-size: 0.8rem;
  color: #a3a3a3; }

.checkout-carrello-riga.checkout-carrello-upgrade.checkout-carrello-riga-riepilogo span:first-of-type.checkout-carrello-riga-riepilogo-upgrade {
  font-style: normal;
  font-weight: 700;
  color: #474747; }

.checkout-carrello-riga.checkout-carrello-upgrade.checkout-carrello-riga-riepilogo .checkout-carrello-riga-riepilogo-prezzo {
  font-style: normal; }

.checkout-carrello-riga.checkout-carrello-upgrade.checkout-carrello-riga-riepilogo .checkout-carrello-riga-riepilogo-infopacchetto > span {
  font-style: italic;
  font-weight: normal;
  color: #757575;
  font-size: 0.8rem; }

.checkout-col-accesso {
  padding-right: 0; }
  @media screen and (max-width: 767px) {
    .checkout-col-accesso {
      margin-top: 2rem;
      padding-right: 15px; } }
  .checkout-col-accesso .checkout-card {
    width: 48%;
    margin-right: 2%;
    float: left;
    margin-top: 4rem;
    position: relative; }
    @media screen and (max-width: 768px) {
      .checkout-col-accesso .checkout-card {
        width: 100%;
        margin-right: 0;
        margin-top: 3rem; } }
    @media screen and (max-width: 360px) {
      .checkout-col-accesso .checkout-card {
        margin-top: 0;
        margin-bottom: 1rem; } }
    .checkout-col-accesso .checkout-card .checkout-card-content {
      padding: 2rem; }
      .checkout-col-accesso .checkout-card .checkout-card-content p {
        font-size: 1.3rem;
        min-height: 6rem;
        margin-bottom: 2rem; }
      .checkout-col-accesso .checkout-card .checkout-card-content.checkout-card-verifica-iva .form-container .wk-field-item .wk-field-body input {
        color: #474747;
        font-weight: 500; }
    .checkout-col-accesso .checkout-card a[wk-button="wk-button"] {
      width: 100%; }
  .checkout-col-accesso + div {
    padding-left: 0; }
    @media screen and (max-width: 991px) {
      .checkout-col-accesso + div {
        padding-left: 15px; }
        .checkout-col-accesso + div .checkout-carrello {
          margin-top: 1rem; } }
    @media screen and (max-width: 767px) {
      .checkout-col-accesso + div {
        margin-top: 0;
        padding-left: 15px; } }

/* Area Riservata */
.reserved-area-card {
  position: relative;
  background-color: #fff;
  margin-top: 5rem; }
  @media screen and (max-width: 767px) {
    .reserved-area-card {
      margin-top: 2rem; } }
  .reserved-area-card .reserved-area-card-title {
    color: #fff;
    font-family: "Franziska", serif;
    font-style: italic;
    padding: 1rem;
    font-size: 1.3rem;
    width: auto;
    position: absolute;
    top: -2.6rem; }
    @media screen and (max-width: 767px) {
      .reserved-area-card .reserved-area-card-title {
        position: relative;
        top: 0; } }
    .reserved-area-card .reserved-area-card-title.blue {
      background-color: #241866; }
    .reserved-area-card .reserved-area-card-title.orange {
      background-color: #ea8f00; }
    .reserved-area-card .reserved-area-card-title.purple {
      background-color: #940c72; }
  .reserved-area-card .reserved-area-card-content {
    background-color: #fff;
    padding: 3rem 1rem 1rem 1rem; }
    @media screen and (max-width: 767px) {
      .reserved-area-card .reserved-area-card-content {
        padding: 1rem; } }
    .reserved-area-card .reserved-area-card-content p {
      font-size: 1.2rem; }
      .reserved-area-card .reserved-area-card-content p > span {
        color: #A3A3A3; }
      .reserved-area-card .reserved-area-card-content p > span.stato-abbonamento-attivo {
        color: #85bc20; }
    .reserved-area-card .reserved-area-card-content .reserved-area-card-content-sub {
      border-top: 1px solid #d3d3d3;
      margin-top: 2rem;
      padding-top: 1rem; }
      .reserved-area-card .reserved-area-card-content .reserved-area-card-content-sub p {
        font-size: 1.2rem;
        margin: 1rem 0; }
      .reserved-area-card .reserved-area-card-content .reserved-area-card-content-sub .wk-h3 {
        font-size: 1.5rem; }
      .reserved-area-card .reserved-area-card-content .reserved-area-card-content-sub .icon-genya-phone {
        font-size: 1rem; }
    .reserved-area-card .reserved-area-card-content .panel + .reserved-area-card-content-sub {
      border-top: none;
      margin-top: 0; }
    .reserved-area-card .reserved-area-card-content.pacchetto .wk-h4 {
      padding: 0 1rem;
      font-style: italic;
      font-size: 1.6rem;
      font-weight: 500;
      color: #474747; }
    .reserved-area-card .reserved-area-card-content.pacchetto .pacchetto-anagrafiche {
      width: 50%;
      float: left;
      border: 0;
      white-space: normal;
      font-size: 1rem;
      padding-right: 0.5rem; }
      @media screen and (max-width: 767px) {
        .reserved-area-card .reserved-area-card-content.pacchetto .pacchetto-anagrafiche {
          width: 100%; } }
    .reserved-area-card .reserved-area-card-content.pacchetto .pacchetto-incluso {
      border-right: none;
      border-left: 1px solid #d3d3d3;
      padding: 0 1rem; }
      @media screen and (max-width: 768px) {
        .reserved-area-card .reserved-area-card-content.pacchetto .pacchetto-incluso ul li {
          font-size: 0.8rem; } }
      @media screen and (max-width: 767px) {
        .reserved-area-card .reserved-area-card-content.pacchetto .pacchetto-incluso {
          border-left: 0;
          border-top: 1px solid #d3d3d3;
          padding: 1rem 0; } }
      @media screen and (max-width: 992px) {
        .reserved-area-card .reserved-area-card-content.pacchetto .pacchetto-incluso {
          padding-left: 1rem;
          padding-right: 0; } }
    .reserved-area-card .reserved-area-card-content.pacchetto .pacchetto-dettagli {
      width: 100%;
      padding: 0;
      margin: 1rem auto 0 auto; }
    .reserved-area-card .reserved-area-card-content.pacchetto .pacchetto-button {
      display: inline-block; }
      @media screen and (max-width: 767px) {
        .reserved-area-card .reserved-area-card-content.pacchetto .pacchetto-button {
          display: block;
          width: 100%; } }
    .reserved-area-card .reserved-area-card-content.fields-container .wk-field-item {
      display: inline-block;
      width: 100%; }
      .reserved-area-card .reserved-area-card-content.fields-container .wk-field-item .wk-field-header {
        width: 30%;
        float: left;
        padding: 0.6rem 0;
        margin: 0; }
        @media screen and (max-width: 767px) {
          .reserved-area-card .reserved-area-card-content.fields-container .wk-field-item .wk-field-header {
            width: 100%; } }
      .reserved-area-card .reserved-area-card-content.fields-container .wk-field-item .wk-field-body {
        width: 70%;
        float: left; }
        @media screen and (max-width: 767px) {
          .reserved-area-card .reserved-area-card-content.fields-container .wk-field-item .wk-field-body {
            width: 100%; } }
        .reserved-area-card .reserved-area-card-content.fields-container .wk-field-item .wk-field-body input {
          color: #474747;
          font-weight: 400; }
    .reserved-area-card .reserved-area-card-content .panel,
    .reserved-area-card .reserved-area-card-content .panel-group {
      margin-bottom: 0;
      border-radius: 0; }
    .reserved-area-card .reserved-area-card-content .panel .wk-field-item label {
      font-size: 1.1rem;
      font-weight: 400; }
    .reserved-area-card .reserved-area-card-content .panel a[wk-button] {
      min-width: 7rem; }
    .reserved-area-card .reserved-area-card-content .panel-title > a:first-child {
      max-width: 90%;
      display: inline-block; }
    .reserved-area-card .reserved-area-card-content .panel-default {
      border-bottom: 0;
      margin-top: 0; }
      .reserved-area-card .reserved-area-card-content .panel-default > .panel-heading {
        border-bottom: 1px solid #ddd;
        position: relative;
        color: #474747; }
        .reserved-area-card .reserved-area-card-content .panel-default > .panel-heading .wk-h4 a {
          font-size: 1.2rem;
          font-weight: 400; }
      .reserved-area-card .reserved-area-card-content .panel-default .panel-body {
        border-bottom: 1px solid #ddd; }
    .reserved-area-card .reserved-area-card-content .accordion-sottoscrizioni-toggle:before {
      content: '-';
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 99px;
      background-color: #85bc20;
      color: white;
      font-size: 23px;
      text-align: center;
      font-weight: 300;
      top: 8px;
      right: 16px; }
    .reserved-area-card .reserved-area-card-content .accordion-sottoscrizioni-toggle.collapsed:before {
      content: '+'; }

.trial-topbar {
  padding: 12px;
  text-align: center;
  background-color: #F8D6A6;
  color: #757575;
  cursor: default;
  font-size: 1rem;
  line-height: 1.5; }
  @media screen and (max-width: 768px) {
    .trial-topbar {
      display: none; } }

.inner-topbar {
  max-width: 75rem;
  margin: 0 auto;
  padding: 0.5rem; }

.multiuser-topbar {
  background-color: #453a7d;
  text-align: right;
  font-size: 1rem;
  line-height: 1rem; }
  @media screen and (max-width: 768px) {
    .multiuser-topbar {
      position: absolute;
      top: 3.5rem;
      left: 0;
      width: 100%;
      z-index: 9; } }
  .multiuser-topbar a {
    position: relative;
    color: #fff; }
    .multiuser-topbar a:visited {
      color: #fff; }
    .multiuser-topbar a:hover {
      color: #fff; }
    .multiuser-topbar a:active {
      color: #fff; }
  .multiuser-topbar [class*="icon-genya-"] {
    /*position: absolute;
        top: 0;
        margin-left: 0.4rem;
        font-size: 1rem;*/
    float: right;
    margin-top: -2px;
    margin-left: 0.5rem; }

.genya-topbar {
  width: 100%;
  background-color: #f2f2f2; }
  @media screen and (max-width: 768px) {
    .genya-topbar {
      display: none; } }
  .genya-topbar .genya-topbar-links {
    max-width: 75rem;
    margin: auto;
    padding: 7px;
    color: #474747;
    cursor: default;
    font-size: 1rem;
    line-height: 1.5; }
    .genya-topbar .genya-topbar-links a {
      color: #474747; }
      .genya-topbar .genya-topbar-links a:visited {
        color: #474747; }
      .genya-topbar .genya-topbar-links a:hover {
        color: #7a7a7a; }
      .genya-topbar .genya-topbar-links a:active {
        color: #474747; }
    .genya-topbar .genya-topbar-links a:last-child {
      float: right; }
    .genya-topbar .genya-topbar-links [class*='icon-genya-'] {
      vertical-align: text-top;
      margin-right: 4px; }

/* Error Pages */
body.error-page {
  background-color: #fff; }
  body.error-page .wk-page-content {
    max-width: 40rem;
    text-align: center; }
    body.error-page .wk-page-content .wk-logo-large {
      display: inline-block;
      margin: 1rem auto; }
    body.error-page .wk-page-content .error-icon {
      display: block;
      width: 100%;
      max-width: 150px;
      margin: 2rem auto; }
      @media screen and (max-width: 767px) {
        body.error-page .wk-page-content .error-icon {
          margin: 1.4rem auto;
          max-width: 180px; } }
    body.error-page .wk-page-content .icon-404 {
      max-width: 220px; }
    body.error-page .wk-page-content .wk-h1 {
      font-weight: 400;
      line-height: 1.5; }
    body.error-page .wk-page-content .wk-h3 {
      font-weight: 400;
      line-height: 1.5;
      margin-top: 0; }
    body.error-page .wk-page-content p {
      margin: 2rem auto;
      font-size: 1.2rem;
      line-height: 1.5;
      -ms-filter: alpha(opacity=80);
      -ms-filter: progid:DXImageTransform.Microsoft.Alpha Opacity=80;
      filter: alpha(opacity=80);
      opacity: 0.8; }
    body.error-page .wk-page-content a[wk-button] {
      min-width: 180px; }
    body.error-page .wk-page-content [class*='icon-genya-'] {
      vertical-align: middle; }

/* Autocomplete */
.autocomplete-suggestions {
  border: 1px solid #999;
  background: #FFF;
  overflow: auto; }

.autocomplete-suggestion {
  padding: 0.8rem 1rem;
  white-space: nowrap;
  overflow: hidden; }

.autocomplete-selected {
  background: #F0F0F0; }

.autocomplete-suggestions strong {
  font-weight: normal;
  color: #3399FF; }

.autocomplete-group {
  padding: 0.8rem 1rem; }

.autocomplete-group strong {
  display: block;
  border-bottom: 1px solid #000; }

.inline {
  display: inline-block;
  width: auto !important; }

.uppercase {
  text-transform: uppercase; }

.capitalcase {
  text-transform: capitalize; }

.lowercase {
  text-transform: lowercase; }

.bind-addon-type {
  font-size: inherit !important; }

.cart-content, .cart-notification {
  display: none; }

.pacchetto-fatturasmart-aziende .pacchetto-button > a {
  background-color: #E5202E; }
  .pacchetto-fatturasmart-aziende .pacchetto-button > a:hover {
    background-color: #f07b84 !important; }
