.form-avvisa-container {
    position: relative;
    max-width: 58%;
    margin: auto;
    border: 1px solid $wkGreen2;
    color: $wkGreen2;
    font-size: 1.3rem;
    padding: 1.5rem 1rem;
    @media screen and (max-width: $max-width-tablet) {
        max-width: 80%;
    }
    @media screen and (max-width: $max-width-mobile) {
        max-width: 100%;
    }
    .wk-field-item {
        margin-top: 1rem;
        margin-bottom: 0;
        @media screen and (max-width: $min-width-tablet) {
            margin-top: 2rem;
        }
        @media screen and (max-width: 600px) {
            margin-top: 0;
        }
        .wk-field-header {
            label {
                font-weight: normal;
                font-size: 1.1rem;
            }
        }
        .wk-field-body {
            input {
                margin: auto;
                display: inline-block;
                float: left;
                width: 85%;
                @media screen and (max-width: $min-width-tablet) {
                    width: 80%;
                }
                @media screen and (max-width: $max-width-mobile) {
                    display: block;
                    float: left;
                    width: 75%;
                }
                @media screen and (max-width: 360px) {
                    width: 60%;
                }
            }
            > a[wk-button] {
                display: inline-block;
                float: left;
                width: 15%;
                background-color: $wkGreen;
                height: 3rem;
            	font-size: 1rem;
            	line-height: 1rem;
                text-align: center;
                @media screen and (max-width: $min-width-tablet) {
                    width: 20%;
                }
                @media screen and (max-width: $max-width-mobile) {
                    width: auto;
                }
                &:hover {
                    background-color: lighten(#a4cd58, 5%);
                }

            }
        }
        .wk-checkbox-field {
            font-size: 1.1rem;
            a {
                text-decoration: underline;
                &:visited {
                    text-decoration: underline;
                }
                &:hover {
                    text-decoration: underline;
                }
                &:active {
                    text-decoration: underline;
                }
            }
        }
    }
}

/* Form */
.form-container,
.fields-container {
    line-height: 1.5;
    .form-question {
        margin-top: 0;
        margin-bottom: 1rem;
        font-weight: 400;
        font-size: 1.3rem;
    }
    .wk-field-item {
        .wk-field-header {
            font-size: 1.1rem;
            font-weight: 400;
            label {
                font-size: 1.1rem;
                font-weight: 400;
                > span {
                    color: $wkBlue;
                }
            }
        }
        .wk-radio-field {
            label {
                font-size: 1rem;
                font-weight: 400;
            }
        }
        .wk-checkbox-field {
            label {
                font-weight: 400;
                font-size: 1rem;
            }
            a {
                text-decoration: underline;
                &:visited {
                    text-decoration: underline;
                }
                &:hover {
                    text-decoration: underline;
                }
                &:active {
                    text-decoration: underline;
                }
            }
        }
        .field-info {
            font-size: 1.1rem;
            font-weight: 400;
        }
        &.full-width-item {
            width: 100%!important;
            .wk-field-header {
                margin-bottom: 0.8rem;
            }
        }
        &.input-accettato {
            input:not([type=radio]):not([type=checkbox]),
            select:not(:disabled),
            textarea:not(:disabled) {
                border-color: $wkGreen;
                color: $wkGreen;
            }
            input[type="text"]:disabled {
                color: $textColor;
            }
        }
        &.horizontal-options {
            .wk-radio-field,
            .wk-checkbox-field {
                display: inline-block;
            	float: left;
            	margin-right: 1rem;
            }
            &.wk-field-has-error {
                .wk-field-error {
                    display: inline-block;
                }
            }
        }
        &.form-bottom {
            &.form-bottom-right-aligned {
                width: 60%;
                float: right;
                padding: 1rem;
                @media screen and (max-width: $min-width-tablet) {
                    width: 100%;
                    float: none;
                    padding: 0;
                }
                a.link-accedi {
                    display: inline-block;
                    font-size: 1rem;
                    line-height: 1.5;
                    margin-top: 1rem;
                    @media screen and (max-width: $min-width-tablet) {
                        margin-top: 0;
                        margin-bottom: 1rem;
                    }
                    @media screen and (max-width: $max-width-mobile) {
                        width: 100%;
                    }
                }
                a[wk-button] {
                    display: inline-block;
                    min-width: 200px;
                    width: auto;
                    margin-top: 0;
                }
                a.link-accedi + a[wk-button] {
                    float: right;
                    @media screen and (max-width: $max-width-mobile) {
                        float: left;
                    }
                }
            }
            @media screen and (max-width: $min-width-tablet) {
                //text-align: center;
            }
            > a[wk-button] {
                display: inline-block;
                min-width: 140px;
                width: auto;
                margin-top: 1.5rem;
            }
        }
    }

    &.form-2-col {
        display: table;
        padding: 1rem 0;
        width: 100%;
        @media screen and (max-width: $max-width-mobile) {
            display: inline-block;
        }
        .wk-field-item {
            width: 48%;
            float: left;
            &.link-item {
                padding: 3rem;
                @media screen and (max-width: $min-width-tablet) {
                    padding: 0;
                }
                a {
                    text-decoration: underline;
                }
            }
            &:not(:last-child) {
                margin-right: 2%;
            }
            .wk-alert {
                margin-right: 2%;
            	font-size: 1.4rem;
            }
            @media screen and (max-width: $min-width-tablet) {
                width: 100%;
                margin: 0.5rem auto;
            }
            input:disabled {
                color: $black;
                font-weight: 500;
            }
        }
    }
    .link-accedi {
        text-decoration: underline;
    }
}

.wk-field-body {
    &.select-data {
        .select-data-mese {
            float: left;
            width: 33%;
            margin-right: 2%;
        }
        .select-data-anno {
            float: left;
            width: 65%;
        }
    }
}

.informativa {
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.5;
}

/* reCaptcha */
.g-recaptcha {
    @include scale(0.85);
    @include transform-origin(left top);
}
