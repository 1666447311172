@font-face {
  font-family: 'Fira Sans';
  src: url('../fonts/firasans/FiraSans-Regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/firasans/FiraSans-Regular.eot') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/firasans/FiraSans-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/firasans/FiraSans-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/firasans/FiraSans-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
}

@font-face {
  font-family: 'Franziska';
  font-weight: italic;
  src: url('../fonts/franziska/FranziskaPro-MediumItalic.otf') format("opentype");
}

@font-face {
    font-family: 'genya icons';
    src: url('../fonts/genya-icons/genya-icons.eot');
    src: url('../fonts/genya-icons/genya-icons.eot?#iefix') format('embedded-opentype'),
         url('../fonts/genya-icons/genya-icons.woff') format('woff'),
         url('../fonts/genya-icons/genya-icons.ttf') format('truetype'),
         url('../fonts/genya-icons/genya-icons.svg#genya-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-genya-']:before{
   display: inline-block;
   font-family: 'genya icons';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   padding: 0 4px;
   margin-top: 2px;
   display: inline-block;
}
.icon-genya-accounting:before{content:'\0041';}
.icon-genya-bag:before{content:'\0042';}
.icon-genya-balance:before{content:'\0043';}
.icon-genya-cart:before{content:'\0044';}
.icon-genya-check:before{content:'\0045';}
.icon-genya-delete:before{content:'\0046';}
.icon-genya-edit:before{content:'\0047';}
.icon-genya-exclamation-point:before{content:'\0048';}
.icon-genya-login:before{content:'\0049';}
.icon-genya-info-and-request:before{content:'\004a';}
.icon-genya-minus:before{content:'\004b';}
.icon-genya-phone:before{content:'\004c';}
.icon-genya-plus:before{content:'\004d';}
.icon-genya-reserved-area:before{content:'\004e';}
.icon-genya-statements:before{content:'\004f';}
.icon-genya-support:before{content:'\0050';}
.icon-genya-down-arrow:before{content:'\0051';}
.icon-genya-tab:before{content:'\0052';}
.icon-genya-404:before{content:'\0053';}
.icon-genya-cloud-1:before{content:'\0054';}
.icon-genya-cloud-2:before{content:'\0055';}
.icon-genya-lock:before{content:'\0056';}
.icon-genya-external-link:before{content:'\0057';}
.icon-genya-play-square:before{content:'\0058';}
